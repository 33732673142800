enum EntityEnum {
  FRANCE = 'France',
  LUXEMBOURG = 'Luxembourg',
  MONACO = 'Monaco',
  SUISSE = 'Suisse',
}

export class EntityHelper {
  static allEntities = (): EntityEnum[] => Object.values(EntityEnum)
}

export default EntityEnum
