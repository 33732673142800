import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom'
import Section from '../../../components/Layout/Section'
import ProtectedRoute from '../../../components/ProtectedRoute'
import ApiContext from '../../../context/ApiContext'
import StartupContext from '../../../context/StartupContext'
import { StartupModel } from '../../../domain/startup.model'
import usePermission from '../../../services/usePermission.hook'
import { ReactComponent as BackIcon } from '../../../styles/svg/back.svg'
import { Roles } from '../../../utils/roles.enum'
import Startup from '../Show/Startup'
import ModifyStartup from '../Update/ModifyStartup'
import StartupsGrid from './StartupsGrid'
import { EcosystemsModel } from '../../../domain/ecosystems.model'

export default function Startups() {
  const match = useRouteMatch()
  const location = useLocation()

  const displayTable = location.pathname === match.path
  const tableStyle = displayTable ? {} : { display: 'none' }

  const isClientOrBanquier = usePermission([Roles.CLIENT, Roles.BANQUIER])
  const { t } = useTranslation('common')

  const { getStartups, getStartup, getEcosystems } = useContext(ApiContext)
  const [startups, setStartups] = useState<StartupModel[]>([])
  const [ecosystems, setEcosystems] = useState<EcosystemsModel[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    setLoading(true)
    getEcosystems().then(setEcosystems)
    getStartups()
      .then(setStartups)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const replaceStartup = (updatedStartup: StartupModel) => {
    let idx = -1
    if (updatedStartup.id) {
      idx = startups.findIndex((s) => s.id === updatedStartup.id)
    }
    if (idx !== -1) {
      startups.splice(idx, 1, updatedStartup)
      setStartups([...startups])
    } else {
      setStartups([updatedStartup, ...startups])
    }
  }

  const reloadStartup = (id: string) => {
    getStartup(id).then(replaceStartup)
  }

  const getEcosystemName = (startup: StartupModel): string => {
    const e = ecosystems.find(
      (e: EcosystemsModel) => e.id === startup.informations.ecosystemId,
    )
    if (e) {
      return e.name
    } else {
      return ''
    }
  }

  return (
    <StartupContext.Provider
      value={{
        startups,
        ecosystems,
        reloadStartup,
        replaceStartup,
        getEcosystemName,
      }}
    >
      <div style={tableStyle}>
        <StartupsGrid loading={loading} />
      </div>
      {displayTable ? null : (
        <Section theme={isClientOrBanquier ? 'dark' : 'light'}>
          <Link to={match.url} className="no-print">
            <BackIcon />
            &nbsp;&nbsp;{t('backToList')}
          </Link>
        </Section>
      )}
      <Switch>
        <ProtectedRoute
          path={`${match.url}/:idStartup/edit`}
          component={ModifyStartup}
          roles={Roles.INNO}
        />
        <Route
          path={`${match.url}/:idStartup`}
          render={(props) => <Startup id={props.match.params.idStartup} />}
        />
      </Switch>
    </StartupContext.Provider>
  )
}
