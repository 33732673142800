import { Form } from 'antd'
import React, { FC, useContext } from 'react'
import ApiContext from '../../../context/ApiContext'
import { StartupModel } from '../../../domain/startup.model'
import { StartupOfferReq } from '../../../services/Api'
import FormFooter from '../../../components/Form/FormFooter'
import OfferStartupForm from '../Update/Common/OfferStartupForm'
import { useTranslation } from 'react-i18next'
import FormHelper from '../../../utils/FormHelper'

interface Props {
  onSave: () => void
  onCancel: () => void
  startup: StartupModel
}

const InitOfferStartup: FC<Props> = ({ onSave, onCancel, startup }: Props) => {
  const { errorFormBehaviour, updateStartupOffer } = useContext(ApiContext)
  const [form] = Form.useForm()

  const save = async (offer: StartupOfferReq) => {
    if (await FormHelper.validateForm(form)) {
      updateStartupOffer(startup.id, offer)
        .then(onSave)
        .catch(errorFormBehaviour)
    }
  }

  const onSaveDraftMode = (errorInfo: any) => {
    save(errorInfo.values)
  }

  const { t } = useTranslation('startup')

  return startup == null ? (
    <></>
  ) : (
    <Form
      layout="vertical"
      onFinish={save}
      onFinishFailed={onSaveDraftMode}
      requiredMark={true}
      initialValues={startup.offer}
      form={form}
    >
      <h2 className="mb-60">{t('activity')}</h2>
      <OfferStartupForm />
      <FormFooter
        onCancel={onCancel}
        cancelLabel={t('back')}
        saveLabel={t('continue')}
      />
    </Form>
  )
}

export default InitOfferStartup
