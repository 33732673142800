import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../../../components/Form/OptionWithCount.model'
import RangeValueModel from '../../../components/Range/RangeValue.model'
import SectorEnum, { SectorHelper } from '../../../domain/sector.enum'
import StartupMaturitiesEnum, {
  StartupMaturitiesHelper,
} from '../../../domain/startup-maturities.enum'
import StartupNeedsEnum, {
  StartupNeedHelper,
} from '../../../domain/startup-needs.enum'
import StartupRaiseStatusEnum, {
  StartupRaiseStatusHelper,
} from '../../../domain/startup-raiseStatus.enum'
import StartupStatusEnum, {
  StartupStatusHelper,
} from '../../../domain/startup-status.enum'
import CountryEnum, { CountryHelper } from '../../../domain/country.enum'
import RegionEnum, { RegionHelper } from '../../../domain/region.enum'
import CascaderOptionWithCountModel, {
  CascaderOption,
  CascaderOptionWithCountHelper,
} from '../../../components/Form/CascaderOptionWithCount.model'
import { TFunction } from 'react-i18next'

export interface Filter {
  statuses: StartupStatusEnum[]
  sectors: SectorEnum[]
  countries: CountryEnum[]
  regions: RegionEnum[]
  ecosystems: CascaderOption[]
  maturities: StartupMaturitiesEnum[]
  fundRaiseNeeds: StartupNeedsEnum[]
  fundRaiseStatus: StartupRaiseStatusEnum[]
  fundRaiseValues: RangeValueModel[]
  entryTicket?: RangeValueModel
  businessWoman: boolean
  environmentalImpact: boolean
}

export const emptyFilter: Filter = {
  statuses: [],
  sectors: [],
  countries: [],
  regions: [],
  ecosystems: [],
  maturities: [],
  fundRaiseNeeds: [],
  fundRaiseStatus: [],
  fundRaiseValues: [],
  entryTicket: undefined,
  businessWoman: false,
  environmentalImpact: false,
}

export interface DataFilter {
  statuses: OptionWithCountModel<StartupStatusEnum>[]
  sectors: OptionWithCountModel<SectorEnum>[]
  countries: OptionWithCountModel<CountryEnum>[]
  regions: OptionWithCountModel<RegionEnum>[]
  ecosystems: CascaderOptionWithCountModel[]
  maturities: OptionWithCountModel<StartupMaturitiesEnum>[]
  fundRaiseNeeds: OptionWithCountModel<StartupNeedsEnum>[]
  fundRaiseStatus: OptionWithCountModel<StartupRaiseStatusEnum>[]
  fundRaiseValues: OptionWithCountModel<RangeValueModel>[]
}

export const defaultDataFilterFn = (
  ecosystemsAndChilds: CascaderOption[],
  t: TFunction,
): DataFilter => ({
  statuses: OptionWithCountHelper.buildFrom(
    [
      StartupStatusEnum.TO_VALIDATE,
      StartupStatusEnum.VALIDATED,
      StartupStatusEnum.DISABLED,
    ],
    t,
    StartupStatusHelper.format,
  ),
  sectors: OptionWithCountHelper.buildFrom(
    SectorHelper.allSectors(),
    t,
    SectorHelper.format,
  ),
  countries: OptionWithCountHelper.buildFrom(CountryHelper.allCountries(), t),
  regions: OptionWithCountHelper.buildFrom(RegionHelper.allRegions(), t),
  ecosystems: CascaderOptionWithCountHelper.buildFrom(ecosystemsAndChilds),
  maturities: OptionWithCountHelper.buildFrom(
    StartupMaturitiesHelper.allMaturities(),
    t,
    StartupMaturitiesHelper.format,
  ),
  fundRaiseNeeds: OptionWithCountHelper.buildFrom(
    StartupNeedHelper.allNeeds(),
    t,
    StartupNeedHelper.format,
  ),
  fundRaiseStatus: OptionWithCountHelper.buildFrom(
    StartupRaiseStatusHelper.allRaiseStatus(),
    t,
    StartupRaiseStatusHelper.format,
  ),
  fundRaiseValues: OptionWithCountHelper.buildFrom(
    [
      new RangeValueModel(0, 200000),
      new RangeValueModel(200000, 500000),
      new RangeValueModel(500000, 2000000),
      new RangeValueModel(2000000),
    ],
    t,
    (v) => v.getLabel(),
  ),
})
