import React, { FC, useContext } from 'react'
import useBreakpointValue from '../../utils/useBreakpointValue.hook'
import ApiContext from '../../context/ApiContext'
import { Button, Drawer, Form, Input, Space } from 'antd'
import { EcosystemReq } from '../../services/Api'
import MainButton from '../../components/MainButton/MainButton'
import { useTranslation } from 'react-i18next'

interface Props {
  onClose: () => void
  visible: boolean
}

const CreateEcosystemDrawer: FC<Props> = ({ visible, onClose }: Props) => {
  const { createEcosystem, successFormBehaviour } = useContext(ApiContext)
  const drawerWidth = useBreakpointValue<string>({
    xs: '100%',
    md: '80%',
    lg: '65%',
    xl: '50%',
  })
  const { t: t_ecosystem } = useTranslation('ecosystem')
  const { t: t_common } = useTranslation('common')

  const [form] = Form.useForm()

  const onSave = () => {
    form.validateFields().then((ecosystem) =>
      createEcosystem({
        ...(ecosystem as EcosystemReq),
        subEcosystems: [],
      })
        .then(() => successFormBehaviour(t_ecosystem('ecosystemCreated')))
        .then(resetAndClose),
    )
  }
  const resetAndClose = () => {
    form.resetFields()
    onClose()
  }

  return (
    <Drawer
      title={t_ecosystem('addNewEcosystem')}
      width={drawerWidth}
      onClose={resetAndClose}
      visible={visible}
      footer={
        <Space direction="horizontal">
          <MainButton onClick={onSave}>{t_common('add')}</MainButton>
          <Button onClick={resetAndClose}>{t_common('cancel')}</Button>
        </Space>
      }
    >
      <Form form={form} layout="vertical" requiredMark={true}>
        <Form.Item
          label={t_ecosystem('name')}
          name="name"
          rules={[{ required: true, message: t_common('requiredField') }]}
        >
          <Input.TextArea name="name" rows={1} />
        </Form.Item>
        <Form.Item label={t_ecosystem('comment')} name="comment">
          <Input.TextArea name="comment" rows={6} />
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default CreateEcosystemDrawer
