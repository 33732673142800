import { Layout as AntLayout } from 'antd'
import React from 'react'

import styles from './Layout.module.scss'

interface Props {
  className?: string
  children: any
}

const Layout = ({ className, children }: Props) => (
  <AntLayout className={[styles.layout, className].join(' ')}>
    {children}
  </AntLayout>
)

export default Layout
