import { TFunction } from 'react-i18next'

export enum Roles {
  CLIENT = 'CLIENT',
  STARTUP = 'STARTUP',
  BANQUIER = 'BANQUIER',
  INNO = 'INNO',
}

export class RolesHelper {
  public static allRoles = (): Roles[] => Object.values(Roles)

  public static format = (role: Roles | undefined, t: TFunction): string =>
    t(`roles.${role}`) ?? role
}
