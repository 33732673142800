import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import CascaderOptionWithCountModel, {
  CascaderOption,
} from '../../../components/Form/CascaderOptionWithCount.model'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../../../components/Form/OptionWithCount.model'
import RangeValueModel from '../../../components/Range/RangeValue.model'
import StartupContext from '../../../context/StartupContext'
import StartupStatusEnum from '../../../domain/startup-status.enum'
import { StartupModel } from '../../../domain/startup.model'
import { DataFilter, defaultDataFilterFn } from './StartupsFilter.model'

const countIntersect =
  <T>(startups: StartupModel[], getter: (s: StartupModel) => T[]) =>
  (option: OptionWithCountModel<T>): OptionWithCountModel<T> =>
    option.addCount(
      startups.filter((s: StartupModel): boolean =>
        (getter(s) || []).includes(option.value),
      ).length,
    )

const countInclude =
  <T>(startups: StartupModel[], getter: (s: StartupModel) => T) =>
  (option: OptionWithCountModel<T>): OptionWithCountModel<T> =>
    option.addCount(
      startups.filter((s: StartupModel): boolean => getter(s) === option.value)
        .length,
    )

const countCascaderInclude =
  (
    startups: StartupModel[],
    getter: (s: StartupModel) => { parent: string; child: string },
  ) =>
  (option: CascaderOptionWithCountModel): CascaderOptionWithCountModel => {
    const startupsWithOptionEcosystem = startups.filter(
      (s) =>
        getter(s).parent === option.value &&
        s.status !== StartupStatusEnum.DISABLED,
    )
    option.addCount(startupsWithOptionEcosystem.length)
    option.children.map((c) =>
      c.addCount(
        startupsWithOptionEcosystem.filter((s) => getter(s).child === c.value)
          .length,
      ),
    )
    return option
  }

const countRangeContains =
  (startups: StartupModel[], getter: (s: StartupModel) => number) =>
  (
    option: OptionWithCountModel<RangeValueModel>,
  ): OptionWithCountModel<RangeValueModel> =>
    option.addCount(
      startups.filter((s: StartupModel): boolean =>
        option.value.contains(getter(s)),
      ).length,
    )

type CountMapper<T> = (
  option: OptionWithCountModel<T>,
) => OptionWithCountModel<T>
type CompareFn<T> = (
  o1: OptionWithCountModel<T>,
  o2: OptionWithCountModel<T>,
) => number

const optionWithCountBuilder = <T>(
  datas: OptionWithCountModel<T>[],
  countMapper: CountMapper<any>,
  compareFn?: CompareFn<T>,
): OptionWithCountModel<T>[] =>
  compareFn ? datas.map(countMapper).sort(compareFn) : datas.map(countMapper)

const cascaderOptionWithCountBuilder = (
  datas: CascaderOptionWithCountModel[],
  countMapper: (
    option: CascaderOptionWithCountModel,
  ) => CascaderOptionWithCountModel,
) => datas.map(countMapper)

function useStartupsFilterData(startups: StartupModel[]): DataFilter {
  const { ecosystems, getEcosystemName } = useContext(StartupContext)
  const { t } = useTranslation('domain')

  const ecosystemsAndChilds: CascaderOption[] = ecosystems.flatMap((e) => ({
    label: e.name,
    value: e.name,
    children: e.subEcosystems.map((s) => ({ label: s.name, value: s.name })),
  }))

  const defaultDataFilter = defaultDataFilterFn(ecosystemsAndChilds, t)

  const [dataFilter, setDataFilter] = useState<DataFilter>(defaultDataFilter)

  useEffect((): void => {
    setDataFilter({
      statuses: optionWithCountBuilder(
        defaultDataFilter.statuses,
        countInclude(startups, (s) => s.status),
        // keeping natural order
      ),
      sectors: optionWithCountBuilder(
        defaultDataFilter.sectors,
        countInclude(startups, (s) => s.informations.sector),
        OptionWithCountHelper.sortByDisabledAndLabel,
      ),
      countries: optionWithCountBuilder(
        defaultDataFilter.countries,
        countInclude(startups, (s) => s.informations.country),
      ),

      regions: optionWithCountBuilder(
        defaultDataFilter.regions,
        countInclude(startups, (s) => s.informations.region),
        OptionWithCountHelper.sortByDisabledAndLabel,
      ),

      ecosystems: cascaderOptionWithCountBuilder(
        defaultDataFilter.ecosystems,
        countCascaderInclude(startups, (s) => ({
          parent: getEcosystemName(s),
          child: s.informations.subEcosystem,
        })),
      ),
      maturities: optionWithCountBuilder(
        defaultDataFilter.maturities,
        countInclude(startups, (s) => s.offer.maturity),
        // keeping natural order
      ),
      fundRaiseNeeds: optionWithCountBuilder(
        defaultDataFilter.fundRaiseNeeds,
        countIntersect(startups, (s) => s.raise.need),
        // keeping natural order
      ),
      fundRaiseStatus: optionWithCountBuilder(
        defaultDataFilter.fundRaiseStatus,
        countInclude(startups, (s) => s.raise.status),
        // keeping natural order
      ),
      fundRaiseValues: optionWithCountBuilder<RangeValueModel>(
        defaultDataFilter.fundRaiseValues,
        countRangeContains(startups, (s) => s.raise.targetMoney),
        OptionWithCountHelper.sortBy<RangeValueModel>((o1, o2) =>
          o1.value.compareTo(o2.value),
        ),
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startups])

  return dataFilter
}

export default useStartupsFilterData
