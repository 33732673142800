import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import {
  PianoAnalyticsContextI,
  defaultValue,
} from '../context/PianoAnalyticsContext'
import MyProfilDto from '../domain/MyProfilDto'
import { PianoAnalyticsService } from './PianoAnalytics.service'

declare global {
  interface Window {
    startupConnectionsConfig: {
      PIANO_ANALYTICS_SITE_ID: number
      COMMIT_ID: string
    }
  }
}

function usePianoAnalytics(user: MyProfilDto): PianoAnalyticsContextI {
  const location = useLocation()
  const [pianoAnalytics, setPianoAnalytics] =
    useState<PianoAnalyticsContextI>(defaultValue)

  useEffect(() => {
    if (
      window.startupConnectionsConfig &&
      window.startupConnectionsConfig.PIANO_ANALYTICS_SITE_ID
    ) {
      setPianoAnalytics(
        new PianoAnalyticsService(
          window.startupConnectionsConfig.PIANO_ANALYTICS_SITE_ID,
          user,
        ),
      )
    }
  }, [user])

  // auto trigger location change
  useEffect(() => {
    if (pianoAnalytics) {
      pianoAnalytics.triggerLocation(location)
    }
  }, [pianoAnalytics, location])

  return pianoAnalytics
}

export default usePianoAnalytics
