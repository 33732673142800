import { Tabs } from 'antd'
import { TabsProps } from 'antd/lib/tabs'
import React, { FC } from 'react'
import styles from './SectionTabs.module.scss'

type Props = Omit<TabsProps, 'type' | 'size'>

const SectionTabs: FC<TabsProps> = (tabsProps: Props) => (
  <Tabs
    {...tabsProps}
    type="card"
    size="large"
    className={[styles.SectionTabs, tabsProps.className].join(' ')}
  />
)

export default SectionTabs
