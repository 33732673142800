import { Skeleton } from 'antd'
import { TFunction } from 'i18next'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BadgeEventStatus from '../../components/Badge/BadgeEventStatus'
import Section from '../../components/Layout/Section'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import { EventDtoModel } from '../../domain/event/EventDtoModel'
import usePaginated from '../../services/usePaginated.hook'
import FormatHelper from '../../utils/FormatHelper'
import styles from './DemandesContactClient.module.scss'

const DemandesContactClient: FC = () => {
  const { listClientLinkingStartup } = useContext(ApiContext)
  const { t: t_event } = useTranslation('event')
  const { t: t_common } = useTranslation('commmon')
  const {
    data: events,
    loading,
    loadMore,
  } = usePaginated(listClientLinkingStartup)

  return (
    <Section>
      <h2>{t_event('connectionRequest')}</h2>
      {events.length === 0 && !loading ? (
        <p>{t_event('noConnectionRequest')}</p>
      ) : (
        EventList(events, t_event)
      )}
      {
        <Skeleton loading={loading} active={true} paragraph={{ rows: 1 }}>
          {loadMore ? (
            <MainButton className={styles.buttonLoad} onClick={loadMore}>
              {t_common('seeMore')}
            </MainButton>
          ) : null}
        </Skeleton>
      }
    </Section>
  )
}

const EventList = (events: EventDtoModel[], t: TFunction) => (
  <ul className="ul">
    {events.map((event, index) => (
      <li key={index}>
        {FormatHelper.formatDate(event.date, 'fr')} : {t('emitFor')}&nbsp;
        <Link
          className={styles.startupLink}
          to={`/startups/${event.startup?.id}`}
        >
          {event.startup?.fullName}
        </Link>
        <BadgeEventStatus status={event.status} />
      </li>
    ))}
  </ul>
)

export default DemandesContactClient
