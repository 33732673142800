import { Input, Row, Space, Typography } from 'antd'
import { AxiosError } from 'axios'
import React, { FC, ReactNode, useContext, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import MainButton from '../../components/MainButton/MainButton'
import AuthenticationContext from '../../context/AuthenticationContext'
import FormHelper from '../../utils/FormHelper'
import styles from './PasswordLogin.module.scss'
import { ResetPasswordModal } from './ResetPasswordModal'

const buildErrorMessage = (t: TFunction, contents: ReactNode[]): ReactNode => (
  <Typography.Text type="danger">
    <br />
    {t('weDontRecognizeThisEmailPasswordCombination')}
    <br />
    {contents.map(
      (content: ReactNode, index: number): ReactNode => (
        <React.Fragment key={index}>
          {content}
          {index !== contents.length - 1 ? (
            <>
              <br />
              <br />
            </>
          ) : null}
        </React.Fragment>
      ),
    )}
  </Typography.Text>
)

const getRequiredMessage = (t: TFunction): ReactNode => (
  <Typography.Text type="danger">
    {t('enterYourEmailAndPasswordToLogin')}
  </Typography.Text>
)

const getMessage = (t: TFunction, attemptLeft?: number): ReactNode => {
  let solution = t('retryOrContactYouIndosuezContactToResetYourPassword')

  if (attemptLeft == null || attemptLeft > 3) {
    return buildErrorMessage(t, [solution])
  } else if (attemptLeft === 0) {
    return buildErrorMessage(t, [
      <strong>{t('following5loginFailuresAccessIsBlocked')}</strong>,
      t('ifTheProblemPersistsAfterThisTime'),
    ])
  }
  return buildErrorMessage(t, [
    solution,
    <strong>
      {attemptLeft} {t('remainingTrial')}
    </strong>,
  ])
}

interface Props {
  mobile?: boolean
}

export const PasswordLogin: FC<Props> = ({
  mobile = false,
}: Props): JSX.Element => {
  const { t: t_login } = useTranslation('login')
  const [error, setError] = useState<ReactNode>()
  const [attemptLeft, setAttemptLeft] = useState<number | undefined>()
  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()

  const { authenticate } = useContext(AuthenticationContext)

  const basicAuthent = () => {
    let inputsBlank =
      FormHelper.isBlank(username) || FormHelper.isBlank(password)
    if (inputsBlank) {
      setError(getRequiredMessage(t_login))
    } else {
      const auth = { username: username ?? '', password: password ?? '' }
      authenticate(auth).catch((error: AxiosError) => {
        let count = attemptLeft
        if (error.response?.headers.attempt != null) {
          count = parseInt(error.response?.headers.attempt)
          setAttemptLeft(count)
        }
        setError(getMessage(t_login, count))
      })
    }
  }

  return (
    <Space
      size={15}
      direction="vertical"
      className={mobile ? '' : styles.loginForm}
    >
      <h1>{t_login('toLogin')}</h1>
      <Row justify="space-between">
        <label>{t_login('login')}</label>
        <Input
          size="large"
          autoCapitalize="none"
          onChange={(e): void => {
            setUsername(e.target.value)
          }}
        />
      </Row>
      <Row>
        <label>{t_login('password')}</label>
        <Input.Password
          size="large"
          iconRender={FormHelper.passwordIconRenderer}
          onChange={(e): void => {
            setPassword(e.target.value)
          }}
        />
      </Row>
      <ResetPasswordModal />
      <Row>
        <div className={styles.error}>{error}</div>
      </Row>
      <Row justify={'center'}>
        <MainButton onClick={basicAuthent}>{t_login('toLogin')}</MainButton>
      </Row>
      <Row>
        <p className={styles.help}>
          {t_login('notYetMemberOfStartupConnections')}
          <br />
          <strong>{t_login('contactYourIndosuezContactForAccess')}</strong>
        </p>
      </Row>
    </Space>
  )
}
