import { Collapse, Form } from 'antd'
import moment from 'moment'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import FormFooter from '../../../components/Form/FormFooter'
import ContactBloc from '../../../components/Layout/InfoBloc/ContactBloc'
import SectionWithInfo from '../../../components/Layout/SectionWithInfo'
import ApiContext from '../../../context/ApiContext'
import { StartupModel } from '../../../domain/startup.model'
import { StartupReq } from '../../../services/Api'
import usePermission from '../../../services/usePermission.hook'
import { Roles } from '../../../utils/roles.enum'
import InfosStartupForm from '../Update/Common/InfosStartupForm'
import OfferStartupForm from '../Update/Common/OfferStartupForm'
import RaiseStartupForm from '../Update/Common/RaiseStartupForm'
import TeamStartupForm from '../Update/Common/TeamStartupForm'
import styles from './ModifyStartup.module.scss'
import PicturesStartupForm from './Common/PicturesStartupForm'
import RoadMapStartupForm from './Common/RoadMapStartupForm'
import { FilePreview } from '../../../domain/filePreview.model'

const ModifyStartup: FC = () => {
  const [startup, setStartup] = useState<StartupModel>()
  const {
    errorFormBehaviour,
    successFormBehaviour,
    updateStartup,
    getMyStartup,
    getStartup,
    uploadStartupLogo,
    uploadStartupPicture,
    deleteStartupPicture,
    deleteStartupLogo,
  } = useContext(ApiContext)
  const { idStartup } = useParams<Record<string, string>>()
  const history = useHistory()

  const isInno = usePermission(Roles.INNO)
  const isStartup = usePermission(Roles.STARTUP)
  const { t: t_startup } = useTranslation('startup')
  const { t: t_common } = useTranslation('common')
  const [displayWarning, setDisplayWarning] = useState<boolean>(false)
  const [logoToAdd, setLogoToAdd] = useState<FilePreview>()
  const [picturesToAdd, setPicturesToAdd] = useState<FilePreview[]>([])

  const [form] = Form.useForm()

  useEffect(() => {
    if (isInno) {
      getStartup(idStartup).then(setStartup)
    } else if (isStartup) {
      getMyStartup().then(setStartup)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInno, isStartup])

  const checkValidFields = (changedFields: any[], allFields: any[]): void => {
    if (!allFields.every((field) => field.errors.length === 0)) {
      setDisplayWarning(true)
    }
  }

  const onSaveDraftMode = (errorInfo: any) => {
    save(errorInfo.values)
  }

  const updateAllFiles = async (promises: (() => Promise<any>)[]) => {
    let index = 0
    const requestUpdateFile = (): any => {
      if (index >= promises.length) {
        return
      }
      return promises[index]().then(() => {
        index++
        return requestUpdateFile()
      })
    }
    await requestUpdateFile()
  }

  const saveAssetFiles = async (): Promise<void> => {
    if (startup != null) {
      let promises: (() => Promise<any>)[] = []

      let toAdd: FilePreview[];
      if (startup.pictureIds !== null && picturesToAdd.length > 0) {
        toAdd = picturesToAdd.filter((picture) => !startup.pictureIds.includes(picture.id))
      } else {
        toAdd = picturesToAdd
      }
      toAdd.forEach((picture) => {
        promises.push(() =>
          uploadStartupPicture(startup.id, {
            id: picture.id,
            file: picture.file,
          }),
        )
      })

      let toDelete: string[] = [];
      if (startup.pictureIds !== null && picturesToAdd.length > 0) {
        toDelete = startup.pictureIds.filter(
          (id) => picturesToAdd.filter(c => c.isDelete).map((picture) => picture.id).includes(id),
        )
      }
      toDelete.forEach((id: string) => {
        promises.push(() => deleteStartupPicture(startup.id, id))
      })

      if (logoToAdd !== undefined) {
        if (logoToAdd.id !== startup.informations.logoId) {
          promises.push(() =>
            uploadStartupLogo(startup.id, {
              id: logoToAdd.id,
              file: logoToAdd.file,
            }),
          )
          if (startup.informations.logoId) {
            promises.push(() =>
              deleteStartupLogo(startup.id, startup.informations.logoId),
            )
          }
        }
      }

      if (promises.length > 0) {
        return updateAllFiles(promises)
          .then(() => successFormBehaviour(t_startup('saveCard')))
          .catch(errorFormBehaviour)
      }
    }
  }

  const save = (startupData: StartupReq) => {
    if (startup != null) {
      if (startupData.raise.targetMoney === 0 || startupData.raise.targetMoney === null) {
        startupData.raise.targetMoney = 100000
      }
      if (startupData.raise.minInvest === 0 || startupData.raise.minInvest === null) {
        startupData.raise.minInvest = 100000
      }
      updateStartup(startup.id, startupData)
        .then(() =>
          saveAssetFiles().then(() =>
            history.push(`/startups/${isStartup ? 'my' : startup.id}`),
          ),
        )
        .catch(errorFormBehaviour)
    }
  }

  const header = (value: string) => (
    <span className={styles.collapsePanelHeader}>{value}</span>
  )

  const formatInitialRoadMap = (): any[] => {
    let keyDates: any[] = []
    if (
      startup &&
      startup.informations !== null &&
      startup.informations.roadMap &&
      startup.informations.roadMap.length > 0
    ) {
      startup.informations.roadMap.forEach((keyDate) => {
        keyDates.push({
          date: keyDate.date == null ? null : moment(keyDate.date),
          descriptionFr: keyDate.descriptionFr,
          descriptionEn: keyDate.descriptionEn,
        })
      })
    } else {
      keyDates = [{}]
    }
    return keyDates
  }

  return startup == null ? (
    <></>
  ) : (
    <SectionWithInfo infoBloc={<ContactBloc />}>
      <h2 className="mb-60">{t_startup('modifyStartupCard')}</h2>
      <Form
        layout="vertical"
        form={form}
        onFinishFailed={onSaveDraftMode}
        onFinish={save}
        onFieldsChange={checkValidFields}
        requiredMark={true}
        initialValues={{
          ...startup,
          raise: {
            ...startup.raise,
            dateRaise:
              startup.raise.dateRaise == null
                ? null
                : moment(startup.raise.dateRaise),
          },
          informations: {
            ...startup.informations,
            roadMap: formatInitialRoadMap(),
          },
        }}
      >
        <Collapse className={styles.collapse}>
          <Collapse.Panel
            key={'Identité'}
            header={header(t_startup('identity'))}
            forceRender={true}
            className={styles.collapsePanel}
          >
            <InfosStartupForm
              startup={startup}
              updateLogoToAdd={setLogoToAdd}
              prefix={'informations'}
              form={form}
            />
            <RoadMapStartupForm prefix={'informations'} />
          </Collapse.Panel>

          <Collapse.Panel
            key={'Activité'}
            header={header(t_startup('activity'))}
            forceRender={true}
            className={styles.collapsePanel}
          >
            <OfferStartupForm prefix={'offer'} />
          </Collapse.Panel>

          <Collapse.Panel
            key={'Objectif'}
            header={header(t_startup('objective'))}
            forceRender={true}
            className={styles.collapsePanel}
          >
            <RaiseStartupForm
              prefix={'raise'}
              initialNeeds={startup.raise.need}
            />
          </Collapse.Panel>

          <Collapse.Panel
            key={'Équipe'}
            header={header(t_startup('team'))}
            forceRender={true}
            className={styles.collapsePanel}
          >
            <TeamStartupForm prefix={'team'} />
          </Collapse.Panel>

          <Collapse.Panel
            key={'Pictures'}
            header={header(t_startup('pictures'))}
            forceRender={true}
            className={styles.collapsePanel}
          >
            <PicturesStartupForm
              updateFilesToAdd={setPicturesToAdd}
              pictureIds={startup.pictureIds ?? []}
            />
          </Collapse.Panel>
        </Collapse>
        {displayWarning && (
          <div className={styles.warningMessage}>
            {t_startup('missingFields')}
          </div>
        )}
        <FormFooter showTips={false} saveLabel={t_common('save')} />
      </Form>
    </SectionWithInfo>
  )
}
export default ModifyStartup
