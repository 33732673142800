import { TFunction } from 'react-i18next'

enum StartupMaturitiesEnum {
  R_D = 'R_D',
  MARKET_FIT = 'MARKET_FIT',
  CROISSANCE = 'CROISSANCE',
}

export class StartupMaturitiesHelper {
  static format = (maturities: StartupMaturitiesEnum, t: TFunction): string =>
    t(`startupMaturities.format.${maturities}`) || maturities

  static formatForDescription = (
    maturities: StartupMaturitiesEnum,
    t: TFunction,
  ): string =>
    t(`startupMaturities.formatForDescription.${maturities}`) || maturities

  static allMaturities = (): StartupMaturitiesEnum[] =>
    Object.values(StartupMaturitiesEnum)
}

export default StartupMaturitiesEnum
