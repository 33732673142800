import { Space } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import InputPrice from '../Form/InputPrice'
import RangeValueModel from './RangeValue.model'

interface Props {
  value?: RangeValueModel
  onChange?: (range: RangeValueModel) => void
}

const InputRangeNumber: FC<Props> = ({ value, onChange = () => {} }: Props) => {
  const { t: t_input } = useTranslation('input')
  const { t: t_common } = useTranslation('common')
  const setStart = (start: number): void =>
    onChange(RangeValueModel.of(start || 0, value?.end))
  const setEnd = (end: number): void =>
    onChange(RangeValueModel.of(value?.start || 0, end))

  return (
    <Space wrap={true}>
      {t_input('between')}
      <InputPrice value={value?.start} onChange={setStart} />
      {t_common('and')}
      <InputPrice min={value?.start} value={value?.end} onChange={setEnd} />
    </Space>
  )
}

export default InputRangeNumber
