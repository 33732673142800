import { Button } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'

const LinkExpired: FC = () => {
  const { t } = useTranslation('login')
  return (
    <div>
      <p>
        {t('theLinkYouFollowedHasExpired')}
        <br />
        {t('contactYouIndosuezContactIfYouHaveAnyTrouble')}
      </p>
      <Button type="link" href="/login">
        {t('backToLoginPage')}
      </Button>
    </div>
  )
}

export default LinkExpired
