import React, { FC } from 'react'
import styles from './InfoBloc.module.scss'

interface Props {
  size?: string
  children: any
}

const InfoBloc: FC<Props> = ({ size = 'small', children }: Props) => (
  <div
    className={`${styles.infoBloc} ${
      size === 'large' ? styles.largeInfoBloc : ''
    }`}
  >
    {children}
  </div>
)

export default InfoBloc
