import { TFunction } from 'react-i18next'

enum StartupRaiseStatusEnum {
  SEED = 'SEED',
  SERIE_A = 'SERIE_A',
  SERIE_B = 'SERIE_B',
  NEXT_SERIES = 'NEXT_SERIES',
}

export class StartupRaiseStatusHelper {
  static format = (raiseStatus: StartupRaiseStatusEnum, t: TFunction): string =>
    t(`startupRaiseStatus.${raiseStatus}`) || raiseStatus

  static allRaiseStatus = (): StartupRaiseStatusEnum[] =>
    Object.values(StartupRaiseStatusEnum)
}

export default StartupRaiseStatusEnum
