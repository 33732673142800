import { getHost } from '../../conf'
import FormatHelper from '../../utils/FormatHelper'

export interface Mail {
  subject: string
  body: string
  dest: string
}

class MailService {
  static buildInviteEmail = (
    email: string,
    token: string,
    expirationDate: number,
  ): Mail => {
    let subject = 'Invitation à rejoindre Startup Connections by Indosuez'
    let body =
      `Bonjour,` +
      `\n\r Nous vous invitons à vous inscrire sur la plateforme Startup Connections by Indosuez !` +
      `\n\r Il vous suffit pour cela de cliquer sur le lien suivant et de suivre les indications :` +
      `\r - ${getHost()}/initPassword?token=${token}&email=${email} ` +
      `\r Ce lien est valable jusqu'au ${FormatHelper.formatDate(
        expirationDate,
        'fr',
      )}.` +
      ` Si vous n'êtes pas intéressé(e), précisez-le nous simplement par retour d'email.` +
      `\n\r Une fois inscrit, il vous suffira de vous rendre directement sur ${getHost()}` +
      `\n\r Bien cordialement,` +
      `\n\r L’équipe Startup Connections by Indosuez`
    return { subject, body, dest: email }
  }

  static buildResetMail = (
    email: string,
    token: string,
    expirationDate: number,
  ): Mail => {
    let subject =
      'Réinitialisation de vos accès à Startup Connections by Indosuez'
    let body =
      `Bonjour, ` +
      `\n\r Veuillez cliquer sur le lien ci-dessus pour réinitialiser vos accès sur Startup Connections by Indosuez Wealth Management:  ` +
      `\r - ${getHost()}/resetPassword?token=${token}&email=${email} ` +
      `\r Ce lien est valable jusqu'au ${FormatHelper.formatDate(
        expirationDate,
        'fr',
      )}` +
      `\n\r Il vous suffira ensuite de vous rendre directement sur ${getHost()}` +
      `\n\r Bien cordialement,` +
      `\n\r L’équipe Startup Connections by Indosuez`
    return { subject, body, dest: email }
  }
}

export default MailService
