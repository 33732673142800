import { Layout as AntLayout } from 'antd'
import React, { FC } from 'react'
import InitAccountForm from '../../components/Authentication/InitAccountForm'
import LinkExpired from '../../components/Authentication/LinkExpired'
import EmptyHeader from '../../components/Layout/Header/EmptyHeader'
import Footer from '../../components/Layout/Footer'
import ContactBloc from '../../components/Layout/InfoBloc/ContactBloc'
import Layout from '../../components/Layout/Layout'
import SectionWithInfo from '../../components/Layout/SectionWithInfo'
import useTokenValidation from '../../services/useTokenValidation.hook'

const InitAccount: FC<void> = () => {
  const [warning, token, email] = useTokenValidation()

  return (
    <Layout>
      <EmptyHeader />
      <AntLayout.Content>
        <SectionWithInfo infoBloc={<ContactBloc />}>
          {warning ? (
            <LinkExpired />
          ) : (
            <InitAccountForm token={token} email={email} />
          )}
        </SectionWithInfo>
      </AntLayout.Content>
      <Footer />
    </Layout>
  )
}

export default InitAccount
