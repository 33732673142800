import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import InfoBloc from './InfoBloc'

interface Props {
  size?: string
}

const ContactBloc: FC<Props> = ({ size }: Props) => {
  const { t } = useTranslation('login')
  return (
    <InfoBloc size={size}>
      <h3>{t('yourContact')}</h3>
      <address>
        <strong>Benoît Bourdin</strong>
        <br />
        {t('innovationMarketManager')}
      </address>
      <div>
        <a href="mailto:benoit.bourdin@ca-indosuez.com">
          benoit.bourdin@ca-indosuez.com
        </a>
        <br />
        <a href="tel:+33637233702">+33 6 37 23 37 02</a>
      </div>
    </InfoBloc>
  )
}

export default ContactBloc
