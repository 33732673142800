export const GET = 'GET'
export const POST = 'POST'
export const PUT = 'PUT'
export const DELETE = 'DELETE'

export default {
  GET,
  PUT,
  POST,
  DELETE,
}
