import { Col, Divider, Row, Steps } from 'antd'
import { StepProps } from 'antd/lib/steps'
import { TFunction } from 'i18next'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import MainButton from '../../../components/MainButton/MainButton'
import styles from './ValidationStep.module.scss'
import formStyles from '../Update/Common/StartupForm.module.scss'
import ContactBloc from '../../../components/Layout/InfoBloc/ContactBloc'

const stepsProps = (t: TFunction): StepProps[] => [
  { title: t('init.steps.first.title') },
  {
    title: t('init.steps.second.title'),
    // TODO disabled because not implemented : description: "Vous en serez averti sur prenom.nom@startup.com."
  },
  {
    title: t('init.steps.third.title'),
    description: t('init.steps.third.description'),
  },
  // TODO disabled because not implemented : {title: "Les statistiques de visite seront visibles sur votre espace."}
]

const ValidationStep: FC = () => {
  const { t: t_startup } = useTranslation('startup')
  const { t: t_common } = useTranslation('common')
  return (
    <Row>
      <Col xs={24} sm={24} md={24} lg={17} className={styles.marginBottom}>
        <h2 className="mb-60">{t_common('confirmation')}</h2>
        <p className={styles.paragraph}>
          <strong>{t_common('thanks')}</strong>
          <br />
          {t_startup('init.yourCardHasBeenCreated')}
        </p>
        <Divider className={formStyles.formDivider} />

        <p className={styles.paragraph}>{t_startup('init.nextSteps')}</p>
        <Steps
          progressDot
          current={stepsProps(t_startup).length + 1}
          direction="vertical"
          className={styles.marginBottom}
        >
          {stepsProps(t_startup).map((stepProps) => (
            <Steps.Step {...stepProps} />
          ))}
        </Steps>

        <Link to="/startups/my">
          <MainButton>{t_startup('seeYourCard')}</MainButton>
        </Link>
      </Col>
      <Col xs={24} sm={24} md={24} lg={{ span: 6, offset: 1 }}>
        <ContactBloc />
      </Col>
    </Row>
  )
}

export default ValidationStep
