import { Col, Input, Row, Select, Skeleton } from 'antd'
import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GridCard from '../../../components/Layout/GridCard'
import Section from '../../../components/Layout/Section'
import StartupContext from '../../../context/StartupContext'
import { StartupModel } from '../../../domain/startup.model'
import StartupsFilter from '../Filter/StartupsFilter'
import StartupCard from '../StartupCard'
import StartupCardLoading from '../StartupCardLoading'
import styles from './StartupGrid.module.scss'
import { StartUpTable } from './StartUpTable'
import usePermission from '../../../services/usePermission.hook'
import { Roles } from '../../../utils/roles.enum'
import { DisplayClientActivity } from './DisplayClientActivity'
import { SwitchGridTableView } from '../../Shared/SwitchGridTableView'
import { DisplayShortNews } from '../../News/DisplayShortNews'
import { SortByEnum } from '../../../domain/sortBy.enum'
import FormatHelper from '../../../utils/FormatHelper'
import { DisplayStartupNews } from '../../News/DisplayStartupNews'
import FormHelper from '../../../utils/FormHelper'

const pageSize = 9

interface Props {
  loading?: boolean
}

const StartupsGrid: FC<Props> = ({ loading }: Props) => {
  const { startups } = useContext(StartupContext)
  const [filteredStartups, setFilteredStartups] =
    useState<StartupModel[]>(startups)

  const [displayCount, setDisplayCount] = useState<number>(pageSize)
  const { t } = useTranslation('startup')
  const [tableView, setTableView] = useState<boolean>(false)
  const [sortBy, setSortBy] = useState(SortByEnum.DATE_DESC)

  const isClient = usePermission(Roles.CLIENT)

  const sortOptions = [
    { label: t('sort.DATE_DESC'), value: SortByEnum.DATE_DESC },
    { label: t('sort.DATE_ASC'), value: SortByEnum.DATE_ASC },
    { label: t('sort.NAME_DESC'), value: SortByEnum.NAME_DESC },
    { label: t('sort.NAME_ASC'), value: SortByEnum.NAME_ASC },
  ]

  const sortStartups = (): StartupModel[] => {
    switch (sortBy) {
      case SortByEnum.DATE_DESC:
        const toCheckStartups = filteredStartups.filter(
          (s) => s.validateDate === null,
        )
        const othersStartups = filteredStartups.filter(
          (s) => s.validateDate !== null && s.validateDate !== undefined,
        )
        othersStartups
          .sort((a, b) => a.validateDate! - b.validateDate!)
          .reverse()
        toCheckStartups.push(...othersStartups)
        return toCheckStartups
      case SortByEnum.DATE_ASC:
        return filteredStartups.sort((a, b) =>
          FormatHelper.comparePossiblyNullNumber(
            a.validateDate,
            b.validateDate,
          ),
        )
      case SortByEnum.NAME_DESC:
        return filteredStartups.sort((a, b) =>
          Intl.Collator().compare(a.informations.name, b.informations.name),
        )
      case SortByEnum.NAME_ASC:
        return filteredStartups
          .sort((a, b) =>
            Intl.Collator().compare(a.informations.name, b.informations.name),
          )
          .reverse()
      default:
        return filteredStartups
    }
  }

  const filterStartups = (search: string): void => {
    if (FormHelper.isBlank(search)) {
      setFilteredStartups(startups)
    } else {
      setFilteredStartups(
        startups.filter((startup) =>
          `${startup.informations.name}`
            .toLowerCase()
            .includes(search.toLowerCase()),
        ),
      )
    }
  }

  if (loading) {
    return (
      <>
        <Section theme="dark">
          <h2>{t('loading_startup')}</h2>
          <Skeleton title={false} active={true} />
        </Section>
        <GridCard dataCards={[0, 1, 2]}>
          {() => <StartupCardLoading />}
        </GridCard>
      </>
    )
  }

  return (
    <>
      {isClient && <DisplayClientActivity />}
      <Section theme={'light'}>
        <Row gutter={20}>
          <Col
            xs={24}
            md={24}
            lg={24}
            xl={isClient ? 18 : 24}
            style={isClient ? { marginBottom: '20px' } : {}}
          >
            <StartupsFilter
              startups={startups}
              onFilter={setFilteredStartups}
            />
            <Row justify={'space-between'} className={styles.marginBottom}>
              <Col xs={24} sm={24} md={12}>
                <SwitchGridTableView
                  tableView={tableView}
                  onChange={setTableView}
                />
                <div className={'search-bar-container ' + styles.marginTop}>
                  <label className={'search-label'}>
                    {t('search_among_startups')}
                  </label>
                  <Input.Search
                    placeholder={'ex: Fake Startup'}
                    allowClear
                    onChange={(e) => filterStartups(e.target.value)}
                    style={{ width: 300 }}
                  />
                </div>
              </Col>
              {!tableView && (
                <Col xs={24} sm={24} md={12} lg={6}>
                  <small className={styles.SortText}>{t('orderBy')}</small>
                  <Select
                    options={sortOptions}
                    defaultValue={sortBy}
                    onChange={(value: SortByEnum): void => setSortBy(value)}
                    style={{ width: '100%' }}
                  />
                </Col>
              )}
            </Row>
            {tableView ? (
              <StartUpTable startUps={startups} />
            ) : (
              <GridCard
                dataCards={sortStartups().slice(0, displayCount)}
                total={filteredStartups.length}
                viewMore={() => setDisplayCount(displayCount + pageSize)}
                what="startups"
              >
                {(s) => <StartupCard startup={s} className={styles.grid} />}
              </GridCard>
            )}
          </Col>
          {isClient && (
            <Col xs={24} md={24} lg={24} xl={6}>
              <DisplayShortNews />
              <DisplayStartupNews />
            </Col>
          )}
        </Row>
      </Section>
    </>
  )
}

export default StartupsGrid
