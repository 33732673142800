import React, { FC } from 'react'
import styles from './Steps.module.scss'

interface Props {
  size: number
  current: number
}

const computeClassNames = (step: number, current: number): string => {
  if (step < current) {
    return [styles.StepsItemDot, styles.StepsItemDotDone].join(' ')
  } else if (step === current) {
    return [styles.StepsItemDot, styles.StepsItemDotDoing].join(' ')
  }
  return styles.StepsItemDot
}

const Steps: FC<Props> = ({ current, size }: Props) => (
  <div className={styles.Steps}>
    {Array.from(Array(size).keys()).map((index: number) => {
      let step = index + 1
      return (
        <React.Fragment key={step}>
          <div className={styles.StepsItemTail} />
          <div className={computeClassNames(step, current)}>
            {step === current ? step : ''}
          </div>
        </React.Fragment>
      )
    })}
    <div className={styles.StepsTail} />
  </div>
)

export default Steps
