import React from 'react'
import { FixMeLater } from '../App'
import UserModel from '../domain/admin/user.model'
import { ClientListModel } from '../domain/client/client-list.model'
import { ClientModel } from '../domain/client/client.model'
import { EventDtoModel } from '../domain/event/EventDtoModel'
import { EventModel } from '../domain/event/EventModel'
import { EventStatusEnum } from '../domain/event/EventStatus.enum'
import { EventTypeEnum } from '../domain/event/EventType.enum'
import PageResultModel from '../domain/PageResult.model'
import { PasswordToken } from '../domain/password-token.model'
import { QuickSearchResultModel } from '../domain/quickSearchResult.model'
import { StartupModel } from '../domain/startup.model'
import { EcosystemsModel } from '../domain/ecosystems.model'
import {
  CreateEventReq,
  EcosystemReq,
  EditClientReq,
  InviteReq,
  StartupInformationsReq,
  StartupOfferReq,
  StartupRaiseReq,
  StartupReq,
  StartupTeamReq,
  UpdateAccountReq,
  UpdateEventReq,
  UploadFileReq,
} from '../services/Api'
import {
  ConnectionRequestStatistics,
  DashboardStatisticsModel,
} from '../domain/admin/dashboard.model'
import { ShortNewsModel } from '../domain/news/shortNews.model'
import { StartupNewsModel } from '../domain/news/startupNews.model'
import { RssFeedModel } from '../domain/news/rssFeed.model'

const notImplemented = () => Promise.reject(new Error('not implemented yet'))

const emptyImplemented = () => {}

export interface ApiContextI {
  getVersion: () => Promise<FixMeLater>
  quickSearch: (searchText: string) => Promise<QuickSearchResultModel>

  /** USER **/
  consent: () => Promise<FixMeLater>
  isConsented: () => Promise<FixMeLater>
  updateAccount: (id: string, req: UpdateAccountReq) => Promise<void>
  getUser: (id: string) => Promise<UserModel>

  /** ADMIN **/
  inviteUser: (inviteReq: InviteReq) => Promise<PasswordToken>
  resetUserPassword: (email: string) => Promise<PasswordToken>
  listUsers: () => Promise<UserModel[]>
  deleteUser: (email: string) => Promise<void>

  /** STARTUP **/
  getStartup: (id: string) => Promise<StartupModel>
  getMyStartup: () => Promise<StartupModel>
  getStartupVisits: (
    id: string,
    startDate: string | null,
    endDate: string | null,
  ) => Promise<number>
  getStartupVisitors: (
    id: string,
    startDate: string | null,
    endDate: string | null,
  ) => Promise<number>

  saveStartup: (startup: FixMeLater) => Promise<FixMeLater>
  updateStartupRaise: (id: string, data: StartupRaiseReq) => Promise<void>
  getStartups: () => Promise<StartupModel[]>
  updateStartupInformations: (
    id: string,
    data: StartupInformationsReq,
    logo?: File,
  ) => Promise<void>
  updateStartup: (id: string, data: StartupReq) => Promise<void>
  updateStartupOffer: (id: string, data: StartupOfferReq) => Promise<void>
  updateStartupTeam: (id: string, files: StartupTeamReq) => Promise<void>
  uploadStartupLogo: (id: string, picture: UploadFileReq) => Promise<void>
  uploadStartupPicture: (id: string, picture: UploadFileReq) => Promise<void>
  deleteStartupPicture: (id: string, assetId: string) => Promise<void>
  deleteStartupLogo: (id: string, assetId: string) => Promise<void>
  validateStartup: (idStartup: string) => Promise<void>
  disableStartup: (idStartup: string) => Promise<void>
  deleteStartup: (idStartup: string) => Promise<void>
  getLastVisitedStartupsByClient: () => Promise<StartupModel[]>

  /** CLIENT **/
  listClients: () => Promise<ClientListModel[]>
  getMyClientProfil: () => Promise<ClientModel>
  getClient: (id: string) => Promise<ClientModel>
  saveClient: (id: string, data: EditClientReq) => Promise<void>

  createClientLinkingStartup: (startupId: string) => Promise<void>
  getClientLinkingStartup: (startupId: string) => Promise<EventModel>
  listClientLinkingStartup: (
    page?: number,
    pageSize?: number,
  ) => Promise<PageResultModel<EventDtoModel>>

  /** EVENT **/
  listEvents: (
    types?: EventTypeEnum[],
    statuses?: EventStatusEnum[],
    page?: number,
    pageSize?: number,
  ) => Promise<PageResultModel<EventDtoModel>>

  saveEvent: (id: string, req: UpdateEventReq) => Promise<void>
  createEvent: (req: CreateEventReq) => Promise<EventDtoModel>
  countEvents: () => Promise<ConnectionRequestStatistics>

  // TODO to be removed ?
  successFormBehaviour: (title: string) => void
  errorFormBehaviour: (error: any) => void

  /** ECOSYSTEMS **/
  getEcosystems: () => Promise<EcosystemsModel[]>
  createEcosystem: (req: EcosystemReq) => Promise<EcosystemsModel>
  saveEcosystem: (id: string, req: EcosystemReq) => Promise<void>
  deleteEcosystem: (id: string) => Promise<void>

  /** ADMIN DASHBOARD **/
  getDashboardStatistics: () => Promise<DashboardStatisticsModel>

  /** SHORT NEWS **/
  getShortNews: () => Promise<ShortNewsModel>
  postShortNews: (data: ShortNewsModel) => Promise<void>
  getStartupNews: () => Promise<StartupNewsModel[]>

  /** STARTUP NEWS **/
  getRssFeeds: () => Promise<RssFeedModel[]>
  postRssFeeds: (data: RssFeedModel[]) => Promise<void>
}

const ApiContext = React.createContext<ApiContextI>({
  consent: notImplemented,
  errorFormBehaviour: emptyImplemented,
  getMyStartup: notImplemented,
  getStartupVisits: notImplemented,
  getStartupVisitors: notImplemented,
  listClients: notImplemented,
  getStartup: notImplemented,
  updateStartupInformations: notImplemented,
  updateStartupOffer: notImplemented,
  updateStartupTeam: notImplemented,
  uploadStartupLogo: notImplemented,
  uploadStartupPicture: notImplemented,
  deleteStartupPicture: notImplemented,
  deleteStartupLogo: notImplemented,
  updateStartupRaise: notImplemented,
  updateStartup: notImplemented,
  saveClient: notImplemented,
  getStartups: notImplemented,
  getVersion: notImplemented,
  isConsented: notImplemented,
  saveStartup: notImplemented,
  successFormBehaviour: emptyImplemented,
  validateStartup: notImplemented,
  quickSearch: notImplemented,
  inviteUser: notImplemented,
  resetUserPassword: notImplemented,
  listUsers: notImplemented,
  getClient: notImplemented,
  getMyClientProfil: notImplemented,
  updateAccount: notImplemented,
  getUser: notImplemented,
  createClientLinkingStartup: notImplemented,
  getClientLinkingStartup: notImplemented,
  listClientLinkingStartup: notImplemented,
  listEvents: notImplemented,
  countEvents: notImplemented,
  saveEvent: notImplemented,
  createEvent: notImplemented,
  disableStartup: notImplemented,
  deleteStartup: notImplemented,
  getLastVisitedStartupsByClient: notImplemented,
  getEcosystems: notImplemented,
  createEcosystem: notImplemented,
  saveEcosystem: notImplemented,
  deleteEcosystem: notImplemented,
  deleteUser: notImplemented,
  getDashboardStatistics: notImplemented,
  getShortNews: notImplemented,
  postShortNews: notImplemented,
  getStartupNews: notImplemented,
  getRssFeeds: notImplemented,
  postRssFeeds: notImplemented,
})

export default ApiContext
