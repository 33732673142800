import {
  CaretDownOutlined,
  MenuOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Layout, Menu, MenuProps } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { ReactComponent as LogoStartup } from '../../../styles/svg/LogoStartupConnections_byIndosuez.svg'
import QuickSearch from '../../QuickSearch'
import { HeaderProps } from './Header'
import styles from './HeaderDesktop.module.scss'
import SelectLanguage from '../../SelectLanguage'
import { useTranslation } from 'react-i18next'

const HeaderDesktop: FC<HeaderProps> = ({
  routes,
  userRoutes,
  showQuickSearch,
  userName,
  logout,
  home,
}: HeaderProps) => {
  const location = useLocation()
  const [paths, setPaths] = useState<string[]>([])
  const { t } = useTranslation('common')

  useEffect((): void => {
    const matchedPath = location.pathname
      .split('/')
      .filter((part: string) => part.trim() !== '')
      .reduce((result: string[], part: string, partIndex: number): string[] => {
        let previous = partIndex > 0 ? result[partIndex - 1] : ''
        result.push(previous + '/' + part)
        return result
      }, [])

    setPaths(matchedPath)
  }, [location.pathname])

  const items: MenuProps['items'] = [
    ...routes.map((route) => ({
      key: route.to,
      label: <NavLink to={route.to}>{route.label}</NavLink>,
    })),
    ...[
      {
        label: <QuickSearch />,
        key: 'key-quick-search',
        className: 'not-menu-item',
      },
    ].filter((i) => showQuickSearch),
    {
      label: <SelectLanguage />,
      key: 'key-select-language',
      className: 'not-menu-item',
    },
    {
      label: (
        <>
          {userName}
          <CaretDownOutlined className="ant-icon-primary" />
        </>
      ),
      className: styles.pullRight,
      key: 'userMenu',
      icon: (
        <>
          <UserOutlined className="ant-icon-primary" />
        </>
      ),
      children: [
        ...userRoutes.map((route) => ({
          key: route.to,
          label: <NavLink to={route.to}>{route.label}</NavLink>,
        })),
        {
          label: (
            <>
              <span onClick={logout}>{t('navbar.signOut')}</span>
            </>
          ),
          key: 'logout',
        },
      ],
    },
  ]

  return (
    <Layout.Header className={styles.header}>
      <LogoStartup className={styles.logo} onClick={home} />
      <Menu
        items={items}
        mode="horizontal"
        defaultSelectedKeys={['/']}
        selectedKeys={paths}
        overflowedIndicator={<MenuOutlined className="mr-0" />}
        className={[styles.menu, 'no-print', styles.headerInline].join(' ')}
      />
    </Layout.Header>
  )
}

export default HeaderDesktop
