import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  EventStatusEnum,
  EventStatusHelper,
} from '../../domain/event/EventStatus.enum'
import Badge from './Badge'

interface Props {
  status: EventStatusEnum
}

const BadgeEventStatus: FC<Props> = ({ status }: Props) => {
  const { t } = useTranslation('domain')
  return (
    <Badge
      backgroundColor={EventStatusHelper.color(status)}
      text={EventStatusHelper.format(status, t)}
    />
  )
}

export default BadgeEventStatus
