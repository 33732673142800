// These must be the first lines in src/index.js
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

//next
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import './index.css'
import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationFr from './translations/fr/'
import translationEn from './translations/en/'
import { defineCustomElements } from '@iwm-ds/react'

defineCustomElements()

i18next
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: translationEn,
      fr: translationFr,
    },
    lng: navigator.language.includes('fr') ? 'fr' : 'en', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)
