import React, { FC } from 'react'
import Section from '../../components/Layout/Section'
import { useTranslation } from 'react-i18next'
import styles from './ClientActivity.module.scss'
import { Grid } from 'antd'
import { StartUpActivityModel } from '../../domain/startupActivity.model'
import { ClientConnectionEvents } from './ClientConnectionEvents'
import { ViewedStartupsMobile } from './ViewedStartupsMobile'
import { ViewedStartupsDesktop } from './ViewedStartupsDesktop'

interface Props {
  connectionHistory: number[]
  viewedStartUps: StartUpActivityModel[]
}

export const ClientActivity: FC<Props> = ({
  connectionHistory,
  viewedStartUps,
}: Props): JSX.Element => {
  const breakpoint = Grid.useBreakpoint()
  const { t: t_client } = useTranslation('client')

  return (
    <Section>
      {connectionHistory ? (
        <>
          <div
            className={
              breakpoint.xs
                ? styles.datesContainerMobile
                : styles.datesContainerDesktop
            }
          >
            <div className={styles.categoryTitle}>
              {connectionHistory.length}
              {t_client('activity.nbConnectionsInLast90Days')}{' '}
            </div>
            <ClientConnectionEvents connectionHistory={connectionHistory} />
          </div>
        </>
      ) : (
        <div>{t_client('activity.noConnectionHistory')}</div>
      )}
      {viewedStartUps ? (
        <>
          {breakpoint.xs ? (
            <ViewedStartupsMobile viewedStartUps={viewedStartUps} />
          ) : (
            <ViewedStartupsDesktop viewedStartUps={viewedStartUps} />
          )}
        </>
      ) : (
        <div>{t_client('activity.noStartupHistoryByClient')}</div>
      )}
    </Section>
  )
}
