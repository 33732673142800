import { InputNumber } from 'antd'
import { InputNumberProps } from 'antd/lib/input-number'
import React, { FC } from 'react'
import FormatHelper from '../../utils/FormatHelper'
import styles from './InputPrice.module.scss'

type Props = Omit<InputNumberProps, 'onChange' | 'formatter'> & {
  onChange?: (v: number) => void
  fullWidth?: boolean
}

const InputPrice: FC<Props> = ({
  min = 0,
  step = 5000,
  size = 'large',
  onChange = () => {},
  fullWidth = false,
  ...props
}) => {
  return (
    <InputNumber
      {...props}
      className={`${styles.inputNumber} ${
        fullWidth ? styles.fullWidthInput : ''
      }`}
      size={size}
      min={min}
      step={step}
      formatter={FormatHelper.currencyFormatter}
      onChange={(v) => onChange(v as number)}
    />
  )
}

export default InputPrice
