import { Card, Divider, Skeleton } from 'antd'
import React, { FC } from 'react'
import styles from './StartupCard.module.scss'

const StartupCardLoading: FC = () => {
  return (
    <Card
      hoverable
      className={styles.card}
      cover={<Skeleton.Image className={styles.logoLoading} />}
    >
      <Skeleton active={true} />
      <Divider />
      <Skeleton active={true} title={false} />
    </Card>
  )
}

export default StartupCardLoading
