import React, { FC, useContext, useState } from 'react'
import { Button, Drawer, Grid } from 'antd'
import { useTranslation } from 'react-i18next'
import ApiContext from '../../context/ApiContext'
import UserModel from '../../domain/admin/user.model'
import ModifyProfile, { UserData } from './ModifyProfile'

interface Props {
  id?: string
  refreshUsers: () => void
}

export const EditUserByAdminModal: FC<Props> = ({
  id,
  refreshUsers,
}: Props): JSX.Element => {
  const { getUser, errorFormBehaviour } = useContext(ApiContext)
  const { t } = useTranslation('users')
  const breakpoint = Grid.useBreakpoint()

  const [open, setOpen] = useState<boolean>(false)
  const [user, setUser] = useState<UserData>()

  const onClose = () => {
    setOpen(false)
  }

  const showDrawer = () => {
    id &&
      getUser(id)
        .then((u: UserModel): void => {
          setUser({
            id: id,
            firstName: u.firstName,
            lastName: u.lastName,
            email: u.email,
            mobilePhone: u.mobilePhone,
          })
          setOpen(true)
        })
        .catch(errorFormBehaviour)
  }

  return (
    <>
      <Button size="small" onClick={showDrawer}>
        {t('edit_short')}
      </Button>
      {open && (
        <Drawer
          title={t('edit')}
          onClose={onClose}
          visible={open}
          closable
          width={breakpoint.xs ? '100%' : '40%'}
        >
          <>
            <ModifyProfile
              profile={user}
              onSave={(): void => {
                setOpen(false)
                refreshUsers()
              }}
            />
          </>
        </Drawer>
      )}
    </>
  )
}
