import { Col, Divider, Form, Row, Checkbox, Radio } from 'antd'
import React, { FC } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../../../../components/Form/OptionWithCount.model'
import TextAreaItem from '../../../../components/Form/TextAreaItem/TextAreaItem'
import StartupMarketsEnum, {
  StartupMarketsHelper,
} from '../../../../domain/startup-markets.enum'
import StartupMaturitiesEnum, {
  StartupMaturitiesHelper,
} from '../../../../domain/startup-maturities.enum'
import { StartupModel } from '../../../../domain/startup.model'
import FormatHelper from '../../../../utils/FormatHelper'
import styles from './StartupForm.module.scss'

interface Props {
  prefix?: keyof StartupModel
}

const maturitiesConfig = (
  t: TFunction,
): OptionWithCountModel<StartupMaturitiesEnum>[] =>
  OptionWithCountHelper.buildFrom(
    StartupMaturitiesHelper.allMaturities(),
    t,
    StartupMaturitiesHelper.format,
  )

const marketsConfig = (
  t: TFunction,
): OptionWithCountModel<StartupMarketsEnum>[] =>
  OptionWithCountHelper.buildFrom(StartupMarketsHelper.allMarkets(), t)

const OfferStartupForm: FC<Props> = ({ prefix }: Props) => {
  const { t: t_startup } = useTranslation('startup')
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')
  const { t: t_input } = useTranslation('input')

  return (
    <div className={'startup-form'}>
      <Form.Item
        label={
          <>
            {t_startup('input.typeOfMarket.label')}
            <small>{t_common('multipleChoiceAllow')}</small>
          </>
        }
        name={FormatHelper.formItemNameFormatter(prefix, 'market')}
        rules={[{ required: true, message: t_common('requiredField') }]}
      >
        <Checkbox.Group options={marketsConfig(t_domain)} />
      </Form.Item>

      <Form.Item
        label={t_startup('input.developmentMaturity.label')}
        name={FormatHelper.formItemNameFormatter(prefix, 'maturity')}
        rules={[{ required: true, message: t_common('requiredField') }]}
      >
        <Radio.Group options={maturitiesConfig(t_domain)} />
      </Form.Item>
      <Divider className={styles.formDivider} />

      <h1>
        {t_startup('input.shortDescription.label')}
        <small>
          {' '}
          <i>
            (70 {t_input('characters')} {t_common('maximum')})
          </i>
        </small>
      </h1>
      <p className={styles.tip}>{t_startup('input.shortDescription.tip')}</p>
      <Row gutter={15}>
        <Col xs={24} md={12}>
          <TextAreaItem
            maxLength={70}
            rows={3}
            name={FormatHelper.formItemNameFormatter(
              prefix,
              'shortDescriptionFr',
            )}
            language={'fr'}
          />
        </Col>
        <Col xs={24} md={12}>
          <TextAreaItem
            maxLength={70}
            rows={3}
            name={FormatHelper.formItemNameFormatter(
              prefix,
              'shortDescriptionEn',
            )}
          />
        </Col>
      </Row>

      <h1>
        {t_startup('input.marketOpportunity.label')}
        <small>
          {' '}
          <i>
            (450 {t_input('characters')} {t_common('maximum')})
          </i>
        </small>
      </h1>
      <p className={styles.tip}>{t_startup('input.marketOpportunity.tip')}</p>
      <Row gutter={15}>
        <Col xs={24} md={12}>
          <TextAreaItem
            maxLength={450}
            name={FormatHelper.formItemNameFormatter(
              prefix,
              'marketOpportunityFr',
            )}
            language={'fr'}
          />
        </Col>
        <Col xs={24} md={12}>
          <TextAreaItem
            maxLength={450}
            name={FormatHelper.formItemNameFormatter(
              prefix,
              'marketOpportunityEn',
            )}
          />
        </Col>
      </Row>

      <h1>
        {t_startup('input.innovativeSolution.label')}
        <small>
          {' '}
          <i>
            (650 {t_input('characters')} {t_common('maximum')})
          </i>
        </small>
      </h1>
      <div className={styles.tip}>
        {t_startup('input.innovativeSolution.tip.precise')}
        <ul>
          <li>{t_startup('input.innovativeSolution.tip.usedTechnologies')}</li>
          <li>
            {t_startup(
              'input.innovativeSolution.tip.innovationsIntroducedAndHowTheyWork',
            )}
          </li>
          <li>
            {t_startup('input.innovativeSolution.tip.benefitsAndAdvantage')}
          </li>
        </ul>
      </div>
      <Row gutter={15}>
        <Col xs={24} md={12}>
          <TextAreaItem
            maxLength={650}
            name={FormatHelper.formItemNameFormatter(
              prefix,
              'innovativeSolutionFr',
            )}
            language={'fr'}
          />
        </Col>
        <Col xs={24} md={12}>
          <TextAreaItem
            maxLength={650}
            name={FormatHelper.formItemNameFormatter(
              prefix,
              'innovativeSolutionEn',
            )}
            language={'en'}
          />
        </Col>
      </Row>

      <h1>
        {t_startup('input.economicModel.label')}
        <small>
          {' '}
          <i>
            (750 {t_input('characters')} {t_common('maximum')})
          </i>
        </small>
      </h1>
      <div className={styles.tip}>
        {t_startup('input.economicModel.tip.youCanInsistOn')}
        <ul>
          <li key={'typeOfCustomers'}>
            {t_startup('input.economicModel.tip.typeOfCustomers')}
          </li>
          <li key={'prospectionAction'}>
            {t_startup('input.economicModel.tip.prospectionAction')}
          </li>
          <li key={'distributionSystem'}>
            {t_startup('input.economicModel.tip.distributionSystem')}
          </li>
          <li key={'monetizationOfYourSolution'}>
            {t_startup('input.economicModel.tip.monetizationOfYourSolution')}
          </li>
        </ul>
        {t_startup('input.economicModel.tip.lastTurnoverAndMMR')}
      </div>
      <Row gutter={15}>
        <Col xs={24} md={12}>
          <TextAreaItem
            maxLength={750}
            name={FormatHelper.formItemNameFormatter(prefix, 'economicModelFr')}
            language={'fr'}
          />
        </Col>
        <Col xs={24} md={12}>
          <TextAreaItem
            maxLength={750}
            name={FormatHelper.formItemNameFormatter(prefix, 'economicModelEn')}
            language={'en'}
          />
        </Col>
      </Row>
      <Divider className={styles.formDivider} />
    </div>
  )
}

export default OfferStartupForm
