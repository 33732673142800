import { Col, Divider, Row, Skeleton } from 'antd'
import React, { FC } from 'react'
import Section from '../../../components/Layout/Section'
import { StartupModel } from '../../../domain/startup.model'
import ShowBaseStartup from './Base/ShowBaseStartup'
import ShowOfferStartup from './Offer/ShowOfferStartup'
import ShowRaiseStartup from './Raise/ShowRaiseStartup'
import { ShowStartupActivity } from './ShowStartupActivity'
import { useTranslation } from 'react-i18next'
import usePermission from '../../../services/usePermission.hook'
import { Roles } from '../../../utils/roles.enum'
import { ShowStartupPicturesModal } from './ShowStartupPicturesModal'
import styles from './ShowStartupActivity.module.scss'

interface Props {
  startup: StartupModel | undefined
  onUpdate?: () => void
}

const ShowStartup: FC<Props> = ({ startup, onUpdate = () => {} }: Props) => {
  const { t } = useTranslation('startup')
  const isInno = usePermission(Roles.INNO)

  const showPictures = (ids: string[]) =>
    ids != null && ids.length > 0
      ? ids.map((id, index) => (
          <Col key={id} xs={16} sm={12} lg={8} xl={6}>
              <Row>
                <ShowStartupPicturesModal current={index} pictureIds={ids} />
              </Row>
          </Col>
        ))
      : null

  if (startup == null) {
    return <Skeleton loading={true} />
  }
  return (
    <>
      <ShowBaseStartup startup={startup} />
      <br />
      <Section>
        {isInno && <ShowStartupActivity startUpId={startup.id} />}
        <ShowRaiseStartup
          raise={startup.raise}
          startup={startup}
          onUpdate={onUpdate}
        />
        <Divider className={styles.divider} />
        <ShowOfferStartup
          offer={startup.offer}
          team={startup.team}
          informations={startup.informations}
        />
        <Divider className={styles.divider} />
        <h3>{t('input.pictures.label')}</h3>
        <Row gutter={[16, 16]}>{showPictures(startup.pictureIds)}</Row>
      </Section>
    </>
  )
}

export default ShowStartup
