import { TFunction } from 'react-i18next'

enum ClientSearchTypeEnum {
  ACTIVE = 'ACTIVE',
  OPPORTUNITY = 'OPPORTUNITY',
}

export class ClientSearchTypeHelper {
  static format = (search: ClientSearchTypeEnum, t: TFunction): string =>
    t(`clientSearchType.${search}`) || search

  static allSearchType = (): ClientSearchTypeEnum[] =>
    Object.values(ClientSearchTypeEnum)
}

export default ClientSearchTypeEnum
