import { Form, Input, Select, Skeleton } from 'antd'
import React, { FC, useContext } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import Section from '../../components/Layout/Section'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import { ClientModel } from '../../domain/client/client.model'
import CountryEnum from '../../domain/country.enum'
import { EditClientReq } from '../../services/Api'
import usePermission from '../../services/usePermission.hook'
import { Roles } from '../../utils/roles.enum'
import ClientForm from './ClientForm'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../../components/Form/OptionWithCount.model'
import EntityEnum, { EntityHelper } from '../../domain/entity.enum'

interface Props {
  client: ClientModel | undefined
  onSave?: () => void
  title?: string
}

const entitiesConfig = (t: TFunction): OptionWithCountModel<EntityEnum>[] =>
  OptionWithCountHelper.buildFrom(EntityHelper.allEntities(), t)

const ModifyClient: FC<Props> = ({
  client,
  title,
  onSave = () => {},
}: Props) => {
  const { errorFormBehaviour, successFormBehaviour, saveClient } =
    useContext(ApiContext)

  const isInno = usePermission(Roles.INNO)

  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')

  const save = (clientInformations: EditClientReq) => {
    if (client != null) {
      saveClient(client.id, clientInformations)
        .then(() => successFormBehaviour('Critères sauvegardés'))
        .then(onSave)
        .catch(errorFormBehaviour)
    }
  }

  if (client == null) {
    return (
      <Section>
        <Skeleton loading={true} />
      </Section>
    )
  }
  return (
    <Section>
      {title ? <h2 className="mb-60">{title}</h2> : null}
      <Form
        layout="vertical"
        onFinish={save}
        requiredMark={true}
        initialValues={{ ...client, regions: client.regions || [] }}
      >
        {isInno ? (
          <>
            <Form.Item label={t_common('banker')} name={'banquier'}>
              <Input size="large" />
            </Form.Item>
            <Form.Item label={t_common('entity')} name={'entity'}>
              <Select options={entitiesConfig(t_domain)} size="large" />
            </Form.Item>
          </>
        ) : null}
        <ClientForm
          initialNeeds={client.need}
          seeRegions={(client.countries || []).includes(CountryEnum.FRANCE)}
        />
        <MainButton htmlType={'submit'}>{t_common('register')}</MainButton>
      </Form>
    </Section>
  )
}

export default ModifyClient
