import { useContext, useEffect, useState } from 'react'
import UserContext from '../context/UserContext'
import { AccessType, AccessValue, hasPermissions } from './hasPermission'

function usePermission(
  accessValue: AccessValue,
  accessType: AccessType = 'one',
): boolean | undefined {
  const [access, setAccess] = useState<boolean>()
  const { user } = useContext(UserContext)

  useEffect(() => {
    setAccess(hasPermissions(user.roles, accessValue, accessType))
  }, [user.roles, accessValue, accessType])

  return access
}

export default usePermission
