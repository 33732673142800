import { Space } from 'antd'
import React, { FC } from 'react'
import PrimaryTag from './PrimaryTag'

interface Props<T> {
  className?: string
  values?: T[]
  format?: (value: T) => string
}

const defaultFormat = <T,>(t: T) => t as unknown as string

const PrimaryTagList: FC<Props<any>> = <T,>({
  className,
  values = [],
  format = defaultFormat,
}: Props<T>) => {
  if (!values || values.length === 0) {
    return null
  }

  // size horizontal is 4 because ant Tag already has 8px margin-left...
  return (
    <Space wrap={true} size={[4, 12]} className={className}>
      {values.map((s: T, index: number) => (
        <PrimaryTag color={'#5992C3'} key={index}>
          {format(s)}
        </PrimaryTag>
      ))}
    </Space>
  )
}

export default PrimaryTagList
