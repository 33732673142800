import { Col, Modal, Row } from 'antd'
import React, { FC, useState } from 'react'
import { getApiHost } from '../../../conf'
import styles from './ShowStartupPictures.module.scss'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

interface Props {
  current: number
  pictureIds: string[]
}

export const ShowStartupPicturesModal: FC<Props> = ({
  current,
  pictureIds,
}: Props) => {
  const [openModal, setOpenModal] = useState<boolean>(false)
  const [pictureIndex, setPictureIndex] = useState<number>(0)

  const previous = (): void => {
    const newIndex =
      pictureIndex === 0 ? pictureIds.length - 1 : pictureIndex - 1
    setPictureIndex(newIndex)
  }
  const next = (): void => {
    const newIndex =
      pictureIndex === pictureIds.length - 1 ? 0 : pictureIndex + 1
    setPictureIndex(newIndex)
  }

  return (
    <>
      <Col span={20} className={styles.imageContainer}>
        <div className={styles.avatarDisplay}>
          <img
            alt={''}
            onClick={(): void => {
              setPictureIndex(current)
              setOpenModal(true)
            }}
            className={styles.smallImage}
            src={`${getApiHost()}/asset-files/${pictureIds[current]}`}
          />
        </div>
      </Col>
      {openModal && (
        <Modal
          visible={openModal}
          closable
          footer={null}
          width={'90%'}
          centered
          bodyStyle={{ padding: 'unset', height: '90vh' }}
          onCancel={() => setOpenModal(false)}
        >
          <Row className={styles.h100}>
            <Col
              span={1}
              className={[styles.h100, styles.centeredContent].join(' ')}
            >
              <LeftOutlined
                onClick={previous}
                className={[styles.margins, styles.buttonIcon].join(' ')}
              />
            </Col>
            <Col
              span={22}
              className={[styles.h100, styles.centeredContent].join(' ')}
            >
              <img
                alt={''}
                className={[styles.margins, styles.bigImage].join(' ')}
                src={`${getApiHost()}/asset-files/${pictureIds[pictureIndex]}`}
              />
            </Col>
            <Col
              span={1}
              className={[styles.h100, styles.centeredContent].join(' ')}
            >
              <RightOutlined
                onClick={next}
                className={[styles.margins, styles.buttonIcon].join(' ')}
              />
            </Col>
          </Row>
        </Modal>
      )}
    </>
  )
}
