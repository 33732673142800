import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import StartupStatusEnum, {
  StartupStatusHelper,
} from '../../domain/startup-status.enum'
import PrimaryTag from '../Tag/PrimaryTag'

interface Props {
  status: StartupStatusEnum
}

const BadgeStartupStatus: FC<Props> = ({ status }: Props) => {
  const { t } = useTranslation('domain')
  return (
    <PrimaryTag color={StartupStatusHelper.color(status)}>
      {StartupStatusHelper.format(status, t)}
    </PrimaryTag>
  )
}

export default BadgeStartupStatus
