import { TFunction } from 'react-i18next'

enum SectorEnum {
  AD_TECH = 'AD_TECH',
  AGRITECH = 'AGRITECH',
  BIOTECH = 'BIOTECH',
  BLUE_TECH = 'BLUE_TECH',
  CHARITY = 'CHARITY',
  CLEAN_TECH = 'CLEAN_TECH',
  CONSUMER_GOODS = 'CONSUMER_GOODS',
  CYBERSECURITY = 'CYBERSECURITY',
  EDTECH = 'EDTECH',
  ENERGY = 'ENERGY',
  ENTERTAINMENT = 'ENTERTAINMENT',
  FASHION_TECH = 'FASHION_TECH',
  FINTECH = 'FINTECH',
  FOOD_TECH = 'FOOD_TECH',
  FUTURE_OF_WORK = 'FUTURE_OF_WORK',
  GAMING = 'GAMING',
  GOVTECH = 'GOVTECH',
  GREEN_TECH = 'GREEN_TECH',
  HEALTH_TECH = 'HEALTH_TECH',
  HR_TECH = 'HR_TECH',
  INDUSTRY = 'INDUSTRY',
  LOGISTICS = 'LOGISTICS',
  MARKETING = 'MARKETING',
  MEDIAS = 'MEDIAS',
  MED_TECH = 'MED_TECH',
  MOBILITY = 'MOBILITY',
  PROP_TECH = 'PROP_TECH',
  REGTECH = 'REGTECH',
  RETAIL = 'RETAIL',
  ROBOTICS = 'ROBOTICS',
  SMART_CITY = 'SMART_CITY',
  SPACE_TECH = 'SPACE_TECH',
  SPORTS_TECH = 'SPORTS_TECH',
  TELCO = 'TELCO',
  TOURISM = 'TOURISM',
}

export class SectorHelper {
  static allSectors = (): SectorEnum[] => Object.values(SectorEnum)

  static format = (sector: SectorEnum, t: TFunction): string =>
    t(`sectors.${sector}`) || sector
}

export default SectorEnum
