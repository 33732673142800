import React, { FC, useContext, useState } from 'react'
import { Button, Col, Drawer, Form, Grid, Row } from 'antd'
import MainButton from '../../components/MainButton/MainButton'
import { useTranslation } from 'react-i18next'
import TextAreaItem from '../../components/Form/TextAreaItem/TextAreaItem'
import ApiContext from '../../context/ApiContext'
import { ShortNewsModel } from '../../domain/news/shortNews.model'
import frenchFlag from '../../styles/svg/french_flag.svg'
import englishFlag from '../../styles/svg/english_flag.svg'
import { ReactComponent as EditShortNewsIcon } from '../../styles/svg/editShortNewsIcon.svg'
import styles from './News.module.scss'

export const ShortNewsEditor: FC = (): JSX.Element => {
  const { getShortNews, postShortNews, errorFormBehaviour } =
    useContext(ApiContext)
  const { t } = useTranslation('news')
  const breakpoint = Grid.useBreakpoint()

  const [open, setOpen] = useState<boolean>(false)
  const [shortNews, setShortNews] = useState<ShortNewsModel>()
  const [titleFr, setTitleFr] = useState<string>('')
  const [titleEn, setTitleEn] = useState<string>('')
  const [textFr, setTextFr] = useState<string>('')
  const [textEn, setTextEn] = useState<string>('')

  const hyperTextHelp: string = `<a href="mywebsite.com">${t('click_here')}</a>`

  const onClose = () => {
    setOpen(false)
  }

  const showDrawer = () => {
    getShortNews()
      .then((res): void => {
        setShortNews(res)
        setTitleFr(res.titleFr)
        setTitleEn(res.titleEn)
        setTextFr(res.textFr)
        setTextEn(res.textEn)
        setOpen(true)
      })
      .catch(errorFormBehaviour)
  }

  const onSave = (data: ShortNewsModel): void => {
    postShortNews(data).then(onClose).catch(errorFormBehaviour)
  }

  return (
    <>
      <MainButton onClick={showDrawer} className={styles.trashIconContainer}>
        <EditShortNewsIcon className={styles.icon} />
        <p>{t('short_news.edit')}</p>
      </MainButton>
      {open && (
        <Drawer
          title={t('short_news.edit')}
          onClose={onClose}
          visible={open}
          closable
          width={breakpoint.xs ? '100%' : '60%'}
        >
          <Row justify={'space-between'}>
            <Col sm={24} md={12}>
              <p>
                {t('tip_insert_link')}
                <br />
                {hyperTextHelp}
                <br />
                {t('tip_preview_link')}
                <a href="mywebsite.com">{t('click_here')}</a>
              </p>
              <Form
                layout="vertical"
                onFinish={onSave}
                initialValues={shortNews}
              >
                <TextAreaItem
                  maxLength={100}
                  name={'titleFr'}
                  rows={1}
                  setInputValue={setTitleFr}
                  title={
                    <>
                      <img
                        src={frenchFlag}
                        alt={''}
                        className={styles.flagIcon}
                      />
                      {t('title')}
                    </>
                  }
                />
                <TextAreaItem
                  maxLength={1000}
                  name={'textFr'}
                  language={'fr'}
                  size={'large'}
                  rows={6}
                  setInputValue={setTextFr}
                />
                <TextAreaItem
                  maxLength={100}
                  name={'titleEn'}
                  rows={1}
                  setInputValue={setTitleEn}
                  title={
                    <>
                      <img
                        src={englishFlag}
                        alt={''}
                        className={styles.flagIcon}
                      />
                      {t('title')}
                    </>
                  }
                />
                <TextAreaItem
                  maxLength={1000}
                  name={'textEn'}
                  language={'en'}
                  size={'large'}
                  rows={6}
                  setInputValue={setTextEn}
                />
                <Row justify={'space-between'}>
                  <MainButton htmlType={'submit'}>{t('save')}</MainButton>
                  <Button onClick={onClose}>{t('close')}</Button>
                </Row>
              </Form>
            </Col>
            <Col sm={24} md={11} className={`${styles.preview}`}>
              <h2>{t('short_news.preview')}</h2>
              <>
                <img src={frenchFlag} alt={''} className={styles.flagIcon} />
                FR
              </>
              <p className={styles.shortNewsTitle}>{titleFr}</p>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: textFr,
                }}
              />
              <br />
              <br />
              <>
                <img src={englishFlag} alt={''} className={styles.flagIcon} />
                EN
              </>
              <p className={styles.shortNewsTitle}>{titleEn}</p>
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: textEn,
                }}
              />
            </Col>
          </Row>
        </Drawer>
      )}
    </>
  )
}
