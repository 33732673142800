import React, { FC, useContext, useEffect, useState } from 'react'
import Section from '../../components/Layout/Section'
import { useTranslation } from 'react-i18next'
import { MostVisitedStartups } from './MostVisitedStartups'
import { MostConnectedClients } from './MostConnectedClients'
import ApiContext from '../../context/ApiContext'
import { ConnectionRequests } from './ConnectionRequests'
import { DashboardStatisticsModel } from '../../domain/admin/dashboard.model'
import { MostVisitedEcosystems } from './MostVisitedEcosystems'
import { MostVisitedBusinessSectors } from './MostVisitedBusinessSectors'
import { Col, Grid, Row, Space } from 'antd'
import styles from './Dashboard.module.scss'
import { ShortNewsEditor } from '../News/ShortNewsEditor'
import { StartupNewsEditor } from '../News/StartupNewsEditor'
import { ClientConnectionsChart } from './ClientConnectionsChart'

export const Dashboard: FC = () => {
  const { getDashboardStatistics, errorFormBehaviour } = useContext(ApiContext)
  const { t: t_dashboard } = useTranslation('dashboard')
  const [dashboardStatistics, setDashboardStatistics] =
    useState<DashboardStatisticsModel>()
  const breakpoint = Grid.useBreakpoint()

  useEffect((): void => {
    getDashboardStatistics()
      .then((res: DashboardStatisticsModel) => {
        setDashboardStatistics(res)
      })
      .catch(errorFormBehaviour)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Section theme={'dark'} className={styles.noPaddingBottom}>
        <h2>{t_dashboard('admin_dashboard')}</h2>
        <Row className={styles.whiteContainer} justify={'space-between'}>
          <Col>
            <h3>{t_dashboard('homepage_content')}</h3>
          </Col>
          <Col>
            <Space direction={breakpoint.xs ? 'vertical' : 'horizontal'}>
              <ShortNewsEditor />
              <StartupNewsEditor />
            </Space>
          </Col>
        </Row>
        <ConnectionRequests
          connectionRequests={dashboardStatistics?.connectionRequestStatistics}
        />
      </Section>
      <Section theme={'mixed'} className={styles.noPaddingBottom}>
        <ClientConnectionsChart
          clientsConnections={dashboardStatistics?.clientsConnectionsStatistics}
        />
      </Section>
      <Section>
        <Row>
          <MostVisitedBusinessSectors
            mostVisitedBusinessSectors={dashboardStatistics?.mostViewedSectors}
            sectorsDistribution={dashboardStatistics?.sectorsDistribution}
          />
          <MostVisitedEcosystems
            mostVisitedEcosystems={dashboardStatistics?.mostViewedEcosystems}
            ecosystemsDistribution={dashboardStatistics?.ecosystemsDistribution}
          />
        </Row>
        <Row>
          <MostVisitedStartups
            mostVisitedStartups={dashboardStatistics?.startupVisitedStatistics}
          />
          <MostConnectedClients
            mostConnectedClients={
              dashboardStatistics?.clientsWithMostConnectionsStatistics
            }
          />
        </Row>
      </Section>
    </>
  )
}
