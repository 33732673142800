import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Bar } from 'react-chartjs-2'
import FormatHelper from '../../utils/FormatHelper'
import styles from './Dashboard.module.scss'
import moment from 'moment/moment'
import { Col, DatePicker, Row } from 'antd'
import Loading from '../../components/Loading'
import { ChartData } from './DoughnutChart'

interface Props {
  clientsConnections?: Record<string, number>
}

export const ClientConnectionsChart: FC<Props> = ({
  clientsConnections,
}: Props) => {
  const { t: t_dashboard } = useTranslation('dashboard')
  const { RangePicker } = DatePicker

  const today = Date.now()
  const todayMinus30Days = today - 24 * 60 * 60 * 1000 * 30

  const [data, setData] = useState<ChartData>({ datasets: [], labels: [] })
  const [startDate, setStartDate] = useState<Date>(new Date(todayMinus30Days))
  const [endDate, setEndDate] = useState<Date>(new Date())

  const options = {
    plugins: { labels: false },
    legend: { display: false },
    scales: {
      xAxes: [
        {
          gridLines: { display: false },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            labelString: t_dashboard('number_of_connections'),
            display: t_dashboard('number_of_connections'),
          },
          gridLines: { borderDash: [8, 4] },
          ticks: {
            suggestedMin: 0,
            stepSize: 1,
          },
        },
      ],
    },
  }

  useEffect((): void => {
    let labels: string[] = clientsConnections
      ? Object.keys(clientsConnections).filter(
          (date) =>
            new Date(date).valueOf() > startDate.valueOf() &&
            new Date(date).valueOf() < endDate.valueOf(),
        )
      : []

    let dataset: number[] = []

    clientsConnections &&
      labels.forEach((label: string): void => {
        let value = clientsConnections[label]
        dataset.push(value)
      })

    let dataValue = {
      datasets: [
        {
          data: dataset,
        },
      ],
      labels: labels.map((date) => FormatHelper.formatStringToDate(date, 'fr')),
    }
    setData(dataValue)
  }, [clientsConnections, startDate, endDate])

  const onChange = (value: any): void => {
    const startToDate = value !== null && value[0] ? value[0].toDate() : null
    const endToDate = value !== null && value[1] ? value[1].toDate() : null
    setStartDate(startToDate)
    setEndDate(endToDate)
  }

  return (
    <Row className={styles.whiteContainer}>
      <Col sm={24} md={12}>
        <h3>{t_dashboard('clients_connections')}</h3>
      </Col>
      <Col sm={24} md={12}>
        <RangePicker
          className={styles.datePicker}
          picker={'date'}
          defaultValue={[
            moment(
              FormatHelper.formatDate(todayMinus30Days, 'fr'),
              'DD/MM/YYYY',
            ),
            moment(FormatHelper.formatDate(today, 'fr'), 'DD/MM/YYYY'),
          ]}
          onChange={(value) => onChange(value)}
          format={'DD/MM/YYYY'}
          allowClear={false}
        />
      </Col>
      <Col
        span={24}
        // style={{ maxHeight: '200px' }}
      >
        {clientsConnections ? (
          <Bar data={data} options={options} />
        ) : (
          <Loading />
        )}
      </Col>
    </Row>
  )
}
