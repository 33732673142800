import { Form } from 'antd'
import React, { FC, useContext, useState } from 'react'
import ApiContext from '../../../context/ApiContext'
import { StartupModel } from '../../../domain/startup.model'
import { StartupInformationsReq } from '../../../services/Api'
import FormFooter from '../../../components/Form/FormFooter'
import InfosStartupForm from '../Update/Common/InfosStartupForm'
import { useTranslation } from 'react-i18next'
import RoadMapStartupForm from '../Update/Common/RoadMapStartupForm'
import moment from 'moment/moment'
import { FilePreview } from '../../../domain/filePreview.model'
import FormHelper from '../../../utils/FormHelper'

/**
 *
 * @param onSave method to call when update success
 * @param title of the bandeau
 * @returns {*}
 * @constructor
 */
interface Props {
  onSave: () => void
  onCancel: () => void
  startup: StartupModel
}

const InitInfosStartup: FC<Props> = ({ onSave, onCancel, startup }: Props) => {
  const {
    errorFormBehaviour,
    updateStartupInformations,
    uploadStartupLogo,
    deleteStartupLogo,
  } = useContext(ApiContext)
  const [form] = Form.useForm()

  const updateAllFiles = async (promises: (() => Promise<any>)[]) => {
    let index = 0
    const requestUpdateFile = (): any => {
      if (index >= promises.length) {
        return
      }
      return promises[index]().then(() => {
        index++
        return requestUpdateFile()
      })
    }
    await requestUpdateFile()
  }

  const save = async (informations: StartupInformationsReq) => {
    if (await FormHelper.validateForm(form)) {
      updateStartupInformations(startup.id, informations)
      .then(() => {
        let promises: (() => Promise<any>)[] = []
        if (logo !== undefined) {
          if (logo.id !== startup.informations.logoId) {
            promises.push(() =>
              uploadStartupLogo(startup.id, {
                id: logo.id,
                file: logo.file,
              }),
            )
            if (startup.informations.logoId) {
              promises.push(() =>
                deleteStartupLogo(startup.id, startup.informations.logoId),
              )
            }
          }

          uploadStartupLogo(startup.id, {
            id: logo.id,
            file: logo.file,
          })
            .then(onSave)
            .catch(errorFormBehaviour)
        } else {
          onSave()
        }

        if (promises.length > 0) {
          return updateAllFiles(promises).then(onSave).catch(errorFormBehaviour)
        }
      })
      .catch(errorFormBehaviour)
    }
  }

  const onSaveDraftMode = (errorInfo: any) => {
    save(errorInfo.values)
  }

  const [logo, setLogo] = useState<FilePreview>()
  const { t } = useTranslation('startup')

  const formatInitialRoadMap = (): any[] => {
    let keyDates: any[] = []
    if (
      startup?.informations !== null &&
      startup.informations.roadMap &&
      startup.informations.roadMap.length > 0
    ) {
      startup.informations.roadMap.forEach((keyDate) => {
        keyDates.push({
          date: keyDate.date == null ? null : moment(keyDate.date),
          descriptionFr: keyDate.descriptionFr,
          descriptionEn: keyDate.descriptionEn,
        })
      })
    } else {
      keyDates = [{}]
    }
    return keyDates
  }

  return startup == null ? (
    <></>
  ) : (
    <Form
      layout="vertical"
      onFinish={save}
      initialValues={{
        ...startup.informations,
        roadMap: formatInitialRoadMap(),
      }}
      form={form}
      onFinishFailed={onSaveDraftMode}
    >
      <h2 className="mb-60">{t('identity')}</h2>
      <InfosStartupForm
        startup={startup}
        updateLogoToAdd={setLogo}
        form={form}
      />
      <RoadMapStartupForm />
      <FormFooter
        onCancel={onCancel}
        cancelLabel={t('back')}
        saveLabel={t('continue')}
      />
    </Form>
  )
}

export default InitInfosStartup
