import { TFunction } from 'react-i18next'
import StartupNeedsEnum from '../startup-needs.enum'

enum ClientNeedsEnum {
  INVESTMENT = 'INVESTMENT',
  BUSINESS_RELATIONSHIP = 'BUSINESS_RELATIONSHIP',
}

export class ClientNeedHelper {
  static format = (needs: ClientNeedsEnum, t: TFunction): string =>
    t(`clientNeed.${needs}`) || needs

  static allNeeds = (): ClientNeedsEnum[] => Object.values(ClientNeedsEnum)

  static toStartupNeed = (need: ClientNeedsEnum): StartupNeedsEnum => {
    switch (need) {
      case ClientNeedsEnum.INVESTMENT:
        return StartupNeedsEnum.FUND_RISING
      case ClientNeedsEnum.BUSINESS_RELATIONSHIP:
        return StartupNeedsEnum.BUSINESS_RELATIONSHIP
    }
  }
}

export default ClientNeedsEnum
