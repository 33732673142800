import React, { FC, useEffect } from 'react'
import Section from '../../components/Layout/Section'
import { useTranslation } from 'react-i18next'
import styles from './Cgu.module.scss'
import SelectLanguage from '../../components/SelectLanguage'
import { ReactComponent as LogoStartupByIndo } from '../../styles/svg/LogoStartupConnections_byIndosuez.svg'
import { Layout } from 'antd'
import { Header } from 'antd/lib/layout/layout'

export const LegalNotices: FC = () => {
  const { t } = useTranslation('legal_notices')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
      <Header
        style={{
          marginBottom: '15px',
          position: 'fixed',
          top: 0,
          zIndex: 2,
          width: '100%',
          height: '80px',
          justifyContent: 'space-between',
          display: 'flex',
          boxShadow:
            '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        }}
      >
        <>
          <LogoStartupByIndo className={styles.smallLogo} />
          <SelectLanguage className={styles.select} />
        </>
      </Header>
      <Section
        className={`text-align-justify ${styles.container} ${styles.cguDetails}`}
      >
        <h1>{t('title')}</h1>
        <p>
          {t('first')}
          <a href="https://startupconnections.ca-indosuez.com">
            https://startupconnections.ca-indosuez.com
          </a>
          {t('first_end')}
        </p>
        <p dangerouslySetInnerHTML={{ __html: t('second') }} />
        <p dangerouslySetInnerHTML={{ __html: t('third') }} />
        <p dangerouslySetInnerHTML={{ __html: t('fourth') }} />
        <p>
          {t('fifth')}
          <a
            href={'mailto:communication@ca-indosuez.com'}
            target="_blank"
            rel="noopener noreferrer"
          >
            communication@ca-indosuez.com
          </a>
          {t('fifth_end')}
        </p>
      </Section>
    </Layout>
  )
}
