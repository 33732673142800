import { useContext, useEffect, useState } from 'react'
import RangeValueModel from '../../../components/Range/RangeValue.model'
import StartupContext from '../../../context/StartupContext'
import { StartupModel } from '../../../domain/startup.model'
import { Filter } from './StartupsFilter.model'

const arraysMatchOne = <T>(array1: T[], array2: T[]): boolean =>
  array1.some((value: T): boolean => array2.includes(value))

const rangesContains = (ranges: RangeValueModel[], value: number): boolean =>
  ranges.some((range: RangeValueModel): boolean => range.contains(value))

type FilterFn = (startup: StartupModel) => boolean

const filterCascaderInclude = (
  data: string[][],
  getter: (s: StartupModel) => { parent: string; child: string },
) => {
  if (data.length <= 0) return () => true
  return (s: StartupModel) => {
    const matchCascader = data.map((d) => d.join(' - '))
    const parent = getter(s).parent
    const child = getter(s).child
    return (
      matchCascader.includes(`${parent} - ${child}`) ||
      matchCascader.includes(parent)
    )
  }
}
const filterInclude = <T>(
  data: T[],
  getter: (s: StartupModel) => T,
): FilterFn =>
  data?.length > 0
    ? (startup: StartupModel): boolean => data.includes(getter(startup))
    : () => true
const filterIntersect = <T>(
  data: T[],
  getter: (s: StartupModel) => T[],
): FilterFn =>
  data?.length > 0
    ? (startup: StartupModel): boolean => arraysMatchOne(data, getter(startup))
    : () => true
const filterRanges = (
  data: RangeValueModel[],
  getter: (s: StartupModel) => number,
): FilterFn =>
  data?.length > 0
    ? (startup: StartupModel): boolean => rangesContains(data, getter(startup))
    : () => true
const filterRange = (
  data: RangeValueModel | undefined,
  getter: (s: StartupModel) => number,
): FilterFn =>
  data == null
    ? () => true
    : (startup: StartupModel): boolean => data.contains(getter(startup))
const filterTrue = (
  data: boolean,
  getter: (s: StartupModel) => boolean | undefined,
): FilterFn =>
  data
    ? (startup: StartupModel): boolean => getter(startup) === true
    : () => true

function useStartupsFilter(
  allStartups: StartupModel[],
  filter: Filter,
): StartupModel[] {
  const [startups, setStartups] = useState<StartupModel[]>(allStartups)
  const { getEcosystemName } = useContext(StartupContext)

  useEffect(() => {
    let result = [...allStartups]
      .filter(filterInclude(filter.statuses, (s) => s.status))
      .filter(filterInclude(filter.sectors, (s) => s.informations.sector))
      .filter(filterInclude(filter.countries, (s) => s.informations.country))
      .filter(filterInclude(filter.regions, (s) => s.informations.region))
      .filter(
        // @ts-ignore --> CascaderOption change de format en string[][] lors de la manipulation
        filterCascaderInclude(filter.ecosystems, (s) => ({
          parent: getEcosystemName(s),
          child: s.informations.subEcosystem,
        })),
      )
      .filter(filterInclude(filter.maturities, (s) => s.offer.maturity))
      .filter(filterIntersect(filter.fundRaiseNeeds, (s) => s.raise.need))
      .filter(filterInclude(filter.fundRaiseStatus, (s) => s.raise.status))
      .filter(filterRanges(filter.fundRaiseValues, (s) => s.raise.targetMoney))
      .filter(filterRange(filter.entryTicket, (s) => s.raise.minInvest))
      .filter(
        filterTrue(filter.businessWoman, (s) => s.informations.businessWoman),
      )
      .filter(
        filterTrue(
          filter.environmentalImpact,
          (s) => s.informations.environmentalImpact,
        ),
      )
    setStartups(result)
  }, [allStartups, filter, getEcosystemName])

  return startups
}

export default useStartupsFilter
