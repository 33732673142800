import { Col, Row, Tooltip } from 'antd'
import { TooltipPlacement } from 'antd/lib/tooltip'
import React, { FC, MouseEventHandler, ReactNode } from 'react'
import useBreakpointValue from '../../../utils/useBreakpointValue.hook'

interface Props {
  tip?: ReactNode
  children: TooltipChildType
}

// Please Ensure the children has those props set, or else the tooltip will not be visible
export interface TooltipChildType {
  onMouseEnter?: MouseEventHandler<HTMLInputElement>
  onMouseLeave?: MouseEventHandler<HTMLInputElement>
  onFocus?: MouseEventHandler<HTMLInputElement>
  onClick?: MouseEventHandler<HTMLInputElement>
}

const InputWithTip: FC<Props> = ({ tip, children }) => {
  const tooltipPosition = useBreakpointValue<TooltipPlacement>({
    xs: 'bottomLeft',
    xl: 'bottomLeft',
    xxl: 'right',
  })

  return (
    <Row justify="space-between">
      <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={16}>
        <Tooltip title={tip} placement={tooltipPosition}>
          {children}
        </Tooltip>
      </Col>
    </Row>
  )
}

export default InputWithTip
