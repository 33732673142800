import { Form } from 'antd'
import React, { FC, useContext } from 'react'
import FormFooter from '../../../components/Form/FormFooter'
import ApiContext from '../../../context/ApiContext'
import UserContext from '../../../context/UserContext'
import { StartupModel } from '../../../domain/startup.model'
import { StartupTeamReq } from '../../../services/Api'
import TeamStartupForm from '../Update/Common/TeamStartupForm'
import { useTranslation } from 'react-i18next'
import FormHelper from '../../../utils/FormHelper'

interface Props {
  onSave: () => void
  onCancel: () => void
  startup: StartupModel
}

const InitTeamStartup: FC<Props> = ({ onSave, onCancel, startup }: Props) => {
  const { errorFormBehaviour, updateStartupTeam } = useContext(ApiContext)
  const { user, setUser } = useContext(UserContext)
  const [form] = Form.useForm()

  const save = async (team: StartupTeamReq) => {
    if (await FormHelper.validateForm(form)) {
    updateStartupTeam(startup.id, team)
      .then(onSave)
      .then(() => setUser({ ...user, profilCompleted: true }))
      .catch(errorFormBehaviour)
    }
  }

  const onSaveDraftMode = (errorInfo: any) => {
    save(errorInfo.values)
  }

  const { t } = useTranslation('startup')

  return startup == null ? (
    <></>
  ) : (
    <Form
      layout="vertical"
      onFinish={save}
      onFinishFailed={onSaveDraftMode}
      requiredMark={true}
      form={form}
      initialValues={{
        fondateurs:
          startup.team && startup.team.fondateurs
            ? startup.team.fondateurs
            : [{}],
        collaborateurs: startup.team.collaborateurs || 0,
        ...startup,
      }}
    >
      <h2 className="mb-60">{t('team')}</h2>
      <TeamStartupForm />
      <FormFooter
        onCancel={onCancel}
        cancelLabel={t('back')}
        saveLabel={t('continue')}
      />
    </Form>
  )
}

export default InitTeamStartup
