import { Badge as AntBadge } from 'antd'
import React, { FC } from 'react'
import styles from './Badge.module.scss'

interface Props {
  text: string
  color?: string
  backgroundColor?: string
}

const Badge: FC<Props> = ({ text, color, backgroundColor }: Props) => {
  return (
    <AntBadge
      className={styles.badge}
      style={{ color, backgroundColor }}
      status="default"
      text={text}
    />
  )
}

export default Badge
