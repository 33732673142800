import { EcosystemsModel } from '../../domain/ecosystems.model'
import React, { FC, useContext, useEffect, useState } from 'react'
import useBreakpointValue from '../../utils/useBreakpointValue.hook'
import { Button, Col, Drawer, Form, Input, Modal, Row } from 'antd'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import { PlusCircleOutlined } from '@ant-design/icons'
import styles from './SubEcosystemForm.module.scss'
import { ReactComponent as TrashIcon } from '../../styles/svg/Trash.svg'
import { useTranslation } from 'react-i18next'

interface Props {
  ecosystem: EcosystemsModel | undefined
  onUpdate: (e: EcosystemsModel) => void
  onClose: () => void
  visible: boolean
}

const EditEcosystemDrawer: FC<Props> = ({
  visible,
  ecosystem,
  onUpdate,
  onClose,
}: Props) => {
  const { saveEcosystem, deleteEcosystem, successFormBehaviour } =
    useContext(ApiContext)
  const { t: t_ecosystem } = useTranslation('ecosystem')
  const { t: t_common } = useTranslation('common')
  const drawerWidth = useBreakpointValue<string>({
    xs: '100%',
    md: '80%',
    lg: '65%',
    xl: '50%',
  })
  const [name, setName] = useState<string>()
  const [comment, setComment] = useState<string>()
  const [showModal, setShowModal] = useState<boolean>(false)
  const [modalContent, setModalContent] = useState({
    title: '',
    action: '',
    cancel: '',
    body: '',
    func: () => {},
  })

  const [form] = Form.useForm()

  useEffect((): void => {
    form.resetFields()
    if (ecosystem) {
      setName(ecosystem.name)
      setComment(ecosystem.comment)
    }
  }, [ecosystem, form])

  const existEmptySubEcosystem = () =>
    form
      .getFieldValue('subEcosystems')
      .filter((e: { name: string }) => (e.name || '').length === 0).length > 0

  const getListOfDeletedEcosystems = () => {
    const updateSubEcosystems = form.getFieldValue('subEcosystems')
    if (!ecosystem || !ecosystem.subEcosystems) {
      return []
    }
    return ecosystem.subEcosystems.filter(
      (subEcosystem) => !updateSubEcosystems.includes(subEcosystem),
    )
  }

  const onSave = () => {
    if (ecosystem != null && name != null && !existEmptySubEcosystem()) {
      const subEcosystems = form.getFieldValue('subEcosystems')
      saveEcosystem(ecosystem.id, {
        name,
        comment,
        subEcosystems,
      })
        .then(() => successFormBehaviour(t_ecosystem('ecosystemSaved')))
        .then(() =>
          onUpdate({
            ...ecosystem,
            name,
            comment,
            subEcosystems,
          }),
        )
        .then(() => setShowModal(false))
        .then(onClose)
    }
  }

  const onDelete = () => {
    if (ecosystem != null) {
      deleteEcosystem(ecosystem.id)
        .then(() => successFormBehaviour(t_ecosystem('ecosystemDeleted')))
        .then(() => onUpdate({ ...ecosystem }))
        .then(() => setShowModal(false))
        .then(onClose)
    }
  }

  return (
    <>
      <Drawer
        title={t_ecosystem('modifyEcosystem')}
        width={drawerWidth}
        onClose={onClose}
        visible={visible}
        footer={
          <div className={styles.DrawerFooter}>
            <MainButton
              onClick={() => {
                const listOfDeletedEcosystems = getListOfDeletedEcosystems()
                if (listOfDeletedEcosystems.length) {
                  setModalContent({
                    title: t_ecosystem('deletionOfSubEcosystem'),
                    action: t_common('delete'),
                    cancel: t_common('cancel'),
                    body: `${t_ecosystem(
                      'warning.subEcosystemsWillBeDeleted',
                    )} [${listOfDeletedEcosystems
                      .map((sub) => sub.name)
                      .join(', ')}]\n ${t_ecosystem(
                      'warning.linkedStartupsWillBeDeactivatedAndUsersPermanentlyDeleted',
                    )}`,
                    func: onSave,
                  })
                  setShowModal(true)
                } else {
                  onSave()
                }
              }}
            >
              Sauvegarder
            </MainButton>
            <Button onClick={onClose}>{t_common('cancel')}</Button>
            <Button
              onClick={() => {
                setModalContent({
                  title: t_ecosystem('deleteEcosystem'),
                  action: t_common('delete'),
                  cancel: t_common('cancel'),
                  body: t_ecosystem(
                    'warning.ecosystemWillBeDeletedAndLinkedStartupDisable',
                  ),
                  func: onDelete,
                })
                setShowModal(true)
              }}
            >
              {t_common('delete')}
            </Button>
          </div>
        }
      >
        {ecosystem ? (
          <>
            <Form form={form} layout="vertical" requiredMark={true}>
              <Form.Item
                label={t_ecosystem('name')}
                rules={[{ required: true, message: t_common('requiredField') }]}
              >
                <Input.TextArea
                  defaultValue={ecosystem.name}
                  onChange={(e) => setName(e.target.value)}
                  rows={1}
                />
              </Form.Item>
              <Form.Item label={t_ecosystem('comment')}>
                <Input.TextArea
                  defaultValue={ecosystem.comment}
                  onChange={(e) => setComment(e.target.value)}
                  rows={6}
                />
              </Form.Item>

              <Form.Item label={<>Sous ecosystème(s)</>}>
                <div style={{ maxHeight: '500px', overflowY: 'scroll' }}>
                  <Form.List
                    name="subEcosystems"
                    initialValue={ecosystem.subEcosystems || []}
                  >
                    {(subEcosystems, { add, remove }) => (
                      <>
                        <Row align="middle" className={styles.addBtnSpacing}>
                          <Col>
                            <PlusCircleOutlined
                              onClick={() => add('', 0)}
                              className={styles.clickableIcon}
                            />
                          </Col>
                          <Col
                            onClick={() => add('', 0)}
                            className={styles.clickableDescription}
                          >
                            <div>{t_ecosystem('addSubEcosystem')}</div>
                          </Col>
                        </Row>
                        {subEcosystems.map((subEcosystem) => (
                          <Row
                            key={subEcosystem.key}
                            style={{
                              alignItems: 'baseline',
                            }}
                          >
                            <Col span={16} offset={1}>
                              <Form.Item
                                {...subEcosystem}
                                name={[subEcosystem.name, 'name']}
                                style={{
                                  marginBottom: '30px',
                                  width: '100%',
                                }}
                                rules={[
                                  {
                                    required: true,
                                    message: t_common('requiredField'),
                                  },
                                ]}
                              >
                                <Input
                                  size="large"
                                  placeholder={t_ecosystem('nameOfEcosystem')}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={5} offset={1}>
                              <TrashIcon
                                className={styles.clickableIcon}
                                onClick={() => remove(subEcosystem.name)}
                              />
                            </Col>
                          </Row>
                        ))}
                      </>
                    )}
                  </Form.List>
                </div>
              </Form.Item>
            </Form>
          </>
        ) : null}
      </Drawer>
      <Modal
        maskStyle={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
        title={modalContent.title}
        centered
        visible={showModal}
        onCancel={() => setShowModal(false)}
        footer={[
          <Button key="delete" onClick={modalContent.func}>
            {modalContent.action}
          </Button>,
          <MainButton key="cancel" onClick={() => setShowModal(false)}>
            {modalContent.cancel}
          </MainButton>,
        ]}
      >
        <p>{modalContent.body}</p>
        <p> {t_ecosystem('confirmDeletion')} </p>
      </Modal>
    </>
  )
}

export default EditEcosystemDrawer
