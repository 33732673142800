import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../OptionWithCount.model'

type Props<T> = Omit<SelectProps<any>, 'options'> & {
  options: T[]
  valueMapper?: (v: T) => any
  format?: (v: T) => string
}

const InputSelect: FC<Props<any>> = <T,>({
  options,
  valueMapper,
  format,
  ...props
}: Props<T>) => {
  const [config, setConfig] = useState<
    ReturnType<OptionWithCountModel<any>['toSelectOption']>[]
  >([])
  const { t } = useTranslation('common')

  useEffect((): void => {
    setConfig(
      OptionWithCountHelper.buildFromMapper(
        options,
        valueMapper,
        t,
        format,
      ).map(OptionWithCountHelper.toSelectOption),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  return (
    <Select
      {...props}
      showSearch
      size="large"
      options={config}
      getPopupContainer={(trigger) => trigger.parentNode}
      optionFilterProp="label"
    />
  )
}

export default InputSelect
