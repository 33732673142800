import { Skeleton, Tabs } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import Section from '../../../components/Layout/Section'
import SectionTabs from '../../../components/Layout/SectionTabs'
import ApiContext from '../../../context/ApiContext'
import StartupContext from '../../../context/StartupContext'
import { StartupModel } from '../../../domain/startup.model'
import usePermission from '../../../services/usePermission.hook'
import { Roles } from '../../../utils/roles.enum'
import ShowStartup from './ShowStartup'
import StartupHeaderInno from './StartupHeaderInno'
import PianoAnalyticsContext from '../../../context/PianoAnalyticsContext'
import { useTranslation } from 'react-i18next'

interface Props {
  id: string
}

const Startup: FC<Props> = ({ id }: Props) => {
  const [startup, setStartup] = useState<StartupModel>()
  const { getStartup } = useContext(ApiContext)
  const pianoAnalytics = useContext(PianoAnalyticsContext)
  const { replaceStartup } = useContext(StartupContext)

  const isSimpleView = usePermission([Roles.CLIENT, Roles.BANQUIER])
  const isInno = usePermission(Roles.INNO)
  const { t } = useTranslation('common')

  const loadStartup = () =>
    getStartup(id).then((s) => {
      setStartup(s)
      // send last version into StartupContext
      replaceStartup(s)
      return s
    })

  useEffect(() => {
    if (id != null) {
      loadStartup().then((s) => pianoAnalytics.displayStartup(s))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  if (startup == null) {
    return <Skeleton loading={true} />
  } else if (isSimpleView) {
    return <ShowStartup startup={startup} />
  }
  return (
    <>
      <Section className="no-print">
        {isInno ? (
          <StartupHeaderInno startup={startup} onUpdate={loadStartup} />
        ) : null}
      </Section>
      <SectionTabs defaultActiveKey="1" className="no-print-nav">
        <Tabs.TabPane tab={t('presentation')} key="1">
          <ShowStartup startup={startup} onUpdate={loadStartup} />
        </Tabs.TabPane>
      </SectionTabs>
    </>
  )
}

export default Startup
