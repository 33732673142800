import React, { ReactNode } from 'react'
import { ReactComponent as EyeIcon } from '../styles/svg/Eye.svg'

class FormHelper {
  public static isBlank = (value: string | null | undefined): boolean =>
    value == null || value.trim() === ''

  public static passwordIconRenderer: (b: boolean) => ReactNode = (visible) => (
    <EyeIcon fill={visible ? '#746250' : '#CFCAC7'} />
  )

  public static validateEmail = (email: string) => {
    return RegExp(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    ).exec(email.toLowerCase())
  }

  public static async validateForm(form: any): Promise<boolean> {
    try {
      await form.validateFields()
    } catch (error) {
      return false;
    }

    return true;
  }
}

export default FormHelper
