import { useContext, useEffect, useState } from 'react'
import { useQueryParam } from 'use-query-params'
import AuthenticationContext from '../context/AuthenticationContext'

const isBlank = (value: string | null | undefined): boolean =>
  value == null || value.trim() === ''

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
// TODO return type is [ (boolean|undefined), string, string] but conflict with eslint...
function useTokenValidation(): any[] {
  const [token] = useQueryParam<string>('token')
  const [email] = useQueryParam<string>('email')

  const [warning, setWarning] = useState<boolean>()
  const { validateToken } = useContext(AuthenticationContext)

  useEffect((): void => {
    if (isBlank(token) || isBlank(email)) {
      setWarning(true)
    } else {
      // @ts-ignore because checked just above
      validateToken(token, email)
        .then(() => setWarning(false))
        .catch(() => setWarning(true))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token])

  return [warning, token, email]
}

export default useTokenValidation
