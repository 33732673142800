import React from 'react'
import { StartupModel } from '../domain/startup.model'
import { EcosystemsModel } from '../domain/ecosystems.model'

export interface StartupContextI {
  startups: StartupModel[]
  ecosystems: EcosystemsModel[]
  reloadStartup: (id: string) => void
  replaceStartup: (startup: StartupModel) => void
  getEcosystemName: (startup: StartupModel) => string
}

const StartupContext = React.createContext<StartupContextI>({
  startups: [],
  ecosystems: [],
  reloadStartup: () => {},
  replaceStartup: () => {},
  getEcosystemName: () => {
    return ''
  },
})

export default StartupContext
