import { useEffect, useState } from 'react'
import PageResultModel from '../domain/PageResult.model'

interface ItemWithId {
  id: string
}

interface PaginatedData<E extends ItemWithId> {
  data: E[]
  loading: boolean
  totalElements: number
  loadMore?: () => void
  onSave: (e: E) => void
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function usePaginated<E extends ItemWithId>(
  loadFn: (page?: number, pageSize?: number) => Promise<PageResultModel<E>>,
  deps: any[] = [],
  pageSize?: number,
): PaginatedData<E> {
  const [data, setData] = useState<E[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [pageNum, setPageNum] = useState<number>(0)
  const [totalElements, setTotalElements] = useState<number>(0)

  const loadMore = () => {
    setLoading(true)
    loadFn(pageNum, pageSize)
      .then((page) => {
        setData([...data, ...page.data])
        setPageNum(pageNum + 1)
        setTotalElements(page.totalElements)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const firstLoad = () => {
    setLoading(true)
    loadFn(0, pageSize)
      .then((page) => {
        setData(page.data)
        setPageNum(1)
        setTotalElements(page.totalElements)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const onSave = (updatedItem: E) => {
    let idx = -1
    if (updatedItem.id) {
      idx = data.findIndex((s) => s.id === updatedItem.id)
    }
    if (idx !== -1) {
      data.splice(idx, 1, updatedItem)
      setData([...data])
    } else {
      setData([updatedItem, ...data])
    }
  }

  useEffect((): void => {
    setPageNum(0)
    setTotalElements(0)
    setData([])
    firstLoad()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)

  return {
    data,
    loading,
    totalElements,
    loadMore: totalElements > data.length ? loadMore : undefined,
    onSave,
  }
}

export default usePaginated
