import { Card, Divider } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import BadgeEventStatus from '../../components/Badge/BadgeEventStatus'
import { EventDtoModel } from '../../domain/event/EventDtoModel'
import FormatHelper from '../../utils/FormatHelper'
import styles from './DemandeContactCard.module.scss'

interface Props {
  event: EventDtoModel
  onClick?: () => void
}

const DemandeContactCard: FC<Props> = ({
  event,
  onClick = () => {},
}: Props) => {
  const { t } = useTranslation('event')

  return (
    <Card
      hoverable
      onClick={onClick}
      title={`${t('sendThe')} ${FormatHelper.formatDate(event.date, 'fr')}`}
      actions={[<BadgeEventStatus status={event.status} />]}
    >
      {t('by')}: <br />
      <Link
        className={styles.link}
        onClick={(e) => e.stopPropagation()}
        to={`/clients/${event.client?.id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {event.client?.fullName}
      </Link>
      <Divider />
      {t('for')}: <br />
      <Link
        className={styles.link}
        onClick={(e) => e.stopPropagation()}
        to={`/startups/${event.startup?.id}`}
        rel="noopener noreferrer"
        target="_blank"
      >
        {event.startup?.fullName}
      </Link>
    </Card>
  )
}

export default DemandeContactCard
