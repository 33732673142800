import React from 'react'
import {
  AuthReq,
  InitAccountReq,
  ResetPasswordReq,
} from '../services/Authentication'
import MyProfilDto from '../domain/MyProfilDto'

const notImplemented = () => Promise.reject(new Error('not implemented yet'))

export interface AuthenticationContextI {
  authenticate: (data: AuthReq) => Promise<void>
  authenticateAndRedirect: (data: AuthReq) => Promise<void>
  getMe: () => Promise<MyProfilDto>
  validateToken: (token: string, email: string) => Promise<void>
  initAccount: (req: InitAccountReq) => Promise<void>
  resetPassword: (req: ResetPasswordReq) => Promise<void>
  sendEmailResetPassword: (email: string) => Promise<void>
}

const AuthenticationContext = React.createContext<AuthenticationContextI>({
  authenticate: notImplemented,
  authenticateAndRedirect: notImplemented,
  getMe: notImplemented,
  validateToken: notImplemented,
  initAccount: notImplemented,
  resetPassword: notImplemented,
  sendEmailResetPassword: notImplemented,
})

export default AuthenticationContext
