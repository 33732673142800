import { Button, Checkbox, Col, notification, Row } from 'antd'
import React, { useContext, useState } from 'react'
import styles from './Cgu.module.scss'

import ApiContext from '../../context/ApiContext'
import UserContext from '../../context/UserContext'
import { useHistory } from 'react-router-dom'
import Section from '../../components/Layout/Section'
import CguDetails from './CguDetails'
import { Roles } from '../../utils/roles.enum'
import PianoAnalyticsContext from '../../context/PianoAnalyticsContext'

export default function Cgu() {
  const [checked, setChecked] = useState(false)
  const { consent, successFormBehaviour } = useContext(ApiContext)
  const { setUser, logout, user } = useContext(UserContext)
  const pianoAnalytics = useContext(PianoAnalyticsContext)
  const history = useHistory()

  const enableButton = () => setChecked(!checked)

  const handleOk = () =>
    consent().then(() => {
      successFormBehaviour('Bienvenue sur Startup App')
      if (
        user &&
        user.roles &&
        user.roles.length === 1 &&
        [Roles.BANQUIER, Roles.INNO].includes(user.roles[0])
      ) {
        pianoAnalytics.createUser()
      }
      history.push('/')
    })

  const doLogout = () =>
    logout().then(() => {
      setUser(null)
      notification['error']({ message: 'Vous êtes déconnecté' })
    })

  return (
    <Section>
      <CguDetails />
      <Row className={styles.readBloc} justify={'center'}>
        <Col>
          <Checkbox checked={checked} onChange={enableButton} />
        </Col>
        <Col style={{ marginLeft: '10px' }}>
          J'ai lu et j'accepte les Conditions générales de Startup Connections
        </Col>
      </Row>
      <br />
      <Row gutter={60} justify={'center'}>
        <Col>
          <Button type="primary" onClick={handleOk} disabled={!checked}>
            Valider
          </Button>
        </Col>
        <Col>
          <Button onClick={doLogout}>Annuler</Button>
        </Col>
      </Row>
    </Section>
  )
}
