import { TFunction } from 'react-i18next'

enum StartupNeedsEnum {
  FUND_RISING = 'FUND_RISING',
  BUSINESS_RELATIONSHIP = 'BUSINESS_RELATIONSHIP',
}

export class StartupNeedHelper {
  static format = (needs: StartupNeedsEnum, t: TFunction): string =>
    t(`startupNeed.${needs}`) || needs

  static allNeeds = (): StartupNeedsEnum[] => Object.values(StartupNeedsEnum)
}

export default StartupNeedsEnum
