import React, { FC, useContext, useEffect, useState } from 'react'
import Section from '../../components/Layout/Section'
import { Button, Input, Table } from 'antd'
import ApiContext from '../../context/ApiContext'
import { ColumnsType } from 'antd/lib/table/interface'
import { EcosystemsModel } from '../../domain/ecosystems.model'
import TableHelper from '../../utils/TableHelper'
import styles from '../Events/DemandesContact.module.scss'
import MainButton from '../../components/MainButton/MainButton'
import CreateEcosystemDrawer from './CreateEcosystemDrawer'
import EditEcosystemDrawer from './EditEcosystemDrawer'
import { useTranslation } from 'react-i18next'
import FormHelper from '../../utils/FormHelper'

const Ecosystems: FC = () => {
  const { t: t_ecosystem } = useTranslation('ecosystem')
  const { t: t_common } = useTranslation('common')

  const columnConfig: ColumnsType<EcosystemsModel> = [
    {
      title: t_ecosystem('name'),
      key: 'name',
      dataIndex: 'name',
      sorter: TableHelper.stringCompare('name'),
      filtered: true,
    },
    {
      title: t_ecosystem('comment'),
      key: 'comment',
      dataIndex: 'comment',
      sorter: TableHelper.stringCompare('comment'),
      filtered: true,
    },
    {
      render: (_, ecosystem) => (
        <Button size="small" onClick={() => setSelectedEcosystem(ecosystem)}>
          {t_common('see')}
        </Button>
      ),
    },
  ]

  const { getEcosystems } = useContext(ApiContext)
  const [createVisible, setCreateVisible] = useState<boolean>(false)
  const [ecosystems, setEcosystems] = useState<EcosystemsModel[]>([])
  const [selectedEcosystem, setSelectedEcosystem] = useState<EcosystemsModel>()
  const reloadEcosystems = () => {
    getEcosystems().then(setEcosystems)
  }
  const [filteredEcosystems, setFilteredEcosystems] = useState<
    EcosystemsModel[]
  >([])

  useEffect(() => {
    getEcosystems().then((res: EcosystemsModel[]) => {
      setEcosystems(res)
      setFilteredEcosystems(res)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterEcosystems = (search: string): void => {
    if (FormHelper.isBlank(search)) {
      setFilteredEcosystems(ecosystems)
    } else {
      setFilteredEcosystems(
        ecosystems.filter((ecosystem) =>
          `${ecosystem.name} ${ecosystem.subEcosystems} ${ecosystem.comment}`
            .toLowerCase()
            .includes(search.toLowerCase()),
        ),
      )
    }
  }

  return (
    <>
      <Section>
        <div className={styles.TitleBloc}>
          <h2>{t_ecosystem('listOfEcosystem')}</h2>
          <MainButton onClick={() => setCreateVisible(true)}>
            {t_ecosystem('addEcosystem')}
          </MainButton>
        </div>
        <div className={'search-bar-container'}>
          <label className={'search-label'}>
            {t_ecosystem('search_among_ecosystems')}
          </label>
          <Input.Search
            placeholder={'ex: Village By CA'}
            allowClear
            onChange={(e) => filterEcosystems(e.target.value)}
            style={{ width: 300 }}
          />
        </div>
        <br />
        <Table
          className={'table-with-clickable-row'}
          rowKey={(ecosystem: EcosystemsModel): string => ecosystem.id}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                event.stopPropagation()
                setSelectedEcosystem(record)
              },
            }
          }}
          locale={{
            triggerDesc: t_common('triggerDesc'),
            triggerAsc: t_common('triggerAsc'),
            cancelSort: t_common('cancelSort'),
          }}
          columns={columnConfig}
          dataSource={filteredEcosystems}
        ></Table>
      </Section>
      <CreateEcosystemDrawer
        onClose={() => {
          setCreateVisible(false)
          reloadEcosystems()
        }}
        visible={createVisible}
      />
      <EditEcosystemDrawer
        ecosystem={selectedEcosystem}
        onUpdate={(e) => {
          setSelectedEcosystem(e)
          reloadEcosystems()
        }}
        onClose={() => {
          setSelectedEcosystem(undefined)
        }}
        visible={selectedEcosystem != null}
      />
    </>
  )
}

export default Ecosystems
