import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApiContext from '../../context/ApiContext'
import { ClientModel } from '../../domain/client/client.model'
import ModifyClient from './ModifyClient'

const ModifyMyClientProfil: FC = () => {
  const [client, setClient] = useState<ClientModel>()
  const { getMyClientProfil } = useContext(ApiContext)

  const { t } = useTranslation('client')

  useEffect((): void => {
    getMyClientProfil().then(setClient)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ModifyClient client={client} title={t('customYourCriterias')} />
}

export default ModifyMyClientProfil
