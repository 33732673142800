import {
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Checkbox,
  Radio,
} from 'antd'
import moment from 'moment'
import React, { FC, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import InputPhone from '../../../../components/Form/InputPhone'
import InputPrice from '../../../../components/Form/InputPrice'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../../../../components/Form/OptionWithCount.model'
import StartupNeedsEnum, {
  StartupNeedHelper,
} from '../../../../domain/startup-needs.enum'
import StartupRaiseStatusEnum, {
  StartupRaiseStatusHelper,
} from '../../../../domain/startup-raiseStatus.enum'
import { StartupModel } from '../../../../domain/startup.model'
import { phoneValidator } from '../../../../services/validators/phone.validators'
// import { ReactComponent as EyeIcon } from '../../../../styles/svg/Eye.svg'
import FormatHelper from '../../../../utils/FormatHelper'
import formStyles from './StartupForm.module.scss'

interface Props {
  prefix?: keyof StartupModel
  initialNeeds?: StartupNeedsEnum[]
}

const raiseStatusConfig = (
  t: TFunction,
): OptionWithCountModel<StartupRaiseStatusEnum>[] =>
  OptionWithCountHelper.buildFrom(
    StartupRaiseStatusHelper.allRaiseStatus(),
    t,
    StartupRaiseStatusHelper.format,
  )
const needsConfig = (t: TFunction): OptionWithCountModel<StartupNeedsEnum>[] =>
  OptionWithCountHelper.buildFrom(
    StartupNeedHelper.allNeeds(),
    t,
    StartupNeedHelper.format,
  )

const monthFormat = 'MM/YYYY'

const RaiseStartupForm: FC<Props> = ({ prefix, initialNeeds }: Props) => {
  const [needs, setNeeds] = useState<StartupNeedsEnum[]>(initialNeeds || [])
  const { t: t_startup } = useTranslation('startup')
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')
  const { t: t_input } = useTranslation('input')

  return (
    <div className={'startup-form'}>
      <Form.Item
        label={
          <>
            {t_common('todayYouWant')}
            <small>{t_common('multipleChoiceAllow')}</small>
          </>
        }
        name={FormatHelper.formItemNameFormatter(prefix, 'need')}
        rules={[{ required: false, message: t_common('requiredField') }]}
        noStyle
      >
        <Checkbox.Group
          options={needsConfig(t_domain)}
          onChange={(checkedValue: any): void => setNeeds(checkedValue)}
        />
      </Form.Item>
      <p>{t_startup('youWillBeAbleToModifyThisChoice')}</p>

      {needs.includes(StartupNeedsEnum.FUND_RISING) ? (
        <>
          <Form.Item
            label={t_startup('input.towerType.label')}
            name={FormatHelper.formItemNameFormatter(prefix, 'status')}
            rules={[{ required: true, message: t_common('requiredField') }]}
          >
            <Radio.Group options={raiseStatusConfig(t_domain)} />
          </Form.Item>

          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                label={t_startup('input.amountSought.label')}
                name={FormatHelper.formItemNameFormatter(prefix, 'targetMoney')}
                rules={[
                  {
                    type: 'number',
                    min: 100000,
                    max: 100000000,
                    message: `${t_common(
                      'indicateMinimumAmount',
                    )} < 100 000 000 €`,
                  },
                ]}
              >
                <InputPrice
                    defaultValue={100000}
                    min={100000}
                    max={100000000}
                    fullWidth />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={t_startup('input.minimumEntryTicket.label')}
                name={FormatHelper.formItemNameFormatter(prefix, 'minInvest')}
                rules={[
                  {
                    type: 'number',
                    min: 100000,
                    max: 100000000,
                    message: `${t_common(
                      'indicateMinimumAmount',
                    )} < 100 000 000 €`,
                  },
                ]}
              >
                <InputPrice
                  defaultValue={100000}
                  min={100000}
                  max={100000000}
                  fullWidth
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t_startup('input.expectedClosingDate.label')}
            name={FormatHelper.formItemNameFormatter(prefix, 'dateRaise')}
            rules={[{ required: false, message: t_common('requiredField') }]}
          >
            <DatePicker
              size="large"
              placeholder={t_input('month_placeholder')}
              picker="month"
              defaultPickerValue={moment()}
              format={monthFormat}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </>
      ) : null}

      <Divider className={formStyles.formDivider} />

      <h1>{t_startup('contactIfContactRequest')}</h1>
      <p className={`${formStyles.formSubTitle} ${formStyles.noMargin}`}>
        {t_startup('onlyVisibleByIndosuez')}
      </p>
      <p>{t_startup('customersWillNotBeAbleToContactYouDirectly')}</p>
      {/*<div className={styles.infoContainer}>
        <strong>
          <EyeIcon fill="#746250" /> &nbsp; {t_startup('onlyVisibleByIndosuez')}
        </strong>
        <p>{t_startup('customersWillNotBeAbleToContactYouDirectly')}</p>
      </div>*/}
      <Form.Item
        label={`${t_common('firstName')} ${t_common('and')} ${t_common(
          'lastName',
        )}`}
        name={FormatHelper.formItemNameFormatter(prefix, 'contactName')}
        rules={[{ required: true, message: t_common('requiredField') }]}
      >
        <Input placeholder={'Nicolas Dupont'} size="large" />
      </Form.Item>

      <Form.Item
        label={t_common('email')}
        name={FormatHelper.formItemNameFormatter(prefix, 'email')}
        rules={[
          {
            required: true,
            type: 'email',
            message: t_startup('input.email.message'),
          },
        ]}
      >
        <Input
          placeholder={'dupont.nicolas@mail.com'}
          size="large"
          autoCapitalize="none"
        />
      </Form.Item>

      <p className={formStyles.formSubTitle}>
        {t_startup('input.mobile.label')}
      </p>
      <Form.Item
        name={FormatHelper.formItemNameFormatter(prefix, 'mobilePhone')}
        rules={[{ required: true }, phoneValidator]}
      >
        <InputPhone prefix={prefix} />
      </Form.Item>
      <Divider className={formStyles.formDivider} />
    </div>
  )
}

export default RaiseStartupForm
