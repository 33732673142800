export const getApiHost = () => {
  switch (window.location.hostname) {
    case 'localhost':
      return 'http://localhost:8080'
    case 'dev.startup.com':
      return 'http://dev.startup.com:8080'
    default:
      return `https://api.${window.location.hostname}`
  }
}

export const getHost = () => {
  if (window.location.hostname === 'localhost') {
    return 'http://localhost:3000'
  }
  return `https://${window.location.hostname}`
}
