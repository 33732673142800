import React, { FC, useContext, useState } from 'react'
import { Button, Col, Drawer, Form, Grid, Input, Row } from 'antd'
import MainButton from '../../components/MainButton/MainButton'
import { useTranslation } from 'react-i18next'
import ApiContext from '../../context/ApiContext'
import { ReactComponent as TrashIcon } from '../../styles/svg/Trash.svg'
import { PlusOutlined } from '@ant-design/icons'
import { RssFeedModel } from '../../domain/news/rssFeed.model'
import styles from './News.module.scss'
import { ReactComponent as EditRssFeedIcon } from '../../styles/svg/rssIcon.svg'

export const StartupNewsEditor: FC = (): JSX.Element => {
  const { getRssFeeds, postRssFeeds, errorFormBehaviour } =
    useContext(ApiContext)
  const { t: t_news } = useTranslation('news')
  const { t: t_common } = useTranslation('common')
  const breakpoint = Grid.useBreakpoint()

  const [open, setOpen] = useState<boolean>(false)
  const [rssFeeds, setRssFeeds] = useState<RssFeedModel[]>([])

  const onClose = () => {
    setOpen(false)
  }

  const showDrawer = () => {
    getRssFeeds()
      .then((res: RssFeedModel[]): void => {
        setRssFeeds(res)
        setOpen(true)
      })
      .catch(errorFormBehaviour)
  }

  const onSave = (data: any): void => {
    postRssFeeds(data.feeds).then(onClose).catch(errorFormBehaviour)
  }

  const initFeed = (): any[] => {
    let feeds: any[] = []
    if (rssFeeds && rssFeeds.length > 0) {
      rssFeeds.forEach((feed) => {
        feeds.push({ link: feed.link })
      })
    } else {
      feeds = [{}]
    }
    return feeds
  }

  return (
    <>
      <MainButton onClick={showDrawer} className={styles.trashIconContainer}>
        <EditRssFeedIcon className={styles.icon} />
        <p>{t_news('startup.edit')}</p>
      </MainButton>
      {open && (
        <Drawer
          title={t_news('startup.edit')}
          onClose={onClose}
          visible={open}
          closable
          width={breakpoint.xs ? '100%' : '30%'}
        >
          <>
            <Form
              layout="vertical"
              onFinish={onSave}
              initialValues={{ feeds: initFeed() }}
            >
              <Form.List name={'feeds'}>
                {(fields, { add, remove }) => (
                  <>
                    {fields.map((field, index) => (
                      <Row key={field.key}>
                        <Col span={22}>
                          <Form.Item
                            {...field}
                            key={[field.name, index].join('.')}
                            label={t_news('startup.link')}
                            name={[field.name, 'link']}
                            rules={[
                              {
                                required: true,
                                message: t_common('requiredField'),
                              },
                            ]}
                            className={styles.rssFeedInput}
                          >
                            <Input size="large" />
                          </Form.Item>
                        </Col>
                        <Col span={2} className={styles.trashIconContainer}>
                          <TrashIcon
                            className={styles.clickableIcon}
                            onClick={() => remove(field.name)}
                          />
                        </Col>
                      </Row>
                    ))}
                    <Button
                      onClick={(): void => add()}
                      className={styles.addButton}
                    >
                      {t_news('startup.add')}{' '}
                      <PlusOutlined className={styles.plusIcon} />
                    </Button>
                  </>
                )}
              </Form.List>
              <br />
              <Row justify={'space-between'}>
                <MainButton htmlType={'submit'}>{t_news('save')}</MainButton>
                <Button onClick={onClose}>{t_news('close')}</Button>
              </Row>
            </Form>
          </>
        </Drawer>
      )}
    </>
  )
}
