import { Form } from 'antd'
import moment from 'moment'
import React, { FC, useContext } from 'react'
import FormFooter from '../../../components/Form/FormFooter'
import ApiContext from '../../../context/ApiContext'
import { StartupModel } from '../../../domain/startup.model'
import { StartupRaiseReq } from '../../../services/Api'
import RaiseStartupForm from '../Update/Common/RaiseStartupForm'
import { useTranslation } from 'react-i18next'
import FormHelper from '../../../utils/FormHelper'

interface Props {
  onSave: () => void
  onCancel: () => void
  startup: StartupModel
}

const InitRaiseStartup: FC<Props> = ({ onSave, onCancel, startup }: Props) => {
  const { errorFormBehaviour, updateStartupRaise } = useContext(ApiContext)
  const [form] = Form.useForm()

  const save = async (raise: StartupRaiseReq) => {
    if (await FormHelper.validateForm(form)) {
      updateStartupRaise(startup.id, raise)
        .then(onSave)
        .catch(errorFormBehaviour)
    }
  }

  const onSaveDraftMode = (errorInfo: any) => {
    save(errorInfo.values)
  }

  const { t } = useTranslation('startup')

  return startup == null ? (
    <></>
  ) : (
    <Form
      layout="vertical"
      onFinish={save}
      onFinishFailed={onSaveDraftMode}
      requiredMark={true}
      form={form}
      initialValues={{
        ...startup.raise,
        dateRaise:
          startup.raise.dateRaise == null
            ? null
            : moment(startup.raise.dateRaise),
      }}
    >
      <h2 className="mb-60">{t('objective')}</h2>
      <RaiseStartupForm initialNeeds={startup.raise.need} />
      <FormFooter
        onCancel={onCancel}
        cancelLabel={t('back')}
        saveLabel={t('continue')}
      />
    </Form>
  )
}

export default InitRaiseStartup
