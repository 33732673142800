import { Button, Modal } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../../components/MainButton/MainButton'
import styles from './ModalRisks.module.scss'

interface Props {
  visible: boolean
  onAccept: () => void
  onRefuse: () => void
}

const ModalRisks: FC<Props> = ({ visible, onAccept, onRefuse }: Props) => {
  const { t } = useTranslation('client')

  return (
    <Modal
      width="800px"
      visible={visible}
      closable={false}
      footer={[
        <MainButton key="back" onClick={onAccept}>
          {t('acceptRisk')}
        </MainButton>,
        <Button key="back" onClick={onRefuse}>
          {t('close')}
        </Button>,
      ]}
    >
      <h2 className="mb-60">{t('riskWarning')}</h2>
      <div className={styles.body}>
        <p>{t('warning.first')}</p>
        <p>{t('warning.second')}</p>
        <p>{t('warning.third')}</p>
        <p>{t('warning.fourth')}</p>
        <ul className={styles.list}>
          <li>{t('warning.list.illiquidity')}</li>
          <li>{t('warning.list.valorisation')}</li>
          <li>{t('warning.list.riskOfLoss')}</li>
          <li>{t('warning.list.riskOfChange')}</li>
          <li>{t('warning.list.fiscality')}</li>
          <li>{t('warning.list.legal')}</li>
        </ul>
        <p>{t('warning.listExplainNotClearly')}</p>
      </div>
    </Modal>
  )
}

export default ModalRisks
