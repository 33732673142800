import { pianoAnalytics } from 'piano-analytics-js'
import { Location } from 'history'
import {
  PianoAnalyticsContextI,
  FilterInitialCriteria,
} from '../context/PianoAnalyticsContext'
import MyProfilDto from '../domain/MyProfilDto'
import { StartupModel } from '../domain/startup.model'
import { Roles } from '../utils/roles.enum'
import { EventStatusEnum } from '../domain/event/EventStatus.enum'

export interface AnonymeUserI {
  role: Roles
}

export class PianoAnalyticsService implements PianoAnalyticsContextI {
  public user?: AnonymeUserI

  constructor(siteId: number, user?: MyProfilDto) {
    pianoAnalytics.setConfigurations({
      site: siteId,
      collectDomain: 'https://logs1412.xiti.com',
    })
    if (user) {
      this.user = { role: user.roles.length ? user.roles[0] : Roles.INNO }
    }
  }

  private sendEvent(
    name: string,
    info: Record<string, any>,
    isLocation = false,
  ) {
    pianoAnalytics.sendEvent(name, {
      ...(isLocation ? info : { startupapp: info }),
      user: this.user,
    })
  }

  triggerLocation(location: Location) {
    this.sendEvent(
      'page.display',
      {
        page: location.pathname,
      },
      true,
    )
  }

  newKeyWord(keyword: string) {
    pianoAnalytics.setProperty('startupapp_search_keyword', keyword)
    this.sendEvent('search.keyword', {'keyword': keyword})
  }

  clickStartupCard(startup: StartupModel) {
    this.sendEvent('startup.click', extractStartupInfo(startup))
  }

  clickContactUs() {
    this.sendEvent('contactUs.click', {})
  }

  clickContactVillageCA() {
    this.sendEvent('contactLeVillageCA.click', {})
  }

  clickWithVillageCA() {
    this.sendEvent('withLeVillageCA.click', {})
  }

  displayStartup(startup: StartupModel) {
    this.sendEvent('startup.display', extractStartupInfo(startup))
  }

  validateStartup(startup: StartupModel) {
    this.sendEvent('startup.validate', extractStartupInfo(startup))
  }

  login() {
    this.sendEvent('user.login', {})
  }

  createUser() {
    this.sendEvent('user.create', {})
  }

  createLinking(status: EventStatusEnum) {
    this.sendEvent('linking.create', {
      linking_Status: status,
    })
  }

  updateLinking(status: EventStatusEnum) {
    this.sendEvent('linking.update', {
      linking_Status: status,
    })
  }

  createInviteLink(role: Roles) {
    this.sendEvent('inviteLink.create', {
      invited_Role: role,
    })
  }

  selectInitialCriterias(initialCriteria: FilterInitialCriteria) {
    const ecosystems = initialCriteria.ecosystems.map((e) => e.join(' - '))
    this.sendEvent('select.initialCriteria', {
      initialCriteria_Countries: initialCriteria.countries,
      initialCriteria_Ecosystems: ecosystems,
      initialCriteria_Regions: initialCriteria.regions,
      initialCriteria_Sectors: initialCriteria.sectors,
      initialCriteria_Needs: initialCriteria.needs,
    })
  }
}

const extractStartupInfo = (startup: StartupModel) => ({
  startup_Id: startup.id,
  startup_Name: startup.informations.name,
  startup_EnvironmentalImpact: startup.informations.environmentalImpact,
  startup_Sector: startup.informations.sector,
  startup_BusinessWoman: startup.informations.businessWoman,
  startup_HasLettreEmetteur: startup.informations.hasLettreEmetteur,
  startup_hasFicheOFAC: startup.informations.hasFicheOFAC,
})
