import React, { FC, ReactElement, useEffect, useRef, useState } from 'react'
import { Mail } from './mail.service'

interface Props {
  mail?: Mail
}

const mailToBuilder = ({ body, dest, subject }: Mail): string => {
  let encodedSubject = encodeURIComponent(subject)
  let encodedBody = encodeURIComponent(body)
  let encodedDest = encodeURIComponent(dest)
  return `mailto:${encodedDest}?subject=${encodedSubject}&body=${encodedBody}`
}

const Mailer: FC<Props> = ({ mail }: Props): ReactElement => {
  const mailToRef = useRef<HTMLAnchorElement>(null)
  const [mailTo, setMailTo] = useState<string>()

  useEffect((): void => {
    if (mailTo != null && mailToRef != null) {
      mailToRef.current?.click()
    }
  }, [mailTo, mailToRef])

  useEffect((): void => {
    if (mail != null) {
      setMailTo(mailToBuilder(mail))
    }
  }, [mail])

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
      <a
        style={{ display: 'none' }}
        ref={mailToRef}
        href={mailTo}
        target="_blank"
        rel="noopener noreferrer"
      />
    </>
  )
}

export default Mailer
