import { Location } from 'history'
import React from 'react'
import { EventStatusEnum } from '../domain/event/EventStatus.enum'
import { StartupModel } from '../domain/startup.model'
import { Roles } from '../utils/roles.enum'
import RegionEnum from '../domain/region.enum'
import CountryEnum from '../domain/country.enum'
import ClientNeedsEnum from '../domain/client/client-needs.enum'
import ClientInvestmentTypeEnum from '../domain/client/client-investmentType.enum'

const notImplemented = () => new Error('not implemented yet')

export interface FilterInitialCriteria {
  ecosystems: string[][]
  sectors: string[]
  countries: CountryEnum[]
  regions: RegionEnum[]
  needs: ClientNeedsEnum[]
  investmentType: ClientInvestmentTypeEnum
}

export interface PianoAnalyticsContextI {
  triggerLocation: (location: Location<unknown>) => void
  newKeyWord: (keyword: string) => void
  clickStartupCard: (startup: StartupModel) => void
  clickContactUs: () => void
  clickContactVillageCA: () => void
  clickWithVillageCA: () => void
  displayStartup: (startup: StartupModel) => void
  validateStartup: (startup: StartupModel) => void
  login: () => void
  createUser: () => void
  createInviteLink: (role: Roles) => void
  createLinking: (status: EventStatusEnum) => void
  updateLinking: (status: EventStatusEnum) => void
  selectInitialCriterias: (initialCriteria: FilterInitialCriteria) => void
}

export const defaultValue: PianoAnalyticsContextI = {
  triggerLocation: notImplemented,
  newKeyWord: notImplemented,
  clickStartupCard: notImplemented,
  clickContactUs: notImplemented,
  clickContactVillageCA: notImplemented,
  clickWithVillageCA: notImplemented,
  displayStartup: notImplemented,
  validateStartup: notImplemented,
  login: notImplemented,
  createUser: notImplemented,
  createInviteLink: notImplemented,
  createLinking: notImplemented,
  updateLinking: notImplemented,
  selectInitialCriterias: notImplemented,
}

const PianoAnalyticsContext =
  React.createContext<PianoAnalyticsContextI>(defaultValue)

export default PianoAnalyticsContext
