import { Form, Input, Modal, Row } from 'antd'
import React, { FC, useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import AuthenticationContext from '../../context/AuthenticationContext'
import FormHelper from '../../utils/FormHelper'
import styles from './PasswordLogin.module.scss'

export const ResetPasswordModal: FC = () => {
  const { sendEmailResetPassword } = useContext(AuthenticationContext)
  const { t: t_login } = useTranslation('login')
  const { t: t_common } = useTranslation('common')
  const { t: t_startup } = useTranslation('startup')
  const [username, setUsername] = useState<string>()
  const [open, setOpen] = useState<boolean>(false)
  const [emailSent, setEmailSent] = useState<boolean>(false)

  const resetPassword = (): void => {
    if (!FormHelper.isBlank(username) && username !== undefined) {
      sendEmailResetPassword(username).then(() => {
        onEmailSucceed()
      })
    }
  }

  const onEmailSucceed = (): void => {
    setUsername(undefined)
    setEmailSent(true)
  }

  const doClose = (): void => {
    setOpen(false)
  }

  return (
    <Row justify={'end'}>
      <button
        className={styles.resetPasswordButton}
        onClick={(): void => setOpen(true)}
      >
        {t_common('forgottenPassword')}
      </button>
      <Modal
        visible={open}
        title={t_common('forgottenPassword').toLocaleUpperCase()}
        onCancel={() => doClose()}
        cancelText={t_login('close')}
        okButtonProps={{
          disabled:
            FormHelper.isBlank(username) ||
            !FormHelper.validateEmail(username ?? ''),
          hidden: emailSent,
        }}
        onOk={resetPassword}
      >
        <>
          {emailSent ? (
            <div>
              <p>{t_login('email_sent')}</p>
            </div>
          ) : (
            <>
              <p>{t_login('enter_email_to_reinit_password')}</p>
              <Form requiredMark={false}>
                <Form.Item
                  name={'email'}
                  rules={[
                    {
                      required: true,
                      message: t_startup('input.email.message'),
                    },
                    {
                      type: 'email',
                      message: t_startup('input.email.validEmail'),
                    },
                  ]}
                  style={{ marginBottom: '10px' }}
                >
                  <Input
                    size="large"
                    type="email"
                    placeholder={'dupont.nicolas@mail.com'}
                    autoCapitalize="none"
                    value={username}
                    onChange={(e): void => {
                      setUsername(e.target.value)
                    }}
                  />
                </Form.Item>
              </Form>
            </>
          )}
        </>
      </Modal>
    </Row>
  )
}
