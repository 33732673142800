import { Button, Drawer, Input, Space } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import RadioBoxGroup from '../../components/Form/InputCheckBox/RadioBoxGroup'
import { OptionWithCountHelper } from '../../components/Form/OptionWithCount.model'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import PianoAnalyticsContext from '../../context/PianoAnalyticsContext'
import { EventDtoModel } from '../../domain/event/EventDtoModel'
import {
  EventStatusEnum,
  EventStatusHelper,
} from '../../domain/event/EventStatus.enum'
import FormatHelper from '../../utils/FormatHelper'
import useBreakpointValue from '../../utils/useBreakpointValue.hook'

interface Props {
  event: EventDtoModel | undefined
  onUpdate: (e: EventDtoModel) => void
  onClose: () => void
  visible: boolean
}

const statusConfig = (t: TFunction) =>
  OptionWithCountHelper.buildFrom(
    EventStatusHelper.values(),
    t,
    EventStatusHelper.format,
  )

const EditEventDrawer: FC<Props> = ({
  visible,
  event,
  onUpdate,
  onClose,
}: Props) => {
  const { saveEvent, successFormBehaviour } = useContext(ApiContext)
  const drawerWidth = useBreakpointValue<string>({
    xs: '100%',
    md: '80%',
    lg: '65%',
    xl: '50%',
  })
  const { t: t_event } = useTranslation('event')
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')

  const [status, setStatus] = useState<EventStatusEnum>()
  const [comment, setComment] = useState<string>()
  const pianoAnalytics = useContext(PianoAnalyticsContext)

  useEffect((): void => {
    if (event) {
      setStatus(event.status)
      setComment(event.comment)
    }
  }, [event])

  const onSave = () => {
    if (event != null && status != null) {
      saveEvent(event.id, { status, comment })
        .then(() => pianoAnalytics.updateLinking(status))
        .then(() => successFormBehaviour(t_event('cardSaved')))
        .then(() => onUpdate({ ...event, status, comment }))
        .then(onClose)
    }
  }

  return (
    <Drawer
      title={t_event('modifyEvent')}
      width={drawerWidth}
      onClose={onClose}
      visible={visible}
      footer={
        <Space direction="horizontal">
          <MainButton onClick={onSave}>{t_common('save')}</MainButton>
          <Button onClick={onClose}>{t_common('cancel')}</Button>
        </Space>
      }
    >
      {event ? (
        <>
          {event.client ? (
            <h4>
              {t_common('client')}:{' '}
              <small>
                <Link
                  to={`/clients/${event.client.id}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {event.client?.fullName}
                </Link>
              </small>
            </h4>
          ) : null}
          {event.startup ? (
            <h4>
              {t_common('startup')}:{' '}
              <small>
                <Link
                  to={`/startups/${event.startup.id}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {event.startup?.fullName}
                </Link>
              </small>
            </h4>
          ) : null}
          <RadioBoxGroup
            options={statusConfig(t_domain)}
            value={status}
            onChange={setStatus}
          />
          <ul className="ul">
            <li>
              {FormatHelper.formatDate(event.date, 'fr')} :{' '}
              {t_event('requestIssuance')}
            </li>
          </ul>
          <Input.TextArea
            defaultValue={event.comment}
            onChange={(e) => setComment(e.target.value)}
            rows={6}
          />
        </>
      ) : null}
    </Drawer>
  )
}

export default EditEventDrawer
