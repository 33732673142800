import { Button, Col, DatePicker, Divider, Form, Input, Row } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './StartupForm.module.scss'
import { PlusOutlined } from '@ant-design/icons'
import FormatHelper from '../../../../utils/FormatHelper'
import frenchFlag from '../../../../styles/svg/french_flag.svg'
import englishFlag from '../../../../styles/svg/english_flag.svg'
import { StartupModel } from '../../../../domain/startup.model'
import { ReactComponent as TrashIcon } from '../../../../styles/svg/Trash.svg'

interface Props {
  prefix?: keyof StartupModel
}

const RoadMapStartupForm: FC<Props> = ({ prefix }: Props) => {
  const { t: t_common } = useTranslation('common')
  const { t: t_startup } = useTranslation('startup')
  const { t: t_input } = useTranslation('input')

  return (
    <>
      <h1>{t_startup('input.keyDate.label')}</h1>
      <Form.List name={FormatHelper.formItemNameFormatter(prefix, 'roadMap')}>
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Row key={field.key} className={styles.dateRowContainer}>
                <Col xs={24} md={7}>
                  <Form.Item
                    {...field}
                    key={[field.name, index].join('.')}
                    label={'Date'}
                    name={[field.name, 'date']}
                    rules={[
                      {
                        required: true,
                        message: t_common('requiredField'),
                      },
                    ]}
                    className={styles.noMargin}
                  >
                    <DatePicker
                      style={{ display: 'flex' }}
                      size="middle"
                      placeholder={t_input('month_placeholder')}
                      picker="month"
                      format={'MM/YYYY'}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} md={{ span: 7, offset: 1 }}>
                  <Form.Item
                    {...field}
                    key={[field.name, index].join('.')}
                    label={
                      <>
                        <img
                          src={frenchFlag}
                          alt={''}
                          className={styles.flagIcon}
                        />
                        {t_input('description_fr')}
                      </>
                    }
                    name={[field.name, 'descriptionFr']}
                    rules={[
                      {
                        required: true,
                        max: 80,
                        message: `${t_input(
                          'textShouldNotExceed',
                        )} ${80} ${t_input('characters')}`,
                      },
                      {
                        required: true,
                        message: t_common('requiredField'),
                      },
                    ]}
                    className={styles.noMargin}
                  >
                    <Input size="middle" maxLength={80} />
                  </Form.Item>
                </Col>

                <Col xs={24} md={{ span: 7, offset: 1 }}>
                  <Form.Item
                    key={[field.name, index].join('.')}
                    label={
                      <>
                        <img
                          src={englishFlag}
                          alt={''}
                          className={styles.flagIcon}
                        />
                        {t_input('description_en')}
                      </>
                    }
                    name={[field.name, 'descriptionEn']}
                    rules={[
                      {
                        max: 80,
                        message: `${t_input(
                          'textShouldNotExceed',
                        )} ${80} ${t_input('characters')}`,
                      },
                      {
                        required: true,
                        message: t_common('requiredField'),
                      },
                    ]}
                    className={styles.noMargin}
                  >
                    <Input size="middle" maxLength={80} />
                  </Form.Item>
                </Col>

                <Col span={1} className={styles.trashIconContainer}>
                  {field.key !== 0 ?
                    <TrashIcon
                      className={styles.clickableIcon}
                      onClick={() => remove(field.name)}
                    />
                    : ""}
                </Col>
              </Row>
            ))}
            <br />
            {fields.length < 12 && (
              <Button onClick={(): void => add()}>
                {t_input('add_date')}{' '}
                <PlusOutlined className={styles.plusIcon} />
              </Button>
            )}
          </>
        )}
      </Form.List>
      <br />
      <Divider className={styles.formDivider} />
    </>
  )
}

export default RoadMapStartupForm
