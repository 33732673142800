import { SelectProps } from 'antd/lib/select'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApiContext from '../../context/ApiContext'
import { ClientListModel } from '../../domain/client/client-list.model'
import FormatHelper from '../../utils/FormatHelper'
import InputSelect from './InputSelect/InputSelect'

const ClientAutocomplete: FC<SelectProps<any>> = (props) => {
  const { t } = useTranslation('input')

  const { listClients } = useContext(ApiContext)
  const [clients, setClients] = useState<ClientListModel[]>([])

  useEffect((): void => {
    listClients().then(setClients)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InputSelect
      {...props}
      options={clients}
      valueMapper={(v) => v.id}
      format={FormatHelper.formatFullName}
      notFoundContent={t('thisCustomerIsNotInOurList')}
    />
  )
}

export default ClientAutocomplete
