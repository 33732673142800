import { LockOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import ContactBloc from '../../../components/Layout/InfoBloc/ContactBloc'
import InfoBloc from '../../../components/Layout/InfoBloc/InfoBloc'
import SectionWithInfo from '../../../components/Layout/SectionWithInfo'
import Steps from '../../../components/Steps/Steps'
import ApiContext from '../../../context/ApiContext'
import PianoAnalyticsContext from '../../../context/PianoAnalyticsContext'
import { StartupModel } from '../../../domain/startup.model'
import InitInfosStartup from './InitInfosStartup'
import InitOfferStartup from './initOfferStartup'
import InitRaiseStartup from './InitRaiseStartup'
import InitTeamStartup from './InitTeamStartup'
import ValidationStep from './ValidationStep'
import WelcomeStep from './WelcomeStep'
import { useTranslation } from 'react-i18next'
import InitPicturesStartup from './InitPicturesStartup'

const STEP_COUNT = 6

const computeCurrentStep = (startup: StartupModel): number => {
  // détection de l'étape en cours en vérifiant des champs obligatoires
  if (
    startup.informations.name == null ||
    startup.informations.country == null ||
    startup.informations.sector == null
  ) {
    return 0
  } else if (Object.values(startup.offer).some((o) => o == null)) {
    return 2
  } else if (Object.values(startup.raise).some((r) => r == null)) {
    return 3
  } else if (Object.values(startup.team).some((t) => t == null)) {
    return 4
  } else if (startup.pictureIds == null || startup.pictureIds.length === 0) {
    return 5
  }
  return 6
}

const InitStartup = () => {
  const [startup, setStartup] = useState<StartupModel>()
  const [step, setStep] = useState<number>(0)

  const { getMyStartup } = useContext(ApiContext)
  const pianoAnalytics = useContext(PianoAnalyticsContext)
  const { t } = useTranslation('startup')

  useEffect((): void => {
    getMyStartup().then((s: StartupModel): void => {
      let initStartup = s || {}
      if (initStartup.raise.targetMoney === 0 || initStartup.raise.targetMoney === null) {
        initStartup.raise.targetMoney = 100000
      }
      if (initStartup.raise.minInvest === 0 || initStartup.raise.minInvest === null) {
        initStartup.raise.minInvest = 100000
      }
      setStartup(initStartup)
      setStep(computeCurrentStep(initStartup))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onNextStep = () => setStep(Math.min(step + 1, STEP_COUNT))
  const onPreviousStep = () => setStep(Math.max(step - 1, 0))

  useEffect((): void => {
    // fix not on top of the page when switching steps
    window.scrollTo(0, 0)
    getMyStartup().then((s: StartupModel) => {
      if (s.raise.targetMoney === 0 || s.raise.targetMoney === null) {
        s.raise.targetMoney = 100000
      }
      if (s.raise.minInvest === 0 || s.raise.minInvest === null) {
        s.raise.minInvest = 100000
      }
      setStartup(s)
      if (step === STEP_COUNT) {
        pianoAnalytics.createUser()
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step])

  if (startup == null) {
    return null
  }

  let steps = [
    <WelcomeStep onNextStep={onNextStep} />,
    <InitInfosStartup
      onSave={onNextStep}
      onCancel={onPreviousStep}
      startup={startup}
    />,
    <InitOfferStartup
      onSave={onNextStep}
      onCancel={onPreviousStep}
      startup={startup}
    />,
    <InitRaiseStartup
      onSave={onNextStep}
      onCancel={onPreviousStep}
      startup={startup}
    />,
    <InitTeamStartup
      onSave={onNextStep}
      onCancel={onPreviousStep}
      startup={startup}
    />,
    <InitPicturesStartup
      onSave={onNextStep}
      onCancel={onPreviousStep}
      startup={startup}
    />,
    <ValidationStep />,
  ]

  return (
    <SectionWithInfo
      infoBloc={
        <>
          {0 < step && step < 6 ? (
            <>
              <ContactBloc />
              <InfoBloc>
                <Space direction="vertical">
                  <strong>
                    <LockOutlined /> {t('init.whoWillSeeMyDataQuestion')}
                  </strong>
                  <p>{t('init.whoWillSeeMyDataAnswer')}</p>
                </Space>
              </InfoBloc>
            </>
          ) : null}
        </>
      }
    >
      {step !== 0 ? <Steps size={STEP_COUNT} current={step} /> : null}
      {steps[step]}
    </SectionWithInfo>
  )
}

export default InitStartup
