import { Col, Divider, Grid, Layout, Row, Steps } from 'antd'
import React, {FC, ReactNode, useContext} from 'react'
import Footer from '../../components/Layout/Footer'
import { ReactComponent as LogoStartupByIndoLight } from '../../styles/svg/LogoStartupConnections_byIndosuez_inversed_colors.svg'
import { ReactComponent as LogoStartupByIndo } from '../../styles/svg/LogoStartupConnections_byIndosuez.svg'
import { ReactComponent as EcosystemIcon } from '../../styles/svg/ecosystemIcon.svg'
import { ReactComponent as TechnoIcon } from '../../styles/svg/technoIcon.svg'
import { ReactComponent as MeetingIcon } from '../../styles/svg/mettingIcon.svg'
import OfficeImage from '../../styles/png/landing_page_image.png'
import AppScreenshot from '../../styles/png/app_screenshot.png'
import startupReferenceIcon from '../../styles/svg/startup_reference.svg'
import contactRequestIcon from '../../styles/svg/contact_request.svg'
import investmentIcon from '../../styles/svg/investment.svg'
import expertsIcon from '../../styles/svg/experts.svg'

import styles from './LayoutLogin.module.scss'
import { Header } from 'antd/lib/layout/layout'
import SelectLanguage from '../../components/SelectLanguage'
import MainButton from '../../components/MainButton/MainButton'
import { PasswordLoginModal } from '../../components/Authentication/PasswordLoginModal'
import { StepProps } from 'antd/lib/steps'
import { useTranslation } from 'react-i18next'
import pianoAnalyticsContext from "../../context/PianoAnalyticsContext";

interface Props {
  title: ReactNode
  showFooter?: boolean
}

const LayoutLogin: FC<Props> = ({ title, showFooter = false, children }) => {
  const breakpoint = Grid.useBreakpoint()
  const pianoAnalytics = useContext(pianoAnalyticsContext)
  const { t: t_login } = useTranslation('login')

  const stepsProps = (): StepProps[] => [
    {
      title: '',
      icon: <></>,
    },
    {
      icon: <img src={startupReferenceIcon} alt={''} />,
      title: t_login('selected_startups'),
    },
    {
      icon: <img src={contactRequestIcon} alt={''} />,
      title: t_login('linking_request_asked_by_client'),
    },
    {
      icon: <img src={investmentIcon} alt={''} />,
      title: t_login('targeted_investments'),
    },
    {
      icon: <img className={styles.icon} src={expertsIcon} alt={''} />,
      title: t_login('experts'),
    },

    {
      title: '',
      icon: <></>,
    },
  ]

  return (
    <Layout>
      <Header
        style={{
          marginBottom: '15px',
          position: 'fixed',
          top: 0,
          zIndex: 2,
          width: '100%',
          height: '80px',
          justifyContent: 'space-between',
          display: 'flex',
          boxShadow:
            '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1)',
        }}
      >
        {breakpoint.xs ? (
          <>
            <PasswordLoginModal />
            <SelectLanguage className={styles.select} />
          </>
        ) : (
          <>
            <LogoStartupByIndo className={styles.smallLogo} />
            <div>
              {!breakpoint.lg && <PasswordLoginModal />}
              <SelectLanguage className={styles.select} />
            </div>
          </>
        )}
      </Header>
      <Row className={styles.container}>
        <Col md={24} lg={16} className={styles.coverImage}>
          <div className={styles.titleContainer}>
            <LogoStartupByIndoLight className={styles.logo} />
            <div className={styles.title}>{title}</div>
          </div>
        </Col>
        {breakpoint.lg && (
          <div>
            <div className={styles.loginModal}>{children}</div>
          </div>
        )}
      </Row>
      <Row className={styles.contactUsRow}>
        <Col sm={24} md={12} className={styles.contactUsContainer}>
          <p className={styles.contactUsText}>
            {t_login('landing_page_client_text')}
          </p>
          <MainButton
            onClick={() => {
              pianoAnalytics.clickContactUs()
              window.open('https://ca-indosuez.com/', '_blank')
            }}
          >
            {t_login('contact_us')}
          </MainButton>
        </Col>
        <Col xs={0} sm={0} md={12}>
          <img
            src={OfficeImage}
            alt={'office'}
            style={{ maxHeight: '600px' }}
          />
        </Col>
      </Row>
      <Row className={styles.presentationContainer} gutter={15}>
        <Col md={24} lg={8}>
          <Row gutter={10} className={styles.presentationRow}>
            <Col
              xs={4}
              sm={3}
              md={2}
              lg={6}
              xl={4}
              className={styles.centeredContent}
            >
              <EcosystemIcon />
            </Col>
            <Col
              xs={20}
              sm={21}
              md={22}
              lg={18}
              xl={20}
              className={styles.centeredContent}
            >
              <span className={styles.categoryTitle}>
                {t_login('unique_ecosystem')}
              </span>
            </Col>
          </Row>
          <Divider />
          <Row>
            <p
              dangerouslySetInnerHTML={{
                __html: t_login('unique_ecosystem_description'),
              }}
            />
          </Row>
        </Col>
        <Col md={24} lg={8}>
          <Row className={styles.presentationRow} gutter={10}>
            <Col
              xs={4}
              sm={3}
              md={2}
              lg={6}
              xl={4}
              className={styles.centeredContent}
            >
              <TechnoIcon />
            </Col>
            <Col
              xs={20}
              sm={21}
              md={22}
              lg={18}
              xl={20}
              className={styles.centeredContent}
            >
              <span className={styles.categoryTitle}>
                {t_login('tech_and_sector_watch')}
              </span>
            </Col>
          </Row>
          <Divider />
          <Row>
            <p
              dangerouslySetInnerHTML={{
                __html: t_login('tech_and_sector_watch_description'),
              }}
            />
          </Row>
        </Col>
        <Col md={24} lg={8}>
          <Row className={styles.presentationRow} gutter={10}>
            <Col
              xs={4}
              sm={3}
              md={2}
              lg={6}
              xl={4}
              className={styles.centeredContent}
            >
              <MeetingIcon />
            </Col>
            <Col
              xs={20}
              sm={21}
              md={22}
              lg={18}
              xl={20}
              className={styles.centeredContent}
            >
              <span className={styles.categoryTitle}>
                {t_login('story_of_meetings')}
              </span>
            </Col>
          </Row>
          <Divider />
          <Row>
            <p
              dangerouslySetInnerHTML={{
                __html: t_login('story_of_meetings_description'),
              }}
            />
          </Row>
        </Col>
      </Row>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={6}
          className={styles.keyNumber1Container}
        >
          <Row className={styles.keyNumber1}> 500+</Row>
          <Row>{t_login('referenced_startups')}</Row>
        </Col>
        <Col xs={24} sm={24} md={12} lg={6}>
          <Row className={styles.keyNumber2Container}>
            <span className={styles.keyNumber2}> 10+</span>{' '}
            {t_login('dedicated_events')}
          </Row>
          <Row className={styles.keyNumber3Container}>
            <span className={styles.keyNumber3}> 3</span>
            {t_login('targeted_countries')}
          </Row>
        </Col>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={12}
          className={styles.keyNumber4Container}
        >
          <Row className={styles.keyNumber4}> 700+</Row>
          <Row>{t_login('clients_in_community')}</Row>
        </Col>
      </Row>
      <Row>
        <p className={styles.details}>
          * {t_login('since')} 2018, ** France, {t_login('italy')}, Luxembourg
        </p>
      </Row>

      <div className={styles.dispositivePresentation}>
        <Row className={styles.subtitle}>
          <p>{t_login('simple_approach')}</p>
        </Row>
        <Row>
          <Steps
            direction={breakpoint.md ? 'horizontal' : 'vertical'}
            labelPlacement={'vertical'}
          >
            {stepsProps().map((stepProps) => (
              <Steps.Step {...stepProps} />
            ))}
          </Steps>
        </Row>
      </div>

      <Row className={styles.contactUsRow}>
        <Col sm={24} md={12} className={styles.contactUsContainer}>
          <p className={styles.subtitle}>{t_login('you_are_a_startup')}</p>
          <p className={styles.contactUsText}>
            {t_login('dispositive_presentation')}
          </p>
          <MainButton
            onClick={() => {
              pianoAnalytics.clickContactVillageCA()
              window.open('https://levillagebyca.com', '_blank')
            }}
          >
            {t_login('contact_village')}
          </MainButton>
        </Col>
        <Col sm={24} md={12} style={{ textAlign: 'right' }}>
          <img
            src={AppScreenshot}
            alt={'app_screenshot'}
            style={{ maxHeight: '600px', maxWidth: '100%' }}
          />
        </Col>
      </Row>

      {showFooter ? (
        <Row justify={'center'} className={styles.footer}>
          {t_login('with')}{' '}
          <a href="https://levillagebyca.com/" onClick={() => pianoAnalytics.clickWithVillageCA()}>
            <img
              className={styles.footerLogo}
              src="/images/village-by-ca.svg"
              alt="village-by-ca-logo"
            />
          </a>
        </Row>
      ) : null}
      <Footer showCGU={false} />
    </Layout>
  )
}

export default LayoutLogin
