import { Grid } from 'antd'
import { useEffect, useState } from 'react'

interface Config<T> {
  xs: T
  sm?: T
  md?: T
  lg?: T
  xl?: T
  xxl?: T
}

function useBreakpointValue<T>({
  xs,
  sm = xs,
  md = sm,
  lg = md,
  xl = lg,
  xxl = xl,
}: Config<T>): T {
  const breakPoint = Grid.useBreakpoint()
  const [value, setValue] = useState<T>(xs)

  useEffect((): void => {
    if (breakPoint.xxl) {
      setValue(xxl)
    } else if (breakPoint.xl) {
      setValue(xl)
    } else if (breakPoint.lg) {
      setValue(lg)
    } else if (breakPoint.md) {
      setValue(md)
    } else if (breakPoint.sm) {
      setValue(sm)
    } else {
      setValue(xs)
    }
  }, [breakPoint, xs, sm, md, lg, xl, xxl])

  return value
}

export default useBreakpointValue
