import { Checkbox, Col, Divider, Form, Input, Row, Select } from 'antd'
import { Rule } from 'rc-field-form/lib/interface'
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../../../../components/Form/OptionWithCount.model'
import UploadFile from '../../../../components/Form/Upload/UploadFile'
import SectorEnum, { SectorHelper } from '../../../../domain/sector.enum'
import { StartupModel } from '../../../../domain/startup.model'
import usePermission from '../../../../services/usePermission.hook'
import FormatHelper from '../../../../utils/FormatHelper'
import { Roles } from '../../../../utils/roles.enum'
import InputSwitch from '../../../../components/Form/InputSwitch'
import { useTranslation, TFunction } from 'react-i18next'
import ApiContext from '../../../../context/ApiContext'
import {
  EcosystemsModel,
  SubEcosystemsModel,
} from '../../../../domain/ecosystems.model'
import CountryEnum, { CountryHelper } from '../../../../domain/country.enum'
import RegionEnum, { RegionHelper } from '../../../../domain/region.enum'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import styles from './StartupForm.module.scss'
import { FilePreview } from '../../../../domain/filePreview.model'
import { getApiHost } from '../../../../conf'
import { genUUID } from '../../../../utils/UuidHelper'

interface Props {
  startup: StartupModel
  updateLogoToAdd: (data: FilePreview | undefined) => void
  prefix?: keyof StartupModel
  isInno?: boolean
  form: FormInstance
}

const sectorsConfig = (t: TFunction): OptionWithCountModel<SectorEnum>[] =>
  OptionWithCountHelper.buildFrom(
    SectorHelper.allSectors(),
    t,
    SectorHelper.format,
  )
const countriesConfig = (t: TFunction): OptionWithCountModel<CountryEnum>[] =>
  OptionWithCountHelper.buildFrom(CountryHelper.allCountries(), t)
const regionsConfig = (t: TFunction): OptionWithCountModel<RegionEnum>[] =>
  OptionWithCountHelper.buildFrom(RegionHelper.allRegions(), t)

const httpsRules = (t: TFunction): Rule[] => [
  {
    pattern: /^https:\/\/.*/,
    message: t('urlMustBeginWith'),
  },
  {
    type: 'url',
    message: t('urlMustMatchThePattern'),
  },
]

const InfosStartupForm: FC<Props> = ({
  startup,
  updateLogoToAdd,
  prefix,
  form,
}: Props) => {
  const { getEcosystems } = useContext(ApiContext)
  const isInno = usePermission(Roles.INNO)

  const [ecosystemsList, setEcosystemsList] = useState<EcosystemsModel[]>()
  const [startupEcosystem, setStartupEcosystem] = useState<EcosystemsModel>()
  const [country, setCountry] = useState<CountryEnum>(
    startup.informations.country,
  )
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [file, setFile] = useState<FilePreview>()

  const handleEcosystemStartupChange = (value: string) => {
    form.setFieldValue(
      FormatHelper.formItemNameFormatter(prefix, 'subEcosystem'),
      '',
    )
    if (ecosystemsList) {
      const e = ecosystemsList.find((e) => e.id === value)
      setStartupEcosystem(e)
    }
  }

  const onLogoUpload = (f: File | undefined) => {
    if (f) {
      let preview = window.URL.createObjectURL(new Blob([f]))
      let imageId = genUUID()
      let filePreview = { file: f, preview: preview, id: imageId }
      setFile(filePreview)
      updateLogoToAdd(filePreview)
    }
  }

  useEffect((): void => {
    getEcosystems().then(setEcosystemsList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect((): void => {
    if (ecosystemsList) {
      const e = ecosystemsList.find(
        (e) => e.id === startup.informations.ecosystemId,
      )
      setStartupEcosystem(e)
    }
  }, [ecosystemsList, startup])

  const { t: t_startup } = useTranslation('startup')
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')
  const { t: t_input } = useTranslation('input')

  useEffect((): void => {
    if (startup.informations.logoId) {
      setFile({
        file: new File([''], `logo${startup.informations.logoId}`),
        preview: `${getApiHost()}/asset-files/${startup.informations.logoId}`,
        id: startup.informations.logoId,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startup.informations.logoId])

  return (
    <div className={'startup-form'}>
      {isInno ? (
        <Row>
          <Col>
            <Form.Item
              label={t_startup('documentReceived')}
              name={FormatHelper.formItemNameFormatter(
                prefix,
                'hasLettreEmetteur',
              )}
              valuePropName="checked"
            >
              <Checkbox value="checked">
                {t_startup('transmitterLetter')}
              </Checkbox>
            </Form.Item>
          </Col>
          <Col offset={1}>
            <Form.Item
              label=" "
              name={FormatHelper.formItemNameFormatter(prefix, 'hasFicheOFAC')}
              valuePropName="checked"
            >
              <Checkbox>{t_startup('ofacCard')}</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col xs={24} md={12} className={styles.companyNameContainer}>
          <Form.Item
            label={t_startup('society')}
            name={FormatHelper.formItemNameFormatter(prefix, 'name')}
            rules={[{ required: true, message: t_common('requiredField') }]}
          >
            <Input placeholder={'Ex : Koko'} size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} md={{ span: 11, offset: 1 }}>
          <Form.Item className={styles.uploadLogoContainer}>
            <UploadFile
              filePreview={file}
              onFileUpload={(data: File | undefined): void => {
                setErrorMessage('')
                onLogoUpload(data)
              }}
              authorizedExtensions={['png', 'jpg', 'jpeg', 'svg']}
              onUploadFail={(): void => {
                setErrorMessage(t_startup('input.pictures.error'))
              }}
            />
            <p style={{ color: '#953d51' }}>{errorMessage}</p>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item
        label={t_startup('input.sector.label_filter')}
        name={FormatHelper.formItemNameFormatter(prefix, 'sector')}
        rules={[{ required: true, message: t_common('requiredField') }]}
      >
        <Select
          options={sectorsConfig(t_domain)}
          notFoundContent={t_startup('input.sector.notFound')}
        />
      </Form.Item>

      {ecosystemsList ? (
        <Form.Item
          label={t_startup('input.ecosystem.label')}
          name={FormatHelper.formItemNameFormatter(prefix, 'ecosystemId')}
          rules={[{ required: true, message: t_common('requiredField') }]}
        >
          <Select
            showSearch
            size="large"
            notFoundContent={t_startup('input.ecosystem.notFound')}
            onChange={handleEcosystemStartupChange}
          >
            {ecosystemsList.map(
              (ecosystem: EcosystemsModel, index: number): ReactNode => (
                <option key={index} value={ecosystem.id}>
                  {ecosystem.name}
                </option>
              ),
            )}
          </Select>
        </Form.Item>
      ) : null}

      {startupEcosystem != null && startupEcosystem.subEcosystems != null ? (
        <Form.Item
          label={t_startup('input.subEcosystem.label')}
          name={FormatHelper.formItemNameFormatter(prefix, 'subEcosystem')}
        >
          <Select
            showSearch
            size="large"
            notFoundContent={t_startup('input.subEcosystem.notFound')}
          >
            {startupEcosystem.subEcosystems.map(
              (subEcosystem: SubEcosystemsModel, index: number): ReactNode => (
                <option key={index} value={subEcosystem.name}>
                  {subEcosystem.name}
                </option>
              ),
            )}
          </Select>
        </Form.Item>
      ) : null}

      <Form.Item
        label={t_startup('input.country.label')}
        name={FormatHelper.formItemNameFormatter(prefix, 'country')}
        rules={[{ required: true, message: t_common('requiredField') }]}
      >
        <Select
          options={countriesConfig(t_domain)}
          showSearch
          onChange={(country: CountryEnum) => setCountry(country)}
          size="large"
          notFoundContent={t_startup('input.country.notFound')}
        />
      </Form.Item>

      {country === CountryEnum.FRANCE ? (
        <>
          <Form.Item
            label={t_startup('input.region.label')}
            name={FormatHelper.formItemNameFormatter(prefix, 'region')}
            rules={[{ required: true, message: t_common('requiredField') }]}
          >
            <Select
              options={regionsConfig(t_domain)}
              showSearch
              size="large"
              notFoundContent={t_startup('input.region.notFound')}
            />
          </Form.Item>
        </>
      ) : null}

      <Divider className={styles.formDivider} />

      <h1>{t_input('links')}</h1>
      <Form.Item
        label={
          <>
            {t_startup('input.video.label')}
            <small>{t_startup('input.video.additionalInformation')}</small>
          </>
        }
        name={FormatHelper.formItemNameFormatter(prefix, 'video')}
        rules={httpsRules(t_startup)}
      >
        <Input placeholder={'https://www.site.com'} size="large" />
      </Form.Item>

      <Form.Item
        label={
          <>
            {t_startup('input.website.label')}
            <small>{t_common('optional')}</small>
          </>
        }
        name={FormatHelper.formItemNameFormatter(prefix, 'website')}
        rules={httpsRules(t_startup)}
      >
        <Input placeholder={'https://www.site.com'} size="large" />
      </Form.Item>

      <Form.Item
        label={
          <>
            {t_startup('input.linkedin.label')}
            <small>{t_common('optional')}</small>
          </>
        }
        name={FormatHelper.formItemNameFormatter(prefix, 'linkedin')}
      >
        <Input placeholder={'https://www.linkedin.com/in/'} size="large" />
      </Form.Item>
      <Divider className={styles.formDivider} />

      <h1>{t_input('labels')}</h1>
      <Row>
        <Col sm={24} md={12} lg={10}>
          <Form.Item
            name={FormatHelper.formItemNameFormatter(prefix, 'businessWoman')}
            noStyle
          >
            <InputSwitch label={t_startup('input.businessWoman.label')} />
          </Form.Item>
        </Col>
        <Col sm={24} md={12} lg={14}>
          <Form.Item
            name={FormatHelper.formItemNameFormatter(
              prefix,
              'environmentalImpact',
            )}
            noStyle
          >
            <InputSwitch label={t_startup('input.environmentalImpact.label')} />
          </Form.Item>
        </Col>
      </Row>
      <Divider className={styles.formDivider} />
      <br />
    </div>
  )
}

export default InfosStartupForm
