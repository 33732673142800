import { Button, DatePicker, Drawer, Form, Input, Space } from 'antd'
import moment from 'moment'
import React, { FC, useContext } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import ClientAutocomplete from '../../components/Form/ClientAutocomplete'
import RadioBoxGroup from '../../components/Form/InputCheckBox/RadioBoxGroup'
import { OptionWithCountHelper } from '../../components/Form/OptionWithCount.model'
import StartupAutocomplete from '../../components/Form/StartupAutocomplete'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import PianoAnalyticsContext from '../../context/PianoAnalyticsContext'
import { EventDtoModel } from '../../domain/event/EventDtoModel'
import {
  EventStatusEnum,
  EventStatusHelper,
} from '../../domain/event/EventStatus.enum'
import { EventTypeEnum } from '../../domain/event/EventType.enum'
import { CreateEventReq } from '../../services/Api'
import useBreakpointValue from '../../utils/useBreakpointValue.hook'

interface Props {
  onCreate: (e: EventDtoModel) => void
  onClose: () => void
  visible: boolean
}

const statusConfig = (t: TFunction) =>
  OptionWithCountHelper.buildFrom(
    EventStatusHelper.values(),
    t,
    EventStatusHelper.format,
  )

const CreateEventDrawer: FC<Props> = ({
  visible,
  onCreate,
  onClose,
}: Props) => {
  const { createEvent, successFormBehaviour } = useContext(ApiContext)
  const { t: t_event } = useTranslation('event')
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')
  const pianoAnalytics = useContext(PianoAnalyticsContext)
  const drawerWidth = useBreakpointValue<string>({
    xs: '100%',
    md: '80%',
    lg: '65%',
    xl: '50%',
  })

  const onSave = () => {
    form.validateFields().then((event) =>
      createEvent({
        ...(event as CreateEventReq),
        type: EventTypeEnum.LINKING,
      })
        .then(onCreate)
        .then(() => pianoAnalytics.createLinking(event.status))
        .then(() => successFormBehaviour(t_event('eventCreated')))
        .then(resetAndClose),
    )
  }
  const resetAndClose = () => {
    form.resetFields()
    onClose()
  }

  const [form] = Form.useForm()

  return (
    <Drawer
      title={t_event('addNewEvent')}
      width={drawerWidth}
      onClose={resetAndClose}
      visible={visible}
      footer={
        <Space direction="horizontal">
          <MainButton onClick={onSave}>{t_common('add')}</MainButton>
          <Button onClick={resetAndClose}>{t_common('cancel')}</Button>
        </Space>
      }
    >
      <Form
        form={form}
        layout="vertical"
        requiredMark={true}
        initialValues={{
          status: EventStatusEnum.OPEN,
          date: moment.utc(),
        }}
      >
        <Form.Item
          label={t_common('client')}
          name="clientId"
          rules={[{ required: true, message: t_common('requiredField') }]}
        >
          <ClientAutocomplete />
        </Form.Item>
        <Form.Item
          label={t_common('startup')}
          name="startupId"
          rules={[{ required: true, message: t_common('requiredField') }]}
        >
          <StartupAutocomplete />
        </Form.Item>
        <Form.Item
          label={t_common('requestDate')}
          name="date"
          rules={[{ required: true, message: t_common('requiredField') }]}
        >
          <DatePicker size="large" />
        </Form.Item>
        {/* TODO add event type when more available*/}
        <Form.Item label={t_common('status')} name="status">
          <RadioBoxGroup options={statusConfig(t_domain)} />
        </Form.Item>
        <Form.Item label={t_common('comment')} name="comment">
          <Input.TextArea name="comment" rows={6} />
        </Form.Item>
      </Form>
    </Drawer>
  )
}

export default CreateEventDrawer
