class TableHelper {
  static getField = <T,>(value: T, field: keyof T): string =>
    value == null ? '' : value[field] == null ? '' : value[field] + ''

  static stringCompare =
    <T,>(field: keyof T) =>
    (a: T, b: T) => {
      let fieldA = TableHelper.getField(a, field)
      let fieldB = TableHelper.getField(b, field)
      return fieldA.localeCompare(fieldB) || 0
    }
}

export default TableHelper
