import React, { FC, useEffect, useState } from 'react'
import { StartUpActivityModel } from '../../domain/startupActivity.model'
import { Table } from 'antd'
import { useTranslation } from 'react-i18next'
import { ExtendedColumnsType } from '../../services/extendedColumnType'
import styles from './ClientActivity.module.scss'
import CountryIcon from '../../components/CountryIcon'
import FormatHelper from '../../utils/FormatHelper'
import {
  EventStatusEnum,
  EventStatusHelper,
} from '../../domain/event/EventStatus.enum'
import inProgress from '../../styles/svg/inProgress.svg'
import checkIcon from '../../styles/svg/checkIcon.svg'
import notificationIcon from '../../styles/svg/notificationIcon.svg'
import { getApiHost } from '../../conf'

interface Props {
  viewedStartUps: StartUpActivityModel[]
}

export const ViewedStartupsDesktop: FC<Props> = ({
  viewedStartUps,
}: Props): JSX.Element => {
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')
  const { t: t_client, i18n } = useTranslation('client')
  const { t: t_startup } = useTranslation('startup')

  const [columnConfig, setColumnConfig] =
    useState<ExtendedColumnsType<StartUpActivityModel>>()

  const showRequestContactStatus = (
    activity: StartUpActivityModel,
  ): JSX.Element => {
    const status = activity.event !== null ? activity.event.status : undefined
    return status !== undefined ? (
      <div
        style={{ backgroundColor: EventStatusHelper.color(status) }}
        className={styles.badgeEventStatus}
      >
        {EventStatusHelper.format(status, t_domain)}
        <img
          alt={''}
          className={styles.badgeIcon}
          src={
            status === EventStatusEnum.OPEN
              ? notificationIcon
              : status === EventStatusEnum.WIP
                ? inProgress
                : checkIcon
          }
        />
      </div>
    ) : (
      <></>
    )
  }

  useEffect((): void => {
    const config: ExtendedColumnsType<StartUpActivityModel> = [
      {
        width: 120,
        render: (_, activity: StartUpActivityModel) =>
          activity.startup.informations.logoId != null ? (
            <img
              className={styles.logoDesktop}
              alt={'Logo'}
              src={`${getApiHost()}/asset-files/${
                activity.startup.informations.logoId
              }`}
            />
          ) : (
            <></>
          ),
      },
      {
        width: 90,
        render: (_, activity: StartUpActivityModel) =>
          activity.startup.informations.country != null ? (
            <CountryIcon
              country={activity.startup.informations.country}
              className={styles.centeredContent}
              size={30}
            />
          ) : (
            <></>
          ),
      },
      {
        title: t_startup('society'),
        key: 'name',
        dataIndex: ['startup', 'informations', 'name'],
        className: styles.startupName,
        sorter: (a: StartUpActivityModel, b: StartUpActivityModel) =>
          Intl.Collator().compare(
            a.startup.informations.name,
            b.startup.informations.name,
          ),
      },
      {
        title: t_client('activity.lastSeen'),
        key: 'date',
        width: 120,
        render: (_, activity: StartUpActivityModel) =>
          activity.date ? FormatHelper.formatDate(activity.date, 'fr') : '',
        sorter: (a: StartUpActivityModel, b: StartUpActivityModel) =>
          FormatHelper.comparePossiblyNullNumber(a.date, b.date),
        exportFormat: (activity: StartUpActivityModel) =>
          activity.date ? FormatHelper.formatDate(activity.date, 'fr') : '',
      },
      {
        title: t_startup('requestContact'),
        width: 160,
        render: (_, activity: StartUpActivityModel) =>
          showRequestContactStatus(activity),
        sorter: (a: StartUpActivityModel, b: StartUpActivityModel) =>
          Intl.Collator().compare(a.event.status, b.event.status),
        filters: EventStatusHelper.values().map((s: EventStatusEnum) => ({
          text: s.valueOf(),
          value: s,
        })),
        onFilter: (value, record: StartUpActivityModel) => {
          return record.event.status === value
        },
      },
    ]
    setColumnConfig(config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  return (
    <div className={styles.startupActivityContainer}>
      <div className={styles.categoryTitle}>
        {t_client('activity.viewedStartUps')}
      </div>
      <br />
      <Table
        rowKey={(activity: StartUpActivityModel): string => activity.id}
        locale={{
          triggerDesc: t_common('triggerDesc'),
          triggerAsc: t_common('triggerAsc'),
          cancelSort: t_common('cancelSort'),
        }}
        columns={columnConfig}
        dataSource={viewedStartUps}
        scroll={{ x: true }}
      />
    </div>
  )
}
