import React, { FC } from 'react'
import styles from '../Startup/All/DisplayClientActivity.module.scss'
import { useTranslation } from 'react-i18next'
import { Col, Row } from 'antd'
import {
  EventStatusEnum,
  EventStatusHelper,
} from '../../domain/event/EventStatus.enum'
import inProgress from '../../styles/svg/inProgress.svg'

interface Props {
  nbEvents?: number
  textSingular: string
  textPlural: string
  eventStatus: EventStatusEnum
}

export const ConnectionRequestBadge: FC<Props> = ({
  nbEvents,
  textSingular,
  textPlural,
  eventStatus,
}: Props): JSX.Element => {
  const { t } = useTranslation('client')

  return (
    <Row gutter={15} className={styles.marginBetweenBadges}>
      <Col
        style={{
          backgroundColor: EventStatusHelper.color(eventStatus),
        }}
        className={styles.badgeEventStatus}
      >
        <img alt={''} src={inProgress} />
      </Col>
      <Col className={styles.connectionRequestsText}>
        {nbEvents && nbEvents > 0 ? (
          <>
            {nbEvents > 1 ? (
              <>
                <span className={styles.numberOfConnectionRequests}>
                  {nbEvents}
                </span>{' '}
                {textPlural}
              </>
            ) : (
              <>
                <span className={styles.numberOfConnectionRequests}>
                  {nbEvents}
                </span>{' '}
                {textSingular}
              </>
            )}
          </>
        ) : (
          <>
            {t('activity.requests.no')} {textSingular}
          </>
        )}
      </Col>
    </Row>
  )
}
