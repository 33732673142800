import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { StartupMaturitiesHelper } from '../domain/startup-maturities.enum'
import { Offer, StartupModel } from '../domain/startup.model'
import FormHelper from './FormHelper'

const dateFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour12: false,
}

const monthFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: undefined,
  hour12: false,
}

const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
  second: 'numeric',
  hour12: false,
}

const reverse = (str: string) => str.split('').reverse().join('')

class FormatHelper {
  public static currencyFormatter = (
    value: number | string | undefined,
    info = {},
    compact: boolean = false,
    showCurrency: boolean = true,
  ): string => {
    let config: Intl.NumberFormatOptions = {
      minimumFractionDigits: 0,
    }
    if (showCurrency) {
      config.style = 'currency'
      config.currency = 'EUR'
    }
    if (compact) {
      config.notation = 'compact'
    }
    return new Intl.NumberFormat(undefined, config).format(value as number)
  }

  public static phoneNumberFormatter = (value = '') => {
    let regex = new RegExp('.{1,2}', 'g')
    let result = reverse(value).match(regex) || []
    return reverse(result.join(' '))
  }

  public static formatDate = (
    date: number,
    language: string = 'en',
  ): string => {
    const locale = language === 'fr' ? 'fr-FR' : 'en-EN'
    return Intl.DateTimeFormat(locale, dateFormatOptions).format(date)
  }

  public static formatTimestamp = (
    date: string,
    language: string = 'en',
  ): string => {
    const timestamp = new Date(date).valueOf()
    const locale = language === 'fr' ? 'fr-FR' : 'en-EN'
    return Intl.DateTimeFormat(locale, dateFormatOptions).format(timestamp)
  }

  public static formatTimestampInMonthYear = (date: string): string => {
    const timestamp = new Date(date).valueOf()
    // using fr-FR to have to '0' before month numbers < 10
    return Intl.DateTimeFormat('fr-FR', monthFormatOptions).format(timestamp)
  }

  public static formatDateTime = (date: number): string =>
    Intl.DateTimeFormat(undefined, dateTimeFormatOptions).format(date)

  public static formatDateString = (date: Date | null): string | null => {
    if (date === null) return null
    return date.toISOString().split('T')[0]
  }

  public static formatTimestampInDateString = (timestamp: number): string => {
    const date = new Date(timestamp)
    return date.toISOString().split('T')[0]
  }

  public static formatStringToDate = (
    stringDate: string,
    language: string = 'en',
  ): string => {
    const date = new Date(stringDate)

    const locale = language === 'fr' ? 'fr-FR' : 'en-EN'
    return Intl.DateTimeFormat(locale, dateFormatOptions).format(date)
  }

  public static formItemNameFormatter = (
    prefix: keyof StartupModel | undefined,
    ...name: string[]
  ): any => {
    return prefix == null ? name : [prefix, ...name]
  }

  public static joinList = <T,>(
    values: T[],
    format: (t: T) => ReactNode = (t) => '' + t,
  ): ReactNode => {
    let formattedValues = (values || []).map(format)
    if (formattedValues.length === 1) {
      return formattedValues[0]
    }
    if (formattedValues.length > 1) {
      return formattedValues.map(
        (content: ReactNode, index: number): ReactNode => (
          <React.Fragment key={index}>
            {content}
            {index === values.length - 2
              ? ' et '
              : index < values.length - 1
                ? ', '
                : null}
          </React.Fragment>
        ),
      )
    }
    return ''
  }

  public static startupPresentation = (
    name: string,
    offer: Offer,
  ): ReactNode => {
    const { t } = useTranslation('domain')
    let markets = FormatHelper.joinList(offer.market, (m) => (
      <strong>{m}</strong>
    ))
    let maturity = StartupMaturitiesHelper.formatForDescription(
      offer.maturity,
      t,
    )
    return (
      <>
        {name} {t('addressesToMarket')} {markets}, {t('andIsIn')}{' '}
        <strong>{maturity}</strong>
      </>
    )
  }

  public static formatFullName = (
    e: { firstName?: string; lastName?: string; email?: string },
    defaultFullName: string = 'Unknown user',
  ): string => {
    let fullName = `${e.firstName || ''} ${e.lastName || ''}`
    return FormHelper.isBlank(fullName)
      ? e.email || defaultFullName
      : fullName.trim()
  }

  public static comparePossiblyNullNumber = (
    a: number | undefined | null,
    b: number | undefined | null,
  ): number => {
    if (a) {
      if (b) {
        return a - b
      } else {
        return 1
      }
    } else if (b) {
      return -1
    } else {
      return 0
    }
  }
}

export default FormatHelper
