import React, { FC, ReactNode } from 'react'
import styles from './Bandeau.module.scss'

interface Props {
  title: ReactNode
  icon?: ReactNode
  type?: 'info' | 'success' | 'warning' | 'error'
}

const Bandeau: FC<Props> = ({ title, icon, type = 'info' }: Props) => {
  return (
    <div className={[styles.bandeau, styles[type]].join(' ')}>
      <span className={styles.bandeauIcon}>{icon}</span> {title}
    </div>
  )
}

export default Bandeau
