import { Select } from 'antd'
import styles from './Dashboard.module.scss'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  chart: string
  setChart: (newChartType: string) => void
}

export const ChartSelector: FC<Props> = ({
  chart,
  setChart,
}: Props): JSX.Element => {
  const { t: t_dashboard, i18n } = useTranslation('dashboard')

  const [options, setOptions] = useState<{ label: string; value: string }[]>([
    { label: t_dashboard('most_viewed_business_sectors'), value: 'top' },
    {
      label: t_dashboard('most_represented'),
      value: 'distribution',
    },
  ])

  useEffect((): void => {
    setOptions([
      { label: t_dashboard('most_viewed_business_sectors'), value: 'top' },
      {
        label: t_dashboard('most_represented'),
        value: 'distribution',
      },
    ])
  }, [t_dashboard, i18n.language])

  return (
    <div className={'search-bar-container'}>
      <label className={'search-label'}>{t_dashboard('display')}</label>
      <Select
        className={styles.marginBottom}
        defaultValue={options[0].value}
        value={chart}
        onClick={(e) => e.stopPropagation()}
        onChange={(value: string): void => setChart(value)}
        options={options}
      />
    </div>
  )
}
