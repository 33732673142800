import { MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons'
import { Skeleton, Tabs } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Section from '../../components/Layout/Section'
import SectionTabs from '../../components/Layout/SectionTabs'
import PrimaryTag from '../../components/Tag/PrimaryTag'
import ApiContext from '../../context/ApiContext'
import { ClientNeedHelper } from '../../domain/client/client-needs.enum'
import { ClientModel } from '../../domain/client/client.model'
import FormatHelper from '../../utils/FormatHelper'
import ModifyProfile, { UserData } from '../Users/ModifyProfile'
import ModifyClient from './ModifyClient'
import { ClientActivity } from './ClientActivity'

interface Props {
  id: string
}

const Client: FC<Props> = ({ id }: Props) => {
  const { getClient } = useContext(ApiContext)
  const [client, setClient] = useState<ClientModel>()
  const [userData, setUserData] = useState<UserData>()

  const { t: t_client } = useTranslation('client')
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')

  useEffect((): void => {
    loadClient(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  const loadClient = (id: string) => {
    setClient(undefined)
    getClient(id).then((c) => {
      setClient(c)
      setUserData({
        id: c.idUser,
        firstName: c.firstName,
        lastName: c.lastName,
        email: c.email,
        mobilePhone: c.mobilePhone,
      })
    })
  }

  if (client == null) {
    return (
      <Section>
        <Skeleton loading={true} />
      </Section>
    )
  }
  return (
    <>
      <Section>
        <h2>{FormatHelper.formatFullName(client)}</h2>
        <p>
          <a href={`mailto:${client.email}`}>
            <MailOutlined /> {client.email}
          </a>
          <br />
          {client.mobilePhone ? (
            <a href={`tel:${client.mobilePhone}`}>
              <PhoneOutlined />
              {client.mobilePhone.indicatif}{' '}
              {FormatHelper.phoneNumberFormatter(client.mobilePhone.number)}
            </a>
          ) : null}
        </p>
        {client.need?.map((n, index) => (
          <PrimaryTag key={index}>
            {ClientNeedHelper.format(n, t_domain)}
          </PrimaryTag>
        ))}
        <br />
        {client.banquier ? (
          <span style={{ color: '#746250' }}>
            <UserOutlined /> {t_common('banker')} : {client.banquier}
          </span>
        ) : null}
      </Section>
      <SectionTabs defaultActiveKey="1">
        <Tabs.TabPane tab={t_client('qualifyingCard')} key="1">
          <ModifyClient client={client} onSave={() => loadClient(id)} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={t_client('personalInformation')} key="2">
          <Section>
            <ModifyProfile profile={userData} onSave={() => loadClient(id)} />
          </Section>
        </Tabs.TabPane>
        <Tabs.TabPane tab={t_client('activity.tab')} key="3">
          <ClientActivity
            connectionHistory={client.connectionHistory}
            viewedStartUps={client.viewedStartUps}
          />
        </Tabs.TabPane>
      </SectionTabs>
    </>
  )
}

export default Client
