import { TFunction } from 'react-i18next'

enum ClientInvestmentTypeEnum {
  GOVERNANCE = 'GOVERNANCE',
  REPORTING = 'REPORTING',
}

export class ClientInvestmentTypeHelper {
  static format = (
    investments: ClientInvestmentTypeEnum,
    t: TFunction,
  ): string => {
    return t(`clientInvestismentType.${investments}`) || investments
  }

  static allInvestmentType = (): ClientInvestmentTypeEnum[] =>
    Object.values(ClientInvestmentTypeEnum)
}

export default ClientInvestmentTypeEnum
