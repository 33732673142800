import { useContext, useEffect, useState } from 'react'
import UserContext from '../context/UserContext'
import { AccessConfig, hasAccessFilter } from './hasPermission'

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useFilterAccess<E>(
  initialState: (E & AccessConfig)[],
  deps: any[] = [],
): (E & AccessConfig)[] {
  const { user } = useContext(UserContext)
  const [state, setState] = useState<(E & AccessConfig)[]>([])

  useEffect((): void => {
    setState(initialState.filter(hasAccessFilter(user.roles)))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.roles, ...deps])

  return state
}

export default useFilterAccess
