import { Layout } from 'antd'
import React from 'react'
import styles from './EmptyHeader.module.scss'
import { ReactComponent as LogoStartupByIndo } from '../../../styles/svg/LogoStartupConnections_byIndosuez.svg'
import SelectLanguage from '../../SelectLanguage'

export type HeaderJustify = 'center' | 'start'

interface Props {
  justify?: HeaderJustify
}

const EmptyHeader = ({ justify = 'start' }: Props) => (
  <Layout.Header className={styles.header} style={{ justifyContent: justify }}>
    <LogoStartupByIndo className={styles.logo} />
    <SelectLanguage className={styles.select} />
  </Layout.Header>
)

export default EmptyHeader
