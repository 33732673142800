import { Col, Form, Input, Row, Skeleton } from 'antd'
import React, { FC, useContext } from 'react'
import InputPhone from '../../components/Form/InputPhone'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import { UpdateAccountReq } from '../../services/Api'
import { phoneValidator } from '../../services/validators/phone.validators'
import { useTranslation } from 'react-i18next'

export type UserData = Partial<UpdateAccountReq> & {
  id: string
}

interface Props {
  profile: UserData | undefined
  title?: string
  onSave?: (data: UpdateAccountReq) => void
}

const ModifyProfile: FC<Props> = ({
  profile,
  title,
  onSave = () => {},
}: Props) => {
  const { successFormBehaviour, errorFormBehaviour, updateAccount } =
    useContext(ApiContext)
  const { t: t_input } = useTranslation('input')
  const { t: t_client } = useTranslation('client')

  const saveProfile = (data: UpdateAccountReq) => {
    profile &&
      updateAccount(profile.id, data)
        .then(() => onSave(data))
        .then(() => successFormBehaviour(t_client('profileSaved')))
        .catch((err): void => {
          if (!err.toString().includes('409')) {
            errorFormBehaviour(err)
          }
        })
  }

  if (profile == null) {
    return <Skeleton loading={true} />
  }
  return (
    <>
      {title ? <h2 className="mb-60">{title}</h2> : null}
      <Form
        layout="vertical"
        onFinish={saveProfile}
        requiredMark={true}
        initialValues={profile}
      >
        <Row justify="space-between">
          <Col xs={24} md={11}>
            <Form.Item
              name={'firstName'}
              label={t_input('firstName')}
              rules={[
                { required: true, message: t_input('errFirstNameRequired') },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              name={'lastName'}
              label={t_input('lastName')}
              rules={[
                { required: true, message: t_input('errLastNameRequired') },
              ]}
            >
              <Input size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name={'email'}
          label={t_input('email')}
          rules={[
            { required: true, message: t_input('errEmailRequired') },
            { type: 'email', message: t_input('errEmailNotValid') },
          ]}
        >
          <Input size="large" autoCapitalize="none" />
        </Form.Item>

        <Form.Item
          label={
            <>
              {t_input('phoneNumber')}
              <small>{t_input('optional')}</small>
            </>
          }
          name={'mobilePhone'}
          rules={[phoneValidator]}
        >
          <InputPhone required={false} />
        </Form.Item>

        <MainButton htmlType={'submit'}>{t_input('save')}</MainButton>
      </Form>
    </>
  )
}

export default ModifyProfile
