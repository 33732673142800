import { Button, Table } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { StartupModel } from '../../../domain/startup.model'
import { Link, useHistory } from 'react-router-dom'
import FormatHelper from '../../../utils/FormatHelper'
import StartupStatusEnum, {
  StartupStatusHelper,
} from '../../../domain/startup-status.enum'
import ApiContext from '../../../context/ApiContext'
import { EcosystemsModel } from '../../../domain/ecosystems.model'
import { XlsxHelper } from '../../../utils/XlsxHelper'
import MainButton from '../../../components/MainButton/MainButton'
import { ExtendedColumnsType } from '../../../services/extendedColumnType'
import styles from './StartupGrid.module.scss'
import SectorEnum, { SectorHelper } from '../../../domain/sector.enum'

interface Props {
  startUps: StartupModel[]
}

export const StartUpTable: FC<Props> = ({ startUps }: Props) => {
  const { getEcosystems } = useContext(ApiContext)
  const [ecosystems, setEcosystems] = useState<EcosystemsModel[]>()
  const { t: t_common, i18n } = useTranslation('common')
  const { t: t_startup } = useTranslation('startup')
  const { t: t_domain } = useTranslation('domain')
  const history = useHistory()
  const [columnConfig, setColumnConfig] =
    useState<ExtendedColumnsType<StartupModel>>()

  useEffect((): void => {
    getEcosystems().then((res: EcosystemsModel[]): void => {
      setEcosystems(res)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect((): void => {
    if (ecosystems !== undefined) {
      const config: ExtendedColumnsType<StartupModel> = [
        {
          title: t_startup('society'),
          key: 'name',
          dataIndex: ['informations', 'name'],
          sorter: (a: StartupModel, b: StartupModel) =>
            Intl.Collator().compare(a.informations.name, b.informations.name),
        },
        {
          title: t_startup('contact'),
          key: 'contactName',
          dataIndex: ['raise', 'contactName'],
          sorter: (a: StartupModel, b: StartupModel) =>
            Intl.Collator().compare(a.raise.contactName, b.raise.contactName),
          render: (_, startup) => (
            <div>
              <span>{startup.raise.contactName}</span>
              <br />
              <span>{startup.raise.email}</span>
            </div>
          ),
          exportFormat: (startup) => startup.raise.email,
        },
        {
          title: t_startup('status.label'),
          key: 'status',
          sorter: (a: StartupModel, b: StartupModel) =>
            Intl.Collator().compare(a.status, b.status),
          render: (_, startup) =>
            StartupStatusHelper.format(startup.status, t_domain),
          filters: StartupStatusHelper.values().map((e: StartupStatusEnum) => ({
            text: StartupStatusHelper.format(e, t_domain).valueOf(),
            value: StartupStatusHelper.format(e, t_domain),
          })),
          onFilter: (value, record: StartupModel) =>
            StartupStatusHelper.format(record.status, t_domain) === value,
          exportFormat: (startup: StartupModel) =>
            StartupStatusHelper.format(startup.status, t_domain),
        },
        {
          title: t_startup('status.validateDate'),
          key: 'validateDate',
          render: (_, startup: StartupModel) =>
            startup.validateDate
              ? FormatHelper.formatDate(startup.validateDate, 'fr')
              : '',
          sorter: (a: StartupModel, b: StartupModel) =>
            FormatHelper.comparePossiblyNullNumber(
              a.validateDate,
              b.validateDate,
            ),
          exportFormat: (startup: StartupModel) =>
            startup.validateDate
              ? FormatHelper.formatDate(startup.validateDate, 'fr')
              : '',
        },
        {
          title: t_startup('input.sector.label'),
          key: 'sector',
          dataIndex: ['informations', 'sector'],
          render: (_, startup: StartupModel) =>
            t_domain(`sectors.${startup.informations.sector}`),
          sorter: (a: StartupModel, b: StartupModel) =>
            Intl.Collator().compare(
              t_domain(`sectors.${a.informations.sector}`),
              t_domain(`sectors.${b.informations.sector}`),
            ),
          filters: SectorHelper.allSectors().map((s: SectorEnum) => ({
            text: SectorHelper.format(s, t_domain).valueOf(),
            value: SectorHelper.format(s, t_domain),
          })),
          onFilter: (value, record: StartupModel) =>
            SectorHelper.format(record.informations.sector, t_domain) === value,
          exportFormat: (startup: StartupModel) =>
            startup.informations.sector
              ? SectorHelper.format(startup.informations.sector, t_domain)
              : '',
        },
        {
          title: t_startup('input.ecosystem.label'),
          key: 'ecosystemId',
          render: (_, startup: StartupModel) =>
            ecosystems.find(
              (ecosystem) => ecosystem.id === startup.informations.ecosystemId,
            )?.name ?? '',
          sorter: (a: StartupModel, b: StartupModel) =>
            Intl.Collator().compare(
              a.informations.ecosystemId,
              b.informations.ecosystemId,
            ),
          filters: ecosystems.map((e: EcosystemsModel) => ({
            text: e.name,
            value: e.id,
          })),
          onFilter: (value, record: StartupModel) =>
            record.informations.ecosystemId === value,
          exportFormat: (startup: StartupModel) =>
            ecosystems.find(
              (ecosystem) => ecosystem.id === startup.informations.ecosystemId,
            )?.name ?? '',
        },
        {
          title: t_startup('input.expectedClosingDate.label'),
          key: 'dateRaise',
          render: (_, startup: StartupModel) =>
            startup.raise.dateRaise
              ? FormatHelper.formatTimestamp(startup.raise.dateRaise, 'fr')
              : '',
          sorter: (a: StartupModel, b: StartupModel) =>
            Intl.Collator(undefined, { numeric: true }).compare(
              a.raise.dateRaise,
              b.raise.dateRaise,
            ),
          exportFormat: (startup: StartupModel) =>
            startup.raise.dateRaise
              ? FormatHelper.formatTimestamp(startup.raise.dateRaise, 'fr')
              : '',
        },
        {
          render: (_, startup: StartupModel) => (
            <Link to={`/startups/${startup.id}`}>
              <Button size="small">{t_startup('see')}</Button>
            </Link>
          ),
        },
      ]
      setColumnConfig(config)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecosystems, i18n.language])

  return (
    <div>
      <MainButton
        className={styles.exportButton}
        onClick={(): void => {
          return (
            columnConfig &&
            XlsxHelper.generateFromReactTable<StartupModel>(
              startUps,
              columnConfig,
              'export_start_ups_' + FormatHelper.formatDate(Date.now()),
            )
          )
        }}
      >
        Export
      </MainButton>
      <Table
        className={'table-with-clickable-row'}
        rowKey={(startUp: StartupModel): string => startUp.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.stopPropagation()
              history.push(`/startups/${record.id}`)
            },
          }
        }}
        locale={{
          triggerDesc: t_common('triggerDesc'),
          triggerAsc: t_common('triggerAsc'),
          cancelSort: t_common('cancelSort'),
        }}
        columns={columnConfig}
        dataSource={startUps}
        scroll={{ x: true }}
      />
    </div>
  )
}
