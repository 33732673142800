import { TFunction } from 'react-i18next'

enum StartupStatusEnum {
  CREATE = 'CREATE',
  TO_VALIDATE = 'TO_VALIDATE',
  VALIDATED = 'VALIDATED',
  DISABLED = 'DISABLED',
}

const startupStatusColorMatcher = {
  [StartupStatusEnum.CREATE]: '#953D51',
  [StartupStatusEnum.TO_VALIDATE]: '#953D51',
  [StartupStatusEnum.VALIDATED]: '#6B8087',
  [StartupStatusEnum.DISABLED]: '#6B8087',
}

export class StartupStatusHelper {
  static format = (status: StartupStatusEnum, t: TFunction): string =>
    t(`startupStatus.format.${status}`) || status

  static color = (status: StartupStatusEnum): string =>
    startupStatusColorMatcher[status] || '#6B8087'

  static values = (): StartupStatusEnum[] => Object.values(StartupStatusEnum)
}

export default StartupStatusEnum
