import React, { FC } from 'react'
import PrimaryTag, { PrimaryTagProps } from '../Tag/PrimaryTag'
import RangeValueModel from './RangeValue.model'

type Props = PrimaryTagProps & {
  label: string
  value: RangeValueModel
}

const RangeTag: FC<Props> = ({ label, value, ...rest }: Props) => {
  return (
    <PrimaryTag key={`${label}-${value.start}-${value.end}`} {...rest}>
      {label} {value.getLabel()}
      {rest.children}
    </PrimaryTag>
  )
}

export default RangeTag
