import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ClientsWithMostConnectionsStatistics } from '../../domain/admin/dashboard.model'
import styles from './Dashboard.module.scss'
import { Col, Table } from 'antd'
import Loading from '../../components/Loading'
import { ExtendedColumnsType } from '../../services/extendedColumnType'
import { useHistory } from 'react-router-dom'

interface Props {
  mostConnectedClients?: ClientsWithMostConnectionsStatistics[]
}

export const MostConnectedClients: FC<Props> = ({
  mostConnectedClients,
}: Props) => {
  const { t: t_dashboard } = useTranslation('dashboard')
  const { t: t_common } = useTranslation('common')
  const history = useHistory()

  const config: ExtendedColumnsType<ClientsWithMostConnectionsStatistics> = [
    {
      title: t_common('firstName'),
      key: 'firstName',
      dataIndex: ['client', 'firstName'],
      sorter: (
        a: ClientsWithMostConnectionsStatistics,
        b: ClientsWithMostConnectionsStatistics,
      ) => Intl.Collator().compare(a.client.firstName, b.client.firstName),
    },
    {
      title: t_common('lastName'),
      key: 'lastName',
      dataIndex: ['client', 'lastName'],
      sorter: (
        a: ClientsWithMostConnectionsStatistics,
        b: ClientsWithMostConnectionsStatistics,
      ) => Intl.Collator().compare(a.client.lastName, b.client.lastName),
    },
    {
      title: t_common('banker'),
      key: 'banquier',
      dataIndex: ['client', 'banquier'],
      sorter: (
        a: ClientsWithMostConnectionsStatistics,
        b: ClientsWithMostConnectionsStatistics,
      ) => Intl.Collator().compare(a.client.banquier, b.client.banquier),
    },
    {
      title: t_dashboard('connections'),
      key: 'numberOfConnections',
      dataIndex: ['numberOfConnections'],
      className: styles.nbViews,
    },
  ]

  return (
    <Col md={24} lg={12} className={styles.whiteContainer}>
      <h3>{t_dashboard('most_active_clients')}</h3>
      {mostConnectedClients ? (
        <>
          {mostConnectedClients.length > 0 ? (
            <Table
              className={'table-with-clickable-row'}
              rowKey={(
                activity: ClientsWithMostConnectionsStatistics,
              ): string => activity.client.id}
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    event.stopPropagation()
                    history.push(`/clients/${record.client.id}`)
                  },
                }
              }}
              locale={{
                triggerDesc: t_common('triggerDesc'),
                triggerAsc: t_common('triggerAsc'),
                cancelSort: t_common('cancelSort'),
              }}
              columns={config}
              dataSource={mostConnectedClients}
              scroll={{ x: true }}
              pagination={false}
            />
          ) : (
            <p>{t_dashboard('not_available')}</p>
          )}
        </>
      ) : (
        <Loading />
      )}
    </Col>
  )
}
