import { useTranslation } from 'react-i18next'
import FormatHelper from '../../utils/FormatHelper'

export default class RangeValueModel {
  start: number
  end?: number

  constructor(start: number, end?: number) {
    this.start = start
    this.end = end
  }

  static of(start: number, end?: number): RangeValueModel {
    return new RangeValueModel(start, end)
  }

  getLabel = (): string => {
    const { t: t_input } = useTranslation('input')
    const { t: t_common } = useTranslation('common')
    return this.end != null
      ? this.start === 0
        ? `${t_input('lessOf')} ${this.formatNumber(this.end)}`
        : `${t_input('between')} ${this.formatNumber(this.start)} ${t_common(
            'and',
          )} ${this.formatNumber(this.end)}`
      : `${t_input('moreOf')} ${this.formatNumber(this.start)}`
  }

  contains = (value: number): boolean => {
    if (value == null) {
      return false
    }
    return this.end != null
      ? this.start <= value && value < this.end
      : this.start <= value
  }

  compareTo = (range: RangeValueModel) => {
    let diffStart = this.start - range.start
    if (diffStart === 0) {
      return (this.end || 0) - (range.end || 0)
    }
    return diffStart
  }

  private formatNumber = (
    number: number,
    showCurrency: boolean = true,
  ): string => FormatHelper.currencyFormatter(number, true, showCurrency)
}
