import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ConnectionRequestStatistics } from '../../domain/admin/dashboard.model'
import styles from './Dashboard.module.scss'
import { Col, Row } from 'antd'
import Loading from '../../components/Loading'
import { ConnectionRequestBadge } from '../Events/ConnectionRequestBadge'
import { EventStatusEnum } from '../../domain/event/EventStatus.enum'
import MainButton from '../../components/MainButton/MainButton'
import { useHistory } from 'react-router-dom'

interface Props {
  connectionRequests?: ConnectionRequestStatistics
}

export const ConnectionRequests: FC<Props> = ({
  connectionRequests,
}: Props) => {
  const { t: t_dashboard } = useTranslation('dashboard')
  const { t: t_client } = useTranslation('client')
  const history = useHistory()

  return (
    <>
      <Row className={styles.whiteContainer} justify={'space-between'}>
        <Col sm={24} md={11} lg={10}>
          <h3>{t_dashboard('connection_requests')}</h3>
        </Col>
        {connectionRequests ? (
          <Col
            sm={24}
            md={13}
            lg={14}
            className={styles.connectionEventsOverview}
          >
            <ConnectionRequestBadge
              textPlural={t_client('activity.requests.opened_plural')}
              textSingular={t_client('activity.requests.opened_singular')}
              eventStatus={EventStatusEnum.OPEN}
              nbEvents={connectionRequests.openEvents}
            />
            <ConnectionRequestBadge
              textPlural={t_client('activity.requests.in_progress_plural')}
              textSingular={t_client('activity.requests.in_progress_singular')}
              eventStatus={EventStatusEnum.WIP}
              nbEvents={connectionRequests.inProgressEvents}
            />
            <MainButton
              className={styles.seeAllButton}
              onClick={(): void => history.push('/events')}
            >
              Voir tout
            </MainButton>
          </Col>
        ) : (
          <Loading />
        )}
      </Row>
    </>
  )
}
