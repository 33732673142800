import React, { FC, useContext, useEffect, useState } from 'react'
import RadioBoxGroup from '../../components/Form/InputCheckBox/RadioBoxGroup'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../../components/Form/OptionWithCount.model'
import GridCard from '../../components/Layout/GridCard'
import Section from '../../components/Layout/Section'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import { EventDtoModel } from '../../domain/event/EventDtoModel'
import {
  EventStatusEnum,
  EventStatusHelper,
} from '../../domain/event/EventStatus.enum'
import { EventTypeEnum } from '../../domain/event/EventType.enum'
import usePaginated from '../../services/usePaginated.hook'
import useDebounce from '../../utils/useDebounce'
import styles from './DemandesContact.module.scss'
import CreateEventDrawer from './CreateEventDrawer'
import DemandeContactCard from './DemandeContactCard'
import DemandeContactCardLoading from './DemandeContactCardLoading'
import EditEventDrawer from './EditEventDrawer'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { SwitchGridTableView } from '../Shared/SwitchGridTableView'
import { ContactRequestTable } from './ContactRequestTable'
import { Input } from 'antd'
import FormHelper from '../../utils/FormHelper'

const statusConfig = (
  t: TFunction,
): OptionWithCountModel<EventStatusEnum | null>[] => [
  new OptionWithCountModel(null, t('allRequests')),
  ...OptionWithCountHelper.buildFrom(
    EventStatusHelper.values(),
    t,
    EventStatusHelper.format,
  ),
]

const DemandesContact: FC = () => {
  const { t: t_event } = useTranslation('event')
  const { t: t_domain } = useTranslation('domain')
  const { listEvents } = useContext(ApiContext)
  const [status, setStatus] = useState<EventStatusEnum | null>(
    EventStatusEnum.OPEN,
  )
  const [createVisible, setCreateVisible] = useState<boolean>(false)
  const [tableView, setTableView] = useState<boolean>(false)
  const [search, setSearch] = useState<string>()

  const loadData = (page?: number, pageSize?: number) =>
    listEvents(
      [EventTypeEnum.LINKING],
      status ? [status] : undefined,
      page,
      pageSize,
    )

  const {
    data: events,
    totalElements,
    loadMore,
    onSave,
    loading,
  } = usePaginated<EventDtoModel>(loadData, [status])
  const [selectedEvent, setSelectedEvent] = useState<EventDtoModel>()
  const debounceLoading = useDebounce(loading)
  const [filteredEvents, setFilteredEvents] = useState<EventDtoModel[]>([])

  useEffect((): void => {
    setSearch('')
    setFilteredEvents(events)
  }, [events, status])

  const filterEvents = (search: string): void => {
    if (FormHelper.isBlank(search)) {
      setFilteredEvents(events)
    } else {
      setFilteredEvents(
        events.filter((event) =>
          `${event.client ? event.client.fullName : ''} ${
            event.startup ? event.startup.fullName : ''
          }`
            .toLowerCase()
            .includes(search.toLowerCase()),
        ),
      )
    }
  }

  return (
    <>
      <Section theme={'dark'}>
        <div className={styles.TitleBloc}>
          <h2>{t_event('contactRequest')}</h2>
          <MainButton onClick={() => setCreateVisible(true)}>
            {t_event('addRequest')}
          </MainButton>
        </div>
        <div className={styles.TitleBloc}>
          <RadioBoxGroup
            value={status}
            options={statusConfig(t_domain)}
            onChange={setStatus}
          />
        </div>
        <div className={styles.marginTop}>
          <SwitchGridTableView tableView={tableView} onChange={setTableView} />
        </div>
      </Section>
      <CreateEventDrawer
        onCreate={onSave}
        onClose={() => setCreateVisible(false)}
        visible={createVisible}
      />
      {debounceLoading ? (
        <GridCard dataCards={[1, 2, 3]}>
          {() => <DemandeContactCardLoading />}
        </GridCard>
      ) : (
        <>
          <Section>
            <div className={'search-bar-container'}>
              <label className={'search-label'}>
                {t_event('search_among_events')}
              </label>
              <Input.Search
                placeholder={'ex: Nicolas Dupont'}
                allowClear
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  filterEvents(e.target.value)
                }}
                style={{ width: 300 }}
              />
            </div>
          </Section>
          {tableView ? (
            <ContactRequestTable
              events={filteredEvents}
              onSelectEvent={setSelectedEvent}
            />
          ) : (
            <GridCard
              dataCards={filteredEvents}
              total={totalElements}
              viewMore={loadMore}
              what={t_event('contactRequests')}
            >
              {(s) => (
                <DemandeContactCard
                  event={s}
                  onClick={() => setSelectedEvent(s)}
                />
              )}
            </GridCard>
          )}
          <EditEventDrawer
            event={selectedEvent}
            visible={selectedEvent != null}
            onUpdate={(e) => {
              onSave(e)
              setSelectedEvent(e)
            }}
            onClose={() => setSelectedEvent(undefined)}
          />
        </>
      )}
    </>
  )
}

export default DemandesContact
