import { Form, Input, Radio } from 'antd'
import React, { FC, ReactElement, useContext, useState } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { OptionWithCountHelper } from '../../components/Form/OptionWithCount.model'
import MailService, { Mail } from '../../components/Mail/mail.service'
import Mailer from '../../components/Mail/Mailer'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import PianoAnalyticsContext from '../../context/PianoAnalyticsContext'
import { PasswordToken } from '../../domain/password-token.model'
import { InviteReq } from '../../services/Api'
import { RolesHelper } from '../../utils/roles.enum'

const rolesConfig = (t: TFunction) =>
  OptionWithCountHelper.buildFrom(RolesHelper.allRoles(), t)

const InviteUser: FC = (): ReactElement => {
  const { inviteUser } = useContext(ApiContext)
  const [mail, setMail] = useState<Mail>()
  const pianoAnalytics = useContext(PianoAnalyticsContext)
  const { t } = useTranslation('domain')
  const { t: t_user } = useTranslation('users')

  const sendMail = (inviteData: InviteReq): void => {
    inviteUser(inviteData).then((token: PasswordToken) => {
      pianoAnalytics.createInviteLink(inviteData.role)
      setMail(
        MailService.buildInviteEmail(
          inviteData.email,
          token.token,
          token.expirationDate,
        ),
      )
    })
  }

  return (
    <>
      <Form
        requiredMark={true}
        layout="vertical"
        onFinish={sendMail}
        className={'user-invite-form'}
      >
        <Form.Item
          label="Email"
          name="email"
          rules={[
            { required: true, message: 'Please enter a valid email!' },
            { type: 'email', message: 'Please enter a valid email!' },
          ]}
        >
          <Input size="large" />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: 'Please select a role' }]}
        >
          <Radio.Group options={rolesConfig(t)} />
        </Form.Item>
        <Form.Item>
          <MainButton htmlType="submit">
            {t_user('generate_invite_link')}
          </MainButton>
        </Form.Item>
      </Form>
      <Mailer mail={mail} />
    </>
  )
}

export default InviteUser
