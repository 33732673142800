import { CloseOutlined, LogoutOutlined, MenuOutlined } from '@ant-design/icons'
import { Button, Divider, Layout, Space } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ReactComponent as LogoStartup } from '../../../styles/svg/LogoStartupConnections_byIndosuez.svg'
import QuickSearch from '../../QuickSearch'
import Section from '../Section'
import { HeaderProps, HeaderRoute } from './Header'

import styles from './HeaderMobile.module.scss'
import SelectLanguage from '../../SelectLanguage'
import { useTranslation } from 'react-i18next'

const HeaderMobile: FC<HeaderProps> = ({
  routes,
  userRoutes,
  showQuickSearch,
  userName,
  logout,
  home,
}: HeaderProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const location = useLocation()
  const { t } = useTranslation('common')
  // close header when clicking on link, or clicking on item in quickSearch
  useEffect((): void => setOpen(false), [location])

  const menu = () => (
    <Section theme="dark" className={styles.mask}>
      <Space direction="vertical" size="large" className={styles.maskBody}>
        {showQuickSearch ? <QuickSearch width="100%" /> : null}
        <SelectLanguage />
        {routes.map((route: HeaderRoute) => (
          <Link key={route.to} to={route.to} className={styles.link}>
            {route.label}
          </Link>
        ))}
        <Divider className={styles.divider} />
        <h3>{userName}</h3>
        {userRoutes.map((route: HeaderRoute) => (
          <Link key={route.to} to={route.to} className={styles.link}>
            {route.label}
          </Link>
        ))}
        <a href="#!" onClick={logout} className={styles.logout}>
          <LogoutOutlined />
          {t('navbar.signOut')}
        </a>
      </Space>
    </Section>
  )

  return (
    <>
      <Layout.Header
        className={[styles.header, open ? styles.headerOpen : ''].join(' ')}
      >
        <LogoStartup className={styles.logo} onClick={home} />
        <Button
          type="text"
          icon={open ? <CloseOutlined /> : <MenuOutlined />}
          size="small"
          style={{ width: '32px' }}
          onClick={() => setOpen(!open)}
        />
      </Layout.Header>
      {open ? menu() : null}
    </>
  )
}

export default HeaderMobile
