import { SelectProps } from 'antd/lib/select'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApiContext from '../../context/ApiContext'
import { StartupModel } from '../../domain/startup.model'
import InputSelect from './InputSelect/InputSelect'

const StartupAutocomplete: FC<SelectProps<any>> = (props) => {
  const { t } = useTranslation('input')

  const { getStartups } = useContext(ApiContext)
  const [startups, setStartups] = useState<StartupModel[]>([])

  useEffect((): void => {
    getStartups().then(setStartups)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <InputSelect
      {...props}
      options={startups}
      valueMapper={(v) => v.id}
      format={(v) => v.informations.name}
      notFoundContent={t('thisStartupIsNotInOurList')}
    />
  )
}

export default StartupAutocomplete
