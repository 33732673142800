import { Button } from 'antd'
import { ButtonProps } from 'antd/lib/button/button'
import React, { FC, useEffect, useState } from 'react'

const MainButton: FC<ButtonProps> = ({
  className,
  ...rest
}: ButtonProps): JSX.Element => {
  const [classNameList, setClassNameList] = useState<string>('')

  useEffect(() => {
    switch (rest.color) {
      case 'grey':
        setClassNameList([className || '', 'main-btn-grey'].join(' '))
        break
      case 'framboise':
        setClassNameList([className || '', 'main-btn-framboise'].join(' '))
        break
      default:
        setClassNameList([className || '', 'main-btn'].join(' '))
        break
    }
  }, [className, classNameList, rest.color])

  return <Button className={classNameList} {...rest} />
}

export default MainButton
