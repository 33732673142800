import {
  CheckOutlined,
  ScheduleOutlined,
  WarningOutlined,
} from '@ant-design/icons'
import { Button, Skeleton } from 'antd'
import { TFunction } from 'i18next'
import React, { FC, ReactNode, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Bandeau from '../../../components/Bandeau/Bandeau'
import ApiContext from '../../../context/ApiContext'
import StartupStatusEnum from '../../../domain/startup-status.enum'
import { StartupModel } from '../../../domain/startup.model'
import ShowStartup from './ShowStartup'

const MyStartup: FC = () => {
  const [startup, setStartup] = useState<StartupModel>()
  const { getMyStartup } = useContext(ApiContext)
  const { t } = useTranslation('startup')

  useEffect(() => {
    getMyStartup().then(setStartup)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (startup == null) {
    return <Skeleton />
  }

  return (
    <>
      {BandeauStatus(startup.status, t)}
      <ShowStartup startup={startup} />
    </>
  )
}

const BandeauStatus = (status: StartupStatusEnum, t: TFunction): ReactNode => {
  if (status === StartupStatusEnum.TO_VALIDATE) {
    return (
      <Bandeau title={t('status.toValidate')} icon={<ScheduleOutlined />} />
    )
  } else if (status === StartupStatusEnum.VALIDATED) {
    return (
      <Bandeau
        type="success"
        title={t('status.validate')}
        icon={<CheckOutlined />}
      />
    )
  } else if (status === StartupStatusEnum.CREATE) {
    return (
      <Bandeau
        type="error"
        title={
          <span>
            {t('status.create.youHaveNotFinalised')}{' '}
            <Link to="/startups/init">
              <Button size="small" href="/startups/init">
                {t('status.create.clickHer')}
              </Button>
            </Link>{' '}
            {t('status.create.toFillLastElement')}
          </span>
        }
        icon={<WarningOutlined />}
      />
    )
  }
  return null
}

export default MyStartup
