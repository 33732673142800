import { Col, Table } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StartupVisitedStatistics } from '../../domain/admin/dashboard.model'
import styles from './Dashboard.module.scss'
import Loading from '../../components/Loading'
import { useHistory } from 'react-router-dom'
import { ExtendedColumnsType } from '../../services/extendedColumnType'
import { getApiHost } from '../../conf'
import CountryIcon from '../../components/CountryIcon'
import activityStyle from '../Client/ClientActivity.module.scss'

interface Props {
  mostVisitedStartups?: StartupVisitedStatistics[]
}

export const MostVisitedStartups: FC<Props> = ({
  mostVisitedStartups,
}: Props) => {
  const { t: t_dashboard } = useTranslation('dashboard')
  const { t: t_common } = useTranslation('common')
  const { t: t_startup } = useTranslation('startup')
  const history = useHistory()

  const config: ExtendedColumnsType<StartupVisitedStatistics> = [
    {
      width: 90,
      render: (_, activity: StartupVisitedStatistics) =>
        activity.startup.informations.logoId != null ? (
          <img
            className={activityStyle.logoMobile}
            alt={'Logo'}
            src={`${getApiHost()}/asset-files/${
              activity.startup.informations.logoId
            }`}
          />
        ) : (
          <></>
        ),
    },
    {
      width: 60,
      render: (_, activity: StartupVisitedStatistics) =>
        activity.startup.informations.country != null ? (
          <CountryIcon
            country={activity.startup.informations.country}
            className={activityStyle.centeredContent}
            size={30}
          />
        ) : (
          <></>
        ),
    },
    {
      title: t_startup('society'),
      key: 'name',
      dataIndex: ['startup', 'informations', 'name'],
      className: activityStyle.startupName,
      sorter: (a: StartupVisitedStatistics, b: StartupVisitedStatistics) =>
        Intl.Collator().compare(
          a.startup.informations.name,
          b.startup.informations.name,
        ),
    },
    {
      title: t_dashboard('views'),
      key: 'numberOfVisits',
      dataIndex: ['numberOfVisits'],
      className: styles.nbViews,
    },
  ]

  return (
    <Col md={24} lg={12} className={styles.whiteContainer}>
      <h3>{t_dashboard('most_viewed_startups')}</h3>
      {mostVisitedStartups ? (
        <>
          {mostVisitedStartups.length > 0 ? (
            <Table
              className={'table-with-clickable-row'}
              rowKey={(activity: StartupVisitedStatistics): string =>
                activity.startup.id
              }
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => {
                    event.stopPropagation()
                    history.push(`/startups/${record.startup.id}`)
                  },
                }
              }}
              locale={{
                triggerDesc: t_common('triggerDesc'),
                triggerAsc: t_common('triggerAsc'),
                cancelSort: t_common('cancelSort'),
              }}
              columns={config}
              dataSource={mostVisitedStartups}
              scroll={{ x: true }}
              pagination={false}
            />
          ) : (
            <p>{t_dashboard('not_available')}</p>
          )}
        </>
      ) : (
        <Loading />
      )}
    </Col>
  )
}
