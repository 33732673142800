import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './Dashboard.module.scss'
import { Col } from 'antd'
import Loading from '../../components/Loading'
import { ChartData, DoughnutChart } from './DoughnutChart'
import { ChartSelector } from './ChartSelector'
import SectorEnum from '../../domain/sector.enum'

interface Props {
  mostVisitedEcosystems?: Record<string, number>
  ecosystemsDistribution?: Record<string, number>
}

export const MostVisitedEcosystems: FC<Props> = ({
  mostVisitedEcosystems,
  ecosystemsDistribution,
}: Props) => {
  const { t: t_dashboard } = useTranslation('dashboard')

  const [chart, setChart] = useState<string>('top')
  const [dataType, setDataType] = useState<
    Record<SectorEnum, number> | undefined
  >(mostVisitedEcosystems)

  useEffect((): void => {
    setDataType(
      chart === 'top' ? mostVisitedEcosystems : ecosystemsDistribution,
    )
  }, [chart, mostVisitedEcosystems, ecosystemsDistribution])

  const ECOSYSTEMS_COLORS = [
    '#CA7265',
    '#D2877B',
    '#DA9D92',
    '#E4B8B1',
    '#F1DCD8',
  ]

  const getData = (): ChartData => {
    let labels: string[] = []
    let dataset: number[] = []

    if (chart === 'top' && mostVisitedEcosystems) {
      labels = Object.keys(mostVisitedEcosystems).map((sector) => sector)
      labels.forEach((label): void => {
        let value = mostVisitedEcosystems[label as SectorEnum]
        dataset.push(value)
      })
    } else if (chart === 'distribution' && ecosystemsDistribution) {
      labels = Object.keys(ecosystemsDistribution).map((sector) => sector)
      labels.forEach((label): void => {
        let value = ecosystemsDistribution[label]
        dataset.push(value)
      })
    }

    return {
      datasets: [
        {
          data: dataset,
          backgroundColor: ECOSYSTEMS_COLORS.slice(0, dataset.length),
        },
      ],
      labels: labels,
    }
  }

  return (
    <Col sm={24} md={12} className={styles.whiteContainer}>
      <h3>{t_dashboard('ecosystems')}</h3>
      <ChartSelector chart={chart} setChart={setChart} />
      {dataType ? (
        <>
          {Object.keys(dataType).length > 0 ? (
            <DoughnutChart data={getData()} />
          ) : (
            <p>{t_dashboard('not_available')}</p>
          )}
        </>
      ) : (
        <Loading />
      )}
    </Col>
  )
}
