import React, { FC } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { AccessType, AccessValue } from '../services/hasPermission'
import usePermission from '../services/usePermission.hook'

interface Props {
  children?: any
  roles?: AccessValue
  accessType?: AccessType

  [a: string]: any
}

const ProtectedRoute: FC<Props> = ({
  children,
  roles = [],
  accessType,
  ...rest
}: Props): JSX.Element => {
  const accessRoute = usePermission(roles, accessType)
  if (accessRoute === true) {
    return <Route {...rest}>{children}</Route>
  } else if (accessRoute === false) {
    return <Redirect to={'/403'} />
  } else {
    return <div>Loading</div>
  }
}

export default ProtectedRoute
