import { FixMeLater } from '../App'
import { Roles } from '../utils/roles.enum'

export type AccessType = 'one' | 'all'
export type AccessValue = Roles | Roles[]

export interface AccessConfig {
  access?: AccessValue
  accessType?: AccessType
}

export function canEditStartup(user: FixMeLater, startup: FixMeLater) {
  let innoRight = hasPermissionsInno(user)
  let startupRight = hasPermissionsStartup(user) && startup != null
  return innoRight || startupRight
}

export const hasPermissionsInno = (user: FixMeLater) =>
  hasPermissions(user.roles, Roles.INNO)
export const hasPermissionsStartup = (user: FixMeLater) =>
  hasPermissions(user.roles, Roles.STARTUP)

export const hasPermissions = (
  userRoles: Roles[],
  access: AccessValue,
  accessType?: AccessType,
) => {
  return hasAccessFilter(userRoles)({
    access: access,
    accessType: accessType,
  })
}

export const hasAccessFilter =
  (userRoles: Roles[]) =>
  ({ access: accessParam = [], accessType = 'one' }: AccessConfig): boolean => {
    if (!accessParam) {
      return true
    }
    let access = Array.isArray(accessParam) ? accessParam : [accessParam]
    if (access.length === 0) {
      return true
    }
    if (accessType === 'one') {
      return access.some((role) => userRoles.includes(role))
    }
    return access.every((role) => userRoles.includes(role))
  }
