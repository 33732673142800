import React, { FC, useState } from 'react'
import { Drawer, Grid } from 'antd'
import MainButton from '../../components/MainButton/MainButton'
import { useTranslation } from 'react-i18next'
import FormatHelper from '../../utils/FormatHelper'
import styles from './ClientActivity.module.scss'

interface Props {
  connectionHistory: number[]
}

export const ClientConnectionEvents: FC<Props> = ({
  connectionHistory,
}: Props): JSX.Element => {
  const { t: t_client } = useTranslation('client')
  const breakpoint = Grid.useBreakpoint()
  const [open, setOpen] = useState<boolean>(false)
  const showDrawer = () => {
    setOpen(true)
  }

  const onClose = () => {
    setOpen(false)
  }

  return (
    <>
      <MainButton
        onClick={showDrawer}
        className={breakpoint.xs ? styles.buttonMobile : ''}
      >
        {t_client('activity.showDates')}
      </MainButton>
      <Drawer
        title={t_client('activity.lastConnections')}
        onClose={onClose}
        visible={open}
        closable
        width={breakpoint.xs ? '100%' : '25%'}
        footer={
          <MainButton
            className={!breakpoint.xs ? styles.centeredContent : ''}
            onClick={onClose}
          >
            {t_client('close')}
          </MainButton>
        }
      >
        <>
          {connectionHistory
            .sort((a, b) => b - a)
            .map((connectionEvent, index) => (
              <div key={index} className={styles.datesInDrawer}>
                {FormatHelper.formatDate(connectionEvent, 'fr')}
                <br />
              </div>
            ))}
        </>
      </Drawer>
    </>
  )
}
