import { Button, Space, Typography } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styles from './BrowserSupport.module.scss'
import { ReactComponent as ChromeIcon } from './icons/chrome.svg'
import { ReactComponent as FirefoxIcon } from './icons/firefox.svg'
import { ReactComponent as SafariIcon } from './icons/safari.svg'

interface Props {
  onDismiss: () => void
}

const BrowserSupport = ({ onDismiss }: Props) => {
  const { t: t_login } = useTranslation('login')
  const { t: t_common } = useTranslation('common')
  return (
    <Space direction="vertical" className={styles.container}>
      <Typography.Paragraph>
        {t_login('itSeemsThatWeDontSupportYourNavigator')}
      </Typography.Paragraph>
      <Space align="center">
        <div className={styles.browser}>
          <ChromeIcon className={styles.browserIcon} />
          <div className={styles.browserName}>Chrome</div>
        </div>
        <div className={styles.browser}>
          <FirefoxIcon className={styles.browserIcon} />
          <div className={styles.browserName}>Firefox</div>
        </div>
        <div className={styles.browser}>
          <SafariIcon className={styles.browserIcon} />
          <div className={styles.browserName}>Safari</div>
        </div>
      </Space>
      <Button type="link" onClick={onDismiss}>
        {t_common('continue')}
      </Button>
    </Space>
  )
}

export default BrowserSupport
