import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApiContext from '../../context/ApiContext'
import { ShortNewsModel } from '../../domain/news/shortNews.model'
import styles from './News.module.scss'
import { Button, Grid, Row } from 'antd'

export const DisplayShortNews: FC = (): JSX.Element => {
  const { t: t_shortnews, i18n } = useTranslation('news')
  const [shortNews, setShortNews] = useState<ShortNewsModel>()
  const { getShortNews, errorFormBehaviour } = useContext(ApiContext)
  const breakpoint = Grid.useBreakpoint()
  const [showMore, setShowMore] = useState<boolean>(false)

  useEffect((): void => {
    getShortNews()
      .then((res): void => {
        setShortNews(res)
      })
      .catch(errorFormBehaviour)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.darkContainer}>
      <h2 className={styles.centeredContent}>{t_shortnews('in_brief')}</h2>
      {shortNews && (
        <>
          <p className={styles.shortNewsTitle}>
            {i18n.language === 'fr' ? shortNews.titleFr : shortNews.titleEn}
          </p>
          <div
            className={`${styles.text} ${
              !breakpoint.xl && !showMore ? styles.hiddenText : ''
            }`}
            dangerouslySetInnerHTML={{
              __html:
                i18n.language === 'fr' ? shortNews.textFr : shortNews.textEn,
            }}
          />
          {!breakpoint.xl ? (
            <Row justify={'center'}>
              <Button
                className={styles.button}
                type={'primary'}
                ghost={true}
                onClick={() => setShowMore(!showMore)}
              >
                {t_shortnews(showMore ? 'show_less' : 'show_more')}
              </Button>
            </Row>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  )
}
