import React, { FC, useEffect } from 'react'
import Section from '../../components/Layout/Section'
import { useTranslation } from 'react-i18next'
import styles from './Cgu.module.scss'

export const CguDetails: FC = () => {
  const { t } = useTranslation('general_terms_of_use')

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Section className={`text-align-justify ${styles.cguDetails}`}>
      <h1>{t('title')}</h1>

      {/*INTRODUCTION*/}
      <p>{t('text')}</p>
      <p>{t('key_points.title')}</p>
      <ul>
        <li>{t('key_points.first')}</li>
        <li>{t('key_points.second')}</li>
        <li>{t('key_points.third')}</li>
      </ul>
      <p>
        <b>{t('contacts.title')}</b>: {t('contacts.text')}
      </p>
      <p>
        <b>{t('VAT.title')}</b>: {t('VAT.text')}
      </p>
      <p>
        <b>{t('publication_director.title')}</b>:{' '}
        {t('publication_director.text')}
      </p>
      <p>
        <b>{t('redaction_director.title')}</b>: {t('redaction_director.text')}
      </p>
      <p>
        <b>{t('cloud_info.title')}</b>: {t('cloud_info.text')}
      </p>

      <h3>{t('purpose.title')}</h3>
      <p>{t('purpose.first')}</p>
      <p>{t('purpose.second')}</p>
      <p>{t('purpose.key_points_users.title')}</p>
      <ul>
        <li>{t('purpose.key_points_users.first')}</li>
        <li>{t('purpose.key_points_users.second')}</li>
      </ul>
      <p>{t('purpose.key_points_application_goal.title')}</p>
      <ul>
        <li>{t('purpose.key_points_application_goal.first')}</li>
        <li>{t('purpose.key_points_application_goal.second')}</li>
        <li>{t('purpose.key_points_application_goal.third')}</li>
      </ul>
      <p>{t('purpose.third')}</p>

      {/*FIRST PART*/}
      <h2>{t('1_important_warnings.title')}</h2>
      <p>{t('1_important_warnings.first')}</p>
      <p>{t('1_important_warnings.second')}</p>
      <p>{t('1_important_warnings.third')}</p>

      {/*SECOND PART*/}
      <h2>{t('2_general_principles.title')}</h2>

      <h3>{t('2_general_principles.terms_of_access.title')}</h3>
      <p>{t('2_general_principles.terms_of_access.first')}</p>
      <p>{t('2_general_principles.terms_of_access.key_points.title')}</p>
      <ul>
        <li>{t('2_general_principles.terms_of_access.key_points.first')}</li>
        <li>{t('2_general_principles.terms_of_access.key_points.second')}</li>
      </ul>
      <p>{t('2_general_principles.terms_of_access.second')}</p>

      <h3>{t('2_general_principles.accessible_features.title')}</h3>
      <p>{t('2_general_principles.accessible_features.first')}</p>
      <p>
        <b>{t('2_general_principles.accessible_features.second')}</b>
      </p>

      <h3>{t('2_general_principles.responsibility.title')}</h3>
      <p>
        <b>{t('2_general_principles.responsibility.first')}</b>
      </p>
      <p>{t('2_general_principles.responsibility.key_points.title')}</p>
      <ul>
        <li>{t('2_general_principles.responsibility.key_points.first')}</li>
        <li>{t('2_general_principles.responsibility.key_points.second')}</li>
      </ul>
      <p>
        <b>{t('2_general_principles.responsibility.second')}</b>
      </p>

      <h3>{t('2_general_principles.operating_reserves.title')}</h3>
      <p>{t('2_general_principles.operating_reserves.text')}</p>

      <h3>{t('2_general_principles.restrictions.title')}</h3>
      <p>{t('2_general_principles.restrictions.text')}</p>

      <h3>{t('2_general_principles.hypertext_links.title')}</h3>
      <p>{t('2_general_principles.hypertext_links.first')}</p>
      <p>{t('2_general_principles.hypertext_links.second')}</p>
      <p>
        <b>{t('2_general_principles.hypertext_links.third')}</b>
      </p>

      <h3>{t('2_general_principles.no_guarantee.title')}</h3>
      <p>{t('2_general_principles.no_guarantee.first')}</p>
      <p>{t('2_general_principles.no_guarantee.second')}</p>

      <h3>{t('2_general_principles.confidentiality.title')}</h3>
      <p>
        <b>{t('2_general_principles.confidentiality.key_points.title')}</b>
      </p>
      <ul>
        <li>
          <b>{t('2_general_principles.confidentiality.key_points.first')}</b>
        </li>
        <li>
          <b>{t('2_general_principles.confidentiality.key_points.second')}</b>
        </li>
        <li>
          <b>{t('2_general_principles.confidentiality.key_points.third')}</b>
        </li>
      </ul>

      {/*THIRD PART*/}
      <h2>{t('3_functionalities_pricing.title')}</h2>

      <h3>{t('3_functionalities_pricing.startup.title')}</h3>
      <p>{t('3_functionalities_pricing.startup.first')}</p>
      <p>
        <b>{t('3_functionalities_pricing.startup.second')}</b>
      </p>

      <h3>{t('3_functionalities_pricing.client.title')}</h3>
      <p>{t('3_functionalities_pricing.client.first')}</p>
      <p>{t('3_functionalities_pricing.client.second')}</p>
      <p>
        <b>{t('3_functionalities_pricing.client.third')}</b>
      </p>

      <h3>{t('3_functionalities_pricing.price.title')}</h3>
      <p>{t('3_functionalities_pricing.price.first')}</p>
      <p>
        <b>{t('3_functionalities_pricing.price.second')}</b>
      </p>

      {/*FOURTH PART*/}
      <h2>{t('4_data_protection.title')}</h2>
      <p>{t('4_data_protection.text')}</p>

      <h3>{t('4_data_protection.who.title')}</h3>
      <p>{t('4_data_protection.who.text')}</p>

      <h4>{t('4_data_protection.who.data_conservation.title')}</h4>
      <p>{t('4_data_protection.who.data_conservation.text')}</p>

      <h4>{t('4_data_protection.who.terms_and_conditions.title')}</h4>
      <p>{t('4_data_protection.who.terms_and_conditions.key_points.title')}</p>
      <ul>
        <li>
          {t('4_data_protection.who.terms_and_conditions.key_points.first')}
        </li>
        <li>
          {t('4_data_protection.who.terms_and_conditions.key_points.second')}
        </li>
        <li>
          {t('4_data_protection.who.terms_and_conditions.key_points.third')}
        </li>
        <li>
          {t('4_data_protection.who.terms_and_conditions.key_points.fourth')}
        </li>
        <li>
          {t('4_data_protection.who.terms_and_conditions.key_points.fifth')}
        </li>
        <li>
          {t('4_data_protection.who.terms_and_conditions.key_points.sixth')}
        </li>
      </ul>
      <p>{t('4_data_protection.who.terms_and_conditions.first')}</p>
      <p>{t('4_data_protection.who.terms_and_conditions.second')}</p>
      <p>{t('4_data_protection.who.terms_and_conditions.third')}</p>

      <h3>{t('4_data_protection.cookies.title')}</h3>
      <p>{t('4_data_protection.cookies.first')}</p>
      <p>{t('4_data_protection.cookies.second')}</p>
      <p>{t('4_data_protection.cookies.key_points.title')}</p>
      <ul>
        <li>{t('4_data_protection.cookies.key_points.first')}</li>
        <li>{t('4_data_protection.cookies.key_points.second')}</li>
        <li>{t('4_data_protection.cookies.key_points.third')}</li>
      </ul>
      <p>{t('4_data_protection.cookies.third')}</p>
      <p>{t('4_data_protection.cookies.fourth')}</p>

      {/*FIFTH PART*/}
      <h2>{t('5_intellectual_property.title')}</h2>
      <p>{t('5_intellectual_property.first')}</p>
      <p>{t('5_intellectual_property.second')}</p>
      <p>{t('5_intellectual_property.third')}</p>
      <p>{t('5_intellectual_property.fourth')}</p>

      {/*SIXTH PART*/}
      <h2>{t('6_security_policy.title')}</h2>
      <p>{t('6_security_policy.first')}</p>
      <p>{t('6_security_policy.second')}</p>
      <p>{t('6_security_policy.third')}</p>
    </Section>
  )
}
export default CguDetails
