import React, { FC, useContext } from 'react'
import ContactBloc from '../../components/Layout/InfoBloc/ContactBloc'
import SectionWithInfo from '../../components/Layout/SectionWithInfo'
import userContext from '../../context/UserContext'
import ModifyProfile from './ModifyProfile'
import { useTranslation } from 'react-i18next'

const ModifyMyProfile: FC = () => {
  const { user, setUser } = useContext(userContext)
  const { t: t_input } = useTranslation('input')

  return (
    <SectionWithInfo infoBloc={<ContactBloc />}>
      <ModifyProfile
        profile={user}
        title={t_input('personalInformationFormTitle')}
        onSave={(data) => setUser({ ...user, ...data })}
      />
    </SectionWithInfo>
  )
}

export default ModifyMyProfile
