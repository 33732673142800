import * as XLSX from 'xlsx'
import {
  ExtendedColumnsType,
  ExtendedColumnType,
} from '../services/extendedColumnType'

interface XlsxRowById {
  [id: string]: string
}

interface ObjectLike {
  [attr: string]: any
}

export class XlsxHelper {
  public static generateFromReactTable<T>(
    data: T[],
    tableConfig: ExtendedColumnsType<T>,
    filename: string = 'export',
  ): void {
    let headerConfig: string[] = XlsxHelper.getHeaderConfig(tableConfig)

    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(
      XlsxHelper.getRowsData(data, tableConfig),
      { header: headerConfig, skipHeader: true },
    )
    const workbook: XLSX.WorkBook = {
      Sheets: { data: worksheet },
      SheetNames: ['data'],
    }

    XLSX.writeFile(workbook, `${filename}.xlsx`)
  }

  private static getHeaderConfig<T>(
    tableConfig: ExtendedColumnsType<T>,
  ): string[] {
    return XlsxHelper.getFilteredHeaders(tableConfig).map(
      (col: any): string => col.key,
    )
  }

  private static getFilteredHeaders<T>(tableConfig: ExtendedColumnsType<T>) {
    return tableConfig.filter((col: any): boolean => col.key != null)
  }

  /**
   * Build all xlsxRow from header and data
   */
  private static getRowsData<T>(
    data: T[],
    tableConfig: ExtendedColumnsType<T>,
  ): XlsxRowById[] {
    // Object representing headers : id => value
    let header: XlsxRowById = this.getHeaderRow(tableConfig)
    let rows: XlsxRowById[] = data.map((data: any) =>
      XlsxHelper.pickAttributes(data, tableConfig),
    )
    return [header, ...rows]
  }

  /**
   * Build a xlsxRow from headers
   */
  private static getHeaderRow<T>(
    tableConfig: ExtendedColumnsType<T>,
  ): XlsxRowById {
    let headerRows: XlsxRowById = {}
    this.getFilteredHeaders(tableConfig).forEach((header: any): void => {
      headerRows[header.key] = header.title
    })
    return headerRows
  }

  /**
   * Filter object attributes to keep only the one in keys list
   */
  private static pickAttributes<T>(
    obj: T,
    tableConfig: ExtendedColumnsType<T>,
  ): ObjectLike {
    return tableConfig
      .filter((config) => config.key !== 'action')
      .map((columnType: ExtendedColumnType<T>): ObjectLike => {
        const dataIndex = columnType.dataIndex as string[]
        let value: any = obj
        if (dataIndex !== null && dataIndex !== undefined) {
          if (Array.isArray(dataIndex)) {
            dataIndex.forEach((accessor: string) => {
              if (value !== undefined && value !== null) {
                value = value[accessor as keyof typeof value]
              }
            })
          } else {
            value = value[dataIndex as keyof typeof value]
          }
        } else if (columnType.exportFormat) {
          value = columnType.exportFormat(obj)
        }
        return { [columnType.key as string]: value }
      })
      .reduce((res, o): ObjectLike => Object.assign(res, o), {})
  }
}
