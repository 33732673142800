import { Grid } from 'antd'
import { TFunction } from 'i18next'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import UserContext from '../../../context/UserContext'
import { AccessConfig, AccessValue } from '../../../services/hasPermission'
import useFilterAccess from '../../../services/useFilterAccess.hook'
import usePermission from '../../../services/usePermission.hook'
import FormatHelper from '../../../utils/FormatHelper'
import { Roles } from '../../../utils/roles.enum'
import HeaderDesktop from './HeaderDesktop'
import HeaderMobile from './HeaderMobile'

export type HeaderRoute = AccessConfig & {
  to: string
  label: string
}

const routes: HeaderRoute[] = [
  { to: '/dashboard', label: 'Dashboard', access: Roles.INNO },
  { to: '/startups/my', label: 'Fiche Startup', access: Roles.STARTUP },
  { to: '/startups', label: 'Startups', access: [Roles.INNO] },
  {
    to: '/startups',
    label: 'Les startups',
    access: [Roles.CLIENT, Roles.BANQUIER],
  },
  { to: '/clients', label: 'Clients', access: Roles.INNO },
  { to: '/ecosystem', label: 'Ecosystèmes', access: Roles.INNO },
  { to: '/users', label: 'Users', access: Roles.INNO },
  {
    to: '/linkings',
    label: 'Vos demandes de mise en relation',
    access: Roles.CLIENT,
  },
  { to: '/events', label: 'Demandes de contact', access: Roles.INNO },
]

const userRoutes: HeaderRoute[] = [
  { to: `/profile`, label: 'Informations personnelles' },
  { to: `/client/modify`, label: 'Critères initiaux', access: Roles.CLIENT },
]

export interface HeaderProps {
  routes: HeaderRoute[]
  userRoutes: HeaderRoute[]
  showQuickSearch?: boolean
  userName: string
  logout: () => void
  home: () => void
}

const renameRoutes = (routes: HeaderRoute[], t: TFunction) =>
  routes.map((r) => ({
    ...r,
    label: {
      // Routes
      '/dashboard': t('navbar.dashboard'),
      '/startups/my': t('navbar.card'),
      '/startups': [...[r.access as AccessValue]].includes(Roles.INNO)
        ? t('navbar.startups')
        : t('navbar.theStartups'),
      '/clients': t('navbar.clients'),
      '/ecosystem': t('navbar.ecosystems'),
      '/users': t('navbar.users'),
      '/linkings': t('navbar.linking'),
      '/events': t('navbar.events'),
      // UserRoutes
      '/profile': t('navbar.personalInformations'),
      '/client/modify': t('navbar.intialCriteria'),
    }[r.to],
  })) as HeaderRoute[]

const Header = () => {
  const breakpoint = Grid.useBreakpoint()
  const history = useHistory()
  const { t } = useTranslation('common')
  const { user, logout } = useContext(UserContext)
  const filteredRoutes = useFilterAccess(routes)
  const filteredUserRoutes = useFilterAccess(userRoutes)
  const showQuickSearch = usePermission([
    Roles.INNO,
    Roles.BANQUIER,
    Roles.CLIENT,
  ])

  const logoutAndRedirect = () => logout().then(home)
  const home = () => history.push('/')

  const props: HeaderProps = {
    routes: user.profilCompleted ? renameRoutes(filteredRoutes, t) : [],
    userRoutes: user.profilCompleted ? renameRoutes(filteredUserRoutes, t) : [],
    showQuickSearch: user.profilCompleted && showQuickSearch,
    userName: FormatHelper.formatFullName(user),
    logout: logoutAndRedirect,
    home: home,
  }

  return breakpoint.xs ? (
    <HeaderMobile {...props} />
  ) : (
    <HeaderDesktop {...props} />
  )
}

export default Header
