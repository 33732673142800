import { Select } from 'antd'
import { SelectProps } from 'antd/lib/select'
import { CustomTagProps } from 'rc-select/lib/BaseSelect'
import React, { FC } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import PrimaryTag from '../../Tag/PrimaryTag'
import OptionWithCountModel, {
  OptionWithCountHelper,
} from '../OptionWithCount.model'

type Props = SelectProps<any> & {
  hideSelectedOptions?: boolean
  options: OptionWithCountModel<any>[]
  format?: (v: any, t: TFunction) => string
}

const defaultFormat = (v: any) => v as string

// format required since tagRender 'label' is in fact the value
const tagRender =
  (format: (v: any, t: TFunction) => string = defaultFormat, t: TFunction) =>
  ({ label, closable, onClose }: CustomTagProps) => (
    <PrimaryTag
      closable={closable}
      onClose={onClose}
      children={format(label, t)}
    />
  )

const InputSelectMultiple: FC<Props> = ({
  options,
  hideSelectedOptions = true,
  showArrow = true,
  size = 'large',
  mode = 'multiple',
  style = { width: '100%' },
  format,
  ...props
}: Props) => {
  const { t } = useTranslation('domain')
  const fullProps: SelectProps<any> = {
    // mapping needed to remove unwanted props from OptionWithCountModel
    // ant-design assign all option attributes, and so an error is logged because of addCount method
    ...props,
    options: options.map(OptionWithCountHelper.toSelectOption),
    showArrow,
    size,
    mode,
    style,
  }
  if (hideSelectedOptions && props.value && props.value.length) {
    fullProps.options = fullProps.options?.filter(
      (o) => !props.value.includes(o.value),
    )
  }
  return (
    <Select
      {...fullProps}
      tagRender={tagRender(format, t)}
      optionFilterProp="label"
      getPopupContainer={(trigger) => trigger.parentNode}
    />
  )
}

export default InputSelectMultiple
