import React, { FC } from 'react'
import { Doughnut } from 'react-chartjs-2'

export interface ChartData {
  datasets: {
    data: number[]
    backgroundColor?: string[]
  }[]
  labels: string[]
}

interface Props {
  data: ChartData
}

export const DoughnutChart: FC<Props> = ({ data }: Props) => {
  return <Doughnut data={data} />
}
