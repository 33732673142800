import { CheckOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import React, { FC } from 'react'
import OptionWithCountModel from '../OptionWithCount.model'
import styles from './CheckBoxGroup.module.scss'

interface Props<T> {
  option: OptionWithCountModel<T>
  checked: boolean
  onClick: () => void
}

const CheckBox: FC<Props<any>> = <T,>({
  option,
  checked,
  onClick,
}: Props<T>): JSX.Element => {
  return option.disabled ? (
    <Tag className={[styles.tag, styles.disabled].join(' ')}>
      {option.label}
    </Tag>
  ) : (
    <Tag
      className={[styles.tag, checked ? styles.checked : ''].join(' ')}
      onClick={onClick}
      icon={checked ? <CheckOutlined /> : undefined}
    >
      {option.label}
    </Tag>
  )
}

export default CheckBox
