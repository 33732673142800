import React, { FC } from 'react'
import OptionWithCountModel from '../OptionWithCount.model'
import CheckBox from './CheckBox'
import styles from './CheckBoxGroup.module.scss'

interface Props<T> {
  options: OptionWithCountModel<T>[]
  value?: T
  onChange?: (value: T | undefined) => void
}

const RadioBoxGroup: FC<Props<any>> = <T,>({
  options = [],
  value,
  onChange = () => {},
}: Props<T>): JSX.Element => {
  const toggleOption = (option: OptionWithCountModel<T>): void => {
    if (value === option.value) {
      onChange(undefined)
    } else {
      onChange(option.value)
    }
  }

  const renderTag = (option: OptionWithCountModel<T>, key: number) => (
    <CheckBox
      key={key}
      option={option}
      checked={value === option.value}
      onClick={() => toggleOption(option)}
    />
  )

  return <div className={styles.ButtonCheckBox}>{options.map(renderTag)}</div>
}

export default RadioBoxGroup
