export interface CascaderOption {
  label: string
  value: string
  children?: CascaderOption[]
}

export default class CascaderOptionWithCountModel {
  label: string
  labelWithoutCount: string
  value: string
  children: CascaderOptionWithCountModel[]
  count?: number
  disabled?: boolean

  constructor(option: CascaderOption) {
    this.value = option.value
    this.label = option.label
    this.labelWithoutCount = this.label
    this.children = (option.children || []).map(
      (c) => new CascaderOptionWithCountModel(c),
    )
  }

  addCount = (count?: number): CascaderOptionWithCountModel => {
    this.count = count
    this.label = count
      ? `${this.labelWithoutCount} (${count})`
      : this.labelWithoutCount
    this.disabled = count === 0
    return this
  }
}

export class CascaderOptionWithCountHelper {
  public static buildFrom = (
    values: CascaderOption[],
  ): CascaderOptionWithCountModel[] =>
    CascaderOptionWithCountHelper.buildFromMapper(values)

  public static buildFromMapper = (
    values: CascaderOption[],
  ): CascaderOptionWithCountModel[] =>
    values.map(
      (value: CascaderOption): CascaderOptionWithCountModel =>
        new CascaderOptionWithCountModel(value),
    )

  public static toTagFormat = (value: string[][]) => value.join(' - ')
}
