import { Space } from 'antd'
import React from 'react'
import { CascaderOptionWithCountHelper } from '../../../components/Form/CascaderOptionWithCount.model'
import RangeTag from '../../../components/Range/RangeTag'
import RangeValueModel from '../../../components/Range/RangeValue.model'
import PrimaryTag from '../../../components/Tag/PrimaryTag'
import { SectorHelper } from '../../../domain/sector.enum'
import { StartupMaturitiesHelper } from '../../../domain/startup-maturities.enum'
import { StartupNeedHelper } from '../../../domain/startup-needs.enum'
import { StartupRaiseStatusHelper } from '../../../domain/startup-raiseStatus.enum'
import StartupStatusEnum, {
  StartupStatusHelper,
} from '../../../domain/startup-status.enum'
import { Filter } from './StartupsFilter.model'
import styles from './StartupsFilter.module.scss'
import CountryEnum from '../../../domain/country.enum'
import { TFunction, useTranslation } from 'react-i18next'
import i18next from 'i18next'

interface Props {
  filter: Filter
  onTagRemove: (name: keyof Filter, value: any) => void
}

const RangeValueModelTags = (
  label: string,
  values: RangeValueModel[],
  onClose: (value: RangeValueModel) => void,
) =>
  values.map((value: RangeValueModel) => (
    <RangeTag
      closable={true}
      key={label}
      label={label}
      value={value}
      onClose={() => onClose(value)}
    />
  ))

const FilterTags = <T,>(
  name: keyof Filter,
  values: T[],
  onClose: (name: keyof Filter, value: T) => void,
  t: TFunction,
  format: (value: T, t: TFunction) => string = (v) => v + '',
) => {
  const getColor = (value: T) =>
    name === 'statuses'
      ? StartupStatusHelper.color(value as unknown as StartupStatusEnum)
      : name === 'ecosystems'
        ? '#516069'
        : name === 'fundRaiseNeeds' || name === 'fundRaiseStatus'
          ? '#5992C3'
          : undefined
  return values.map((value: T) => (
    <PrimaryTag
      closable={true}
      key={`${name}-${value}`}
      onClose={() => onClose(name, value)}
      color={getColor(value)}
    >
      {format(value, t)}
    </PrimaryTag>
  ))
}

const StartupsFilterTags = ({ filter, onTagRemove }: Props) => {
  const onTagValueRemove = (name: keyof Filter) => (value: any) =>
    onTagRemove(name, value)
  const { t } = useTranslation('domain')

  return (
    <div className={styles.FilterBloc}>
      <Space wrap={true} size={[0, 8]}>
        {FilterTags(
          'statuses',
          filter.statuses,
          onTagRemove,
          t,
          StartupStatusHelper.format,
        )}
        {FilterTags(
          'sectors',
          filter.sectors,
          onTagRemove,
          t,
          SectorHelper.format,
        )}
        {FilterTags('countries', filter.countries, onTagRemove, t)}
        {FilterTags(
          'regions',
          filter.countries.includes(CountryEnum.FRANCE) ? filter.regions : [],
          onTagRemove,
          t,
        )}

        {FilterTags(
          'ecosystems',
          filter.ecosystems,
          onTagRemove,
          t,
          // @ts-ignore --> CascaderOption change de format en string[][] lors de la manipulation
          CascaderOptionWithCountHelper.toTagFormat,
        )}
        {FilterTags(
          'maturities',
          filter.maturities,
          onTagRemove,
          t,
          StartupMaturitiesHelper.format,
        )}
        {FilterTags(
          'fundRaiseNeeds',
          filter.fundRaiseNeeds,
          onTagRemove,
          t,
          StartupNeedHelper.format,
        )}
        {FilterTags(
          'fundRaiseStatus',
          filter.fundRaiseStatus,
          onTagRemove,
          t,
          StartupRaiseStatusHelper.format,
        )}
        {RangeValueModelTags(
          i18next.language === 'en' ? 'Fund rising' : 'Levée',
          filter.fundRaiseValues,
          onTagValueRemove('fundRaiseValues'),
        )}
        {filter.entryTicket ? (
          <RangeTag
            label={
              i18next.language === 'en' ? 'Entry ticket' : "Ticket d'entrée"
            }
            value={filter.entryTicket}
            closable={true}
            onClose={onTagValueRemove('entryTicket')}
          />
        ) : null}
        {filter.businessWoman ? (
          <PrimaryTag
            closable={true}
            onClose={onTagValueRemove('businessWoman')}
          >
            {i18next.language === 'en' ? 'Women-led' : 'Femme entrepreneure'}
          </PrimaryTag>
        ) : null}
        {filter.environmentalImpact ? (
          <PrimaryTag
            closable={true}
            onClose={onTagValueRemove('environmentalImpact')}
          >
            {i18next.language === 'en' ? 'Positive impact' : 'Société à impact'}
          </PrimaryTag>
        ) : null}
      </Space>
    </div>
  )
}

export default StartupsFilterTags
