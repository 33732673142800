import { CheckOutlined } from '@ant-design/icons'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../../../components/MainButton/MainButton'
import ApiContext from '../../../context/ApiContext'
import PianoAnalyticsContext from '../../../context/PianoAnalyticsContext'
import { EventStatusEnum } from '../../../domain/event/EventStatus.enum'

interface Props {
  startupId: string
}

const AskConnectionRequestButton: FC<Props> = ({ startupId }: Props) => {
  const [eventStatus, setEventStatus] = useState<EventStatusEnum>()
  const [loadingEvent, setLoadingEvent] = useState<boolean>(false)
  const pianoAnalytics = useContext(PianoAnalyticsContext)

  const { createClientLinkingStartup, getClientLinkingStartup } =
    useContext(ApiContext)
  const { t } = useTranslation('startup')

  const miseEnRelation = () => {
    setLoadingEvent(true)
    createClientLinkingStartup(startupId)
      .then(() => {
        pianoAnalytics.createLinking(EventStatusEnum.OPEN)
        setEventStatus(EventStatusEnum.OPEN)
        setLoadingEvent(false)
      })
      .catch(() => setLoadingEvent(false))
  }

  useEffect((): void => {
    getClientLinkingStartup(startupId).then((e) => setEventStatus(e.status))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (eventStatus) {
    return (
      <>
        <MainButton icon={<CheckOutlined />} disabled={true}>
          {t('requestSend')}
        </MainButton>
        <p>{t('startupTeamWillContactYou')}</p>
      </>
    )
  } else {
    return (
      <MainButton loading={loadingEvent} onClick={miseEnRelation}>
        {t('requestContact')}
      </MainButton>
    )
  }
}

export default AskConnectionRequestButton
