import React, { FC } from 'react'
import OptionWithCountModel from '../OptionWithCount.model'
import CheckBox from './CheckBox'
import styles from './CheckBoxGroup.module.scss'

interface Props<T> {
  options: OptionWithCountModel<T>[]
  value?: T[]
  onChange?: (values: T[]) => void
}

const CheckBoxGroup: FC<Props<any>> = <T,>({
  options = [],
  value = [],
  onChange = () => {},
}: Props<T>): JSX.Element => {
  const toggleOption = (option: OptionWithCountModel<T>): void => {
    let selectedValues = value || []
    if (selectedValues.includes(option.value)) {
      onChange(selectedValues.filter((i) => i !== option.value))
    } else {
      onChange([...selectedValues, option.value])
    }
  }
  const renderTag = (option: OptionWithCountModel<T>, key: number) => {
    let checked = (value || []).includes(option.value)
    return (
      <CheckBox
        key={key}
        option={option}
        checked={checked}
        onClick={() => toggleOption(option)}
      />
    )
  }

  return <div className={styles.ButtonCheckBox}>{options.map(renderTag)}</div>
}

export default CheckBoxGroup
