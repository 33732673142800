import moment from 'moment'
import { useTranslation } from 'react-i18next'
import React, { FC } from 'react'
import { CaretDownOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { SelectProps } from 'antd/es/select'

const SelectLanguage: FC<SelectProps<any>> = ({ className }): JSX.Element => {
  const { i18n } = useTranslation('common')
  const setLanguage = (language: string): void => {
    i18n.changeLanguage(language)
    moment.locale(language)
  }

  return (
    <Select
      className={className}
      defaultValue={i18n.language}
      onClick={(e) => e.stopPropagation()}
      onChange={(value: string): void => setLanguage(value)}
      options={[
        { label: 'EN', value: 'en' },
        { label: 'FR', value: 'fr' },
      ]}
      suffixIcon={<CaretDownOutlined />}
    />
  )
}

export default SelectLanguage
