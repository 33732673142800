import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Table } from 'antd'
import { ExtendedColumnsType } from '../../services/extendedColumnType'
import {
  EventDtoModel,
  EventMemberModel,
} from '../../domain/event/EventDtoModel'
import useBreakpointValue from '../../utils/useBreakpointValue.hook'
import Section, { Theme } from '../../components/Layout/Section'
import FormatHelper from '../../utils/FormatHelper'
import {
  EventStatusEnum,
  EventStatusHelper,
} from '../../domain/event/EventStatus.enum'
import styles from '../Client/ClientActivity.module.scss'
import notificationIcon from '../../styles/svg/notificationIcon.svg'
import inProgress from '../../styles/svg/inProgress.svg'
import checkIcon from '../../styles/svg/checkIcon.svg'
import { Link } from 'react-router-dom'

interface Props {
  events: EventDtoModel[]
  onSelectEvent: (event: EventDtoModel) => void
}

export const ContactRequestTable: FC<Props> = ({
  events,
  onSelectEvent,
}: Props): JSX.Element => {
  const gridTheme = useBreakpointValue<Theme>({ xs: 'light', sm: 'mixed' })
  const { t: t_event } = useTranslation('event')
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')

  const allClients = (): EventMemberModel[] => {
    let setOfClients: EventMemberModel[] = []
    events.forEach((event) => {
      if (!setOfClients.map((client) => client.id).includes(event.client!.id)) {
        event.client && setOfClients.push(event.client)
      }
    })
    return setOfClients
  }

  const allStartups = (): EventMemberModel[] => {
    let setOfStartups: EventMemberModel[] = []
    events.forEach((event) => {
      if (
        !setOfStartups.map((startup) => startup.id).includes(event.startup!.id)
      ) {
        event.startup && setOfStartups.push(event.startup)
      }
    })
    return setOfStartups
  }

  const showRequestContactStatus = (event: EventDtoModel): JSX.Element => {
    const status = event !== null ? event.status : undefined
    return status !== undefined ? (
      <div
        style={{ backgroundColor: EventStatusHelper.color(status) }}
        className={styles.badgeEventStatus}
      >
        {EventStatusHelper.format(status, t_domain)}
        <img
          alt={''}
          className={styles.badgeIcon}
          src={
            status === EventStatusEnum.OPEN
              ? notificationIcon
              : status === EventStatusEnum.WIP
                ? inProgress
                : checkIcon
          }
        />
      </div>
    ) : (
      <></>
    )
  }

  const columnConfig: ExtendedColumnsType<EventDtoModel> = [
    {
      title: 'Client',
      key: 'client',
      dataIndex: ['client', 'fullName'],
      render: (_, event) => (
        <Link
          className={styles.link}
          onClick={(e) => e.stopPropagation()}
          to={`/clients/${event.client?.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {event.client?.fullName}
        </Link>
      ),
      sorter: (a: EventDtoModel, b: EventDtoModel) =>
        a.client && b.client
          ? Intl.Collator().compare(a.client.fullName, b.client.fullName)
          : 0,
      filters: allClients().map((s: EventMemberModel) => ({
        text: s.fullName,
        value: s.id,
      })),
      onFilter: (value, record: EventDtoModel) => {
        return record.client?.id === value
      },
    },
    {
      title: 'Startup',
      key: 'startup',
      dataIndex: ['startup', 'fullName'],
      render: (_, event) => (
        <Link
          className={styles.link}
          onClick={(e) => e.stopPropagation()}
          to={`/startups/${event.startup?.id}`}
          rel="noopener noreferrer"
          target="_blank"
        >
          {event.startup?.fullName}
        </Link>
      ),
      sorter: (a: EventDtoModel, b: EventDtoModel) =>
        a.startup && b.startup
          ? Intl.Collator().compare(a.startup.fullName, b.startup.fullName)
          : 0,
      filters: allStartups().map((s: EventMemberModel) => ({
        text: s.fullName,
        value: s.id,
      })),
      onFilter: (value, record: EventDtoModel) => {
        return record.startup?.id === value
      },
    },
    {
      title: t_event('sendThe'),
      key: 'date',
      render: (_, event: EventDtoModel) =>
        FormatHelper.formatDate(event.date, 'fr'),
      sorter: (a: EventDtoModel, b: EventDtoModel) =>
        FormatHelper.comparePossiblyNullNumber(a.date, b.date),
    },
    {
      title: t_event('status'),
      key: 'status',
      width: 160,
      render: (_, event: EventDtoModel) => showRequestContactStatus(event),
      sorter: (a: EventDtoModel, b: EventDtoModel) =>
        Intl.Collator().compare(a.status, b.status),
      filters: EventStatusHelper.values().map((s: EventStatusEnum) => ({
        text: s.valueOf(),
        value: s,
      })),
      onFilter: (value, record: EventDtoModel) => {
        return record.status === value
      },
    },
    {
      render: (_, event: EventDtoModel) => (
        <Button size="small" onClick={() => onSelectEvent(event)}>
          {t_event('see')}
        </Button>
      ),
    },
  ]

  return (
    <Section theme={gridTheme}>
      <Table
        className={'table-with-clickable-row'}
        rowKey={(event: EventDtoModel): string => event.id}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.stopPropagation()
              onSelectEvent(record)
            },
          }
        }}
        locale={{
          triggerDesc: t_common('triggerDesc'),
          triggerAsc: t_common('triggerAsc'),
          cancelSort: t_common('cancelSort'),
        }}
        columns={columnConfig}
        dataSource={events}
        scroll={{ x: true }}
      />
    </Section>
  )
}
