import PasswordValidator from 'password-validator'
import {
  FormInstance,
  RuleObject,
  RuleRender,
} from 'rc-field-form/lib/interface'

const validator = new PasswordValidator()
  .is()
  .min(12)
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .digits()
  .has()
  .not()
  .spaces()

const samePasswordValidator =
  (fieldName: string): RuleRender =>
  ({ getFieldValue }: FormInstance): RuleObject => ({
    validator(rule, value) {
      if (!value || getFieldValue(fieldName) === value) {
        return Promise.resolve()
      }
      return Promise.reject('Les mots de passe saisis ne correspondent pas')
    },
  })

const complexityPasswordValidator: RuleObject = {
  validator(rule, value) {
    if (!value || validator.validate(value)) {
      return Promise.resolve()
    }
    return Promise.reject(
      'Le mot de passe ne respecte pas les règles de complexité',
    )
  },
}

export { samePasswordValidator, complexityPasswordValidator }
