import { Form } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ContactBloc from '../../components/Layout/InfoBloc/ContactBloc'
import SectionWithInfo from '../../components/Layout/SectionWithInfo'
import MainButton from '../../components/MainButton/MainButton'
import ApiContext from '../../context/ApiContext'
import UserContext from '../../context/UserContext'
import { ClientModel } from '../../domain/client/client.model'
import { EditClientReq } from '../../services/Api'
import ClientForm from './ClientForm'
import ModalRisks from './ModalRisks'
import PianoAnalyticsContext from '../../context/PianoAnalyticsContext'
import { useTranslation } from 'react-i18next'
import ClientInvestmentTypeEnum from '../../domain/client/client-investmentType.enum'

const InitClient = () => {
  const history = useHistory()
  const [client, setClient] = useState<ClientModel>()
  const [dataToSave, setDataToSave] = useState<EditClientReq>()
  const [showValidate, setShowValidate] = useState(false)
  const { errorFormBehaviour, saveClient, getMyClientProfil } =
    useContext(ApiContext)
  const { user, setUser } = useContext(UserContext)

  const pianoAnalytics = useContext(PianoAnalyticsContext)
  const { t } = useTranslation('client')

  useEffect((): void => {
    getMyClientProfil().then(setClient)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const save = (clientInformations: EditClientReq) => {
    if (client != null) {
      clientInformations = {
        ...clientInformations,
        regions: clientInformations.regions || [],
      }
      saveClient(client.id, clientInformations)
        .then(() => setUser({ ...user, profilCompleted: true }))
        .then(() => pianoAnalytics.createUser())
        .then(() =>
          pianoAnalytics.selectInitialCriterias({
            ecosystems: clientInformations.ecosystems || [],
            sectors: clientInformations.sectors || [],
            countries: clientInformations.countries || [],
            regions: clientInformations.regions,
            needs: clientInformations.need || [],
            investmentType:
              clientInformations.investmentType as ClientInvestmentTypeEnum,
          }),
        )
        .then(() => history.push('/startups'))
        .catch(errorFormBehaviour)
    }
  }

  const showModalRisks = (data: EditClientReq) => {
    setDataToSave(data)
    setShowValidate(true)
  }

  const validateRisksAndSave = () => {
    setShowValidate(false)
    if (dataToSave != null) {
      save(dataToSave)
    }
  }

  return (
    <SectionWithInfo infoBloc={<ContactBloc />}>
      <h2 className="mb-60">{t('yourSearchCriteria')}</h2>
      <h4>{t('changeableAtAnyTime')}</h4>
      <Form layout="vertical" onFinish={showModalRisks} requiredMark={true}>
        <ClientForm />

        <MainButton htmlType={'submit'}>
          {t('validateAndSeeStartups')}
        </MainButton>

        <ModalRisks
          visible={showValidate}
          onAccept={validateRisksAndSave}
          onRefuse={() => setShowValidate(false)}
        />
      </Form>
    </SectionWithInfo>
  )
}

export default InitClient
