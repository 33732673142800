import { Button, Input, Table } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useHistory } from 'react-router-dom'
import Section from '../../components/Layout/Section'
import ApiContext from '../../context/ApiContext'
import { ClientListModel } from '../../domain/client/client-list.model'
import FormHelper from '../../utils/FormHelper'
import TableHelper from '../../utils/TableHelper'
import EntityEnum, { EntityHelper } from '../../domain/entity.enum'
import MainButton from '../../components/MainButton/MainButton'
import styles from '../Events/DemandesContact.module.scss'
import FormatHelper from '../../utils/FormatHelper'
import { XlsxHelper } from '../../utils/XlsxHelper'
import { ExtendedColumnsType } from '../../services/extendedColumnType'

const ClientsTable: FC = () => {
  const { listClients } = useContext(ApiContext)
  const [clients, setClients] = useState<ClientListModel[]>([])
  const [filteredClients, setFilteredClients] = useState<ClientListModel[]>([])
  const [clientsToExport, setClientsToExport] = useState<ClientListModel[]>([])
  const [columnConfig, setColumnConfig] =
    useState<ExtendedColumnsType<ClientListModel>>()

  const { t: t_client } = useTranslation('client')
  const { t: t_common, i18n } = useTranslation('common')
  const history = useHistory()

  useEffect((): void => {
    const config: ExtendedColumnsType<ClientListModel> = [
      {
        title: t_common('firstName'),
        key: 'firstName',
        dataIndex: 'firstName',
        sorter: TableHelper.stringCompare('firstName'),
        filtered: true,
      },
      {
        title: t_common('lastName'),
        key: 'lastName',
        dataIndex: 'lastName',
        sorter: TableHelper.stringCompare('lastName'),
      },
      {
        title: t_common('email'),
        key: 'email',
        dataIndex: 'email',
        sorter: TableHelper.stringCompare('email'),
      },
      {
        title: t_common('banker'),
        key: 'banquier',
        dataIndex: 'banquier',
        sorter: TableHelper.stringCompare('banquier'),
      },
      {
        title: t_common('categorie'),
        key: 'searchType',
        dataIndex: 'searchType',
        sorter: TableHelper.stringCompare('searchType'),
      },
      {
        title: t_common('entity'),
        key: 'entity',
        dataIndex: 'entity',
        sorter: TableHelper.stringCompare('entity'),
        filters: EntityHelper.allEntities().map((e: EntityEnum) => ({
          text: e.valueOf(),
          value: e,
        })),
        onFilter: (value, record: ClientListModel) => {
          return record.entity === value
        },
      },
      {
        title: t_common('lastConnectionDate'),
        key: 'lastConnectionDate',
        render: (_, client: ClientListModel) =>
          client.lastConnectionDate
            ? FormatHelper.formatDate(client.lastConnectionDate, 'fr')
            : '',
        sorter: (a: ClientListModel, b: ClientListModel) =>
          FormatHelper.comparePossiblyNullNumber(
            a.lastConnectionDate,
            b.lastConnectionDate,
          ),
        exportFormat: (client: ClientListModel) =>
          client.lastConnectionDate
            ? FormatHelper.formatDate(client.lastConnectionDate, 'fr')
            : '',
      },
      {
        render: (_, client) => (
          <Link to={`/clients/${client.id}`}>
            <Button size="small">{t_common('see')}</Button>
          </Link>
        ),
      },
    ]
    setColumnConfig(config)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])

  useEffect((): void => {
    listClients().then((list) => {
      setClients(list)
      setFilteredClients(list)
      setClientsToExport(list)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const filterUsers = (search: string): void => {
    if (FormHelper.isBlank(search)) {
      setFilteredClients(clients)
    } else {
      setFilteredClients(
        clients.filter((client) =>
          `${client.firstName} ${client.lastName} ${client.email} ${client.banquier} ${client.searchType}`
            .toLowerCase()
            .includes(search.toLowerCase()),
        ),
      )
    }
  }

  return (
    <Section>
      <div className={styles.TitleBloc}>
        <h2>{t_client('customersListing')}</h2>
        <MainButton
          onClick={(): void => {
            return (
              columnConfig &&
              XlsxHelper.generateFromReactTable<ClientListModel>(
                clientsToExport,
                columnConfig,
                'export_clients_SCBI_' + FormatHelper.formatDate(Date.now()),
              )
            )
          }}
        >
          Export
        </MainButton>
      </div>
      <div className={'search-bar-container'}>
        <label className={'search-label'}>
          {t_client('searchThroughCustomers')}
        </label>
        <Input.Search
          placeholder={'ex: Nicolas Dupont'}
          allowClear
          onChange={(e) => filterUsers(e.target.value)}
          style={{ width: 300 }}
        />
      </div>
      <br />
      <br />
      <Table
        className={'table-with-clickable-row'}
        rowKey={(client: ClientListModel): string => client.id}
        locale={{
          triggerDesc: t_common('triggerDesc'),
          triggerAsc: t_common('triggerAsc'),
          cancelSort: t_common('cancelSort'),
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              event.stopPropagation()
              history.push(`/clients/${record.id}`)
            },
          }
        }}
        columns={columnConfig}
        dataSource={filteredClients}
        scroll={{ x: true }}
        onChange={(pagination, filters, sorter, currentPageData) =>
          setClientsToExport(currentPageData.currentDataSource)
        }
      />
    </Section>
  )
}

export default ClientsTable
