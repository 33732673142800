enum CountryEnum {
  FRANCE = 'France',
  ITALIE = 'Italie',
  LUXEMBOURG = 'Luxembourg',
  ESPAGNE = 'Espagne',
  UK = 'UK',
  SINGAPOUR = 'Singapour',
  HONG_KONG = 'Hong Kong',
  SUISSE = 'Suisse',
}

export class CountryHelper {
  static allCountries = (): CountryEnum[] => Object.values(CountryEnum)
}

export default CountryEnum
