import React from 'react'
import { Col, Row, Spin } from 'antd'
import styles from './Loading.module.scss'

const Loading = (): JSX.Element => (
  <Row className={styles.loadingContainer}>
    <Col span={24}>
      <Spin size={'large'} />
    </Col>
  </Row>
)

export default Loading
