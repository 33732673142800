import {
  GlobalOutlined,
  WomanOutlined,
  EnvironmentOutlined,
} from '@ant-design/icons'
import { Col, Grid, Row } from 'antd'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Section from '../../../../components/Layout/Section'
import PrimaryTag from '../../../../components/Tag/PrimaryTag'
import { SectorHelper } from '../../../../domain/sector.enum'
import { Informations, StartupModel } from '../../../../domain/startup.model'
import styles from './ShowBaseStartup.module.scss'
import ApiContext from '../../../../context/ApiContext'
import { EcosystemsModel } from '../../../../domain/ecosystems.model'
import CountryIcon from '../../../../components/CountryIcon'
import i18next from 'i18next'
import { getApiHost } from '../../../../conf'

interface Props {
  startup: StartupModel
}

const ShowBaseStartup: FC<Props> = ({ startup }: Props) => {
  const { getEcosystems } = useContext(ApiContext)
  const breakpoint = Grid.useBreakpoint()
  const { t: t_startup } = useTranslation('startup')
  const { t: t_domain } = useTranslation('domain')

  const [ecosystemsList, setEcosystemsList] = useState<EcosystemsModel[]>()
  const [startupEcosystem, setStartupEcosystem] = useState<EcosystemsModel>()

  useEffect((): void => {
    getEcosystems().then(setEcosystemsList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect((): void => {
    if (ecosystemsList) {
      const e = ecosystemsList.find(
        (e) => e.id === startup.informations.ecosystemId,
      )
      setStartupEcosystem(e)
    }
  }, [ecosystemsList, startup])

  return breakpoint.md ? (
    <Section theme="dark" className={styles.header}>
      <Row gutter={30} wrap={false}>
        <Col flex={'none'} className={styles.headerLogo}>
          {showLogo(startup.informations)}
        </Col>
        <Col flex={'auto'}>
          <h2>{startup.informations.name}</h2>
          <p className={styles.blockText}>
            {i18next.language === 'fr'
              ? startup.offer.shortDescriptionFr
              : startup.offer.shortDescriptionEn}
          </p>
          <div className={styles.blockIcon}>
            <p className={styles.sectors}>
              <PrimaryTag key={'sector-tag-md'}>
                {SectorHelper.format(startup.informations.sector, t_domain)}
              </PrimaryTag>
            </p>
            <EnvironmentOutlined className={styles.switchIcon} />
            <div className={styles.village}>
              <p>{startupEcosystem?.name}</p>
              {startup.informations.subEcosystem ? (
                <p>
                  {' - '}
                  {startup.informations.subEcosystem}
                </p>
              ) : null}
            </div>
          </div>
          {startup.informations.businessWoman ||
          startup.informations.environmentalImpact ? (
            <div className={styles.blockIcon}>
              {startup.informations.businessWoman ? (
                <>
                  <WomanOutlined className={styles.switchIcon} />
                  <p className={styles.sectors}>
                    {t_startup('input.businessWoman.label')}
                  </p>
                </>
              ) : null}
              {startup.informations.environmentalImpact ? (
                <>
                  <GlobalOutlined className={styles.switchIcon} />
                  <p className={styles.sectors}>
                    {t_startup('input.environmentalImpact.label')}
                  </p>
                </>
              ) : null}
            </div>
          ) : null}
          <div className={styles.blockIcon}>
            <CountryIcon country={startup.informations.country} />
          </div>
        </Col>
      </Row>
    </Section>
  ) : (
    <Section theme="dark">
      <Row gutter={30} align={'middle'} wrap={true}>
        <Col>{showLogo(startup.informations)}</Col>
        <Col>
          <br />
          <h2 className={styles.title}>{startup.informations.name}</h2>
        </Col>
      </Row>
      <br />
      <Row>
        <p className={styles.blockText}>
          {i18next.language === 'fr'
            ? startup.offer.shortDescriptionFr
            : startup.offer.shortDescriptionEn}
        </p>
      </Row>
      <p className={styles.sectors}>
        <PrimaryTag key={'sector-tag'}>
          {SectorHelper.format(startup.informations.sector, t_domain)}
        </PrimaryTag>
      </p>
      <Row>
        {startup.informations.businessWoman ? (
          <>
            <WomanOutlined className={styles.switchIcon} />
            <p className={styles.sectors}>
              {t_startup('input.businessWoman.label')}
            </p>
          </>
        ) : null}
      </Row>
      <Row>
        {startup.informations.environmentalImpact ? (
          <>
            <GlobalOutlined className={styles.switchIcon} />
            <p className={styles.sectors}>
              {t_startup('input.environmentalImpact.label')}
            </p>
          </>
        ) : null}
      </Row>
      <Row>
        <EnvironmentOutlined className={styles.switchIcon} />
        <div className={styles.village}>
          <p>{startupEcosystem?.name}</p>
          {startup.informations.subEcosystem ? (
            <p>
              {' - '}
              {startup.informations.subEcosystem}
            </p>
          ) : null}
        </div>
      </Row>
      <CountryIcon country={startup.informations.country} />
    </Section>
  )
}

const showLogo = (informations: Informations) =>
  informations.logoId != null ? (
    <div className={styles.blockLogo}>
      <img
        className={styles.logo}
        alt={'Logo'}
        src={`${getApiHost()}/asset-files/${informations.logoId}`}
      />
    </div>
  ) : null

export default ShowBaseStartup
