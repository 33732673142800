import { Form, Input } from 'antd'
import { NamePath } from 'rc-field-form/lib/interface'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { TooltipChildType } from '../InputWithTip/InputWithTip'
import englishFlag from '../../../styles/svg/english_flag.svg'
import frenchFlag from '../../../styles/svg/french_flag.svg'
import styles from '../../../pages/Startup/Update/Common/StartupForm.module.scss'
import { SizeType } from 'antd/lib/config-provider/SizeContext'

type Props = TooltipChildType & {
  title?: any
  name: NamePath
  maxLength: number
  rows?: number
  placeHolder?: string
  language?: string
  size?: SizeType
  setInputValue?: (value: string) => void
}

const TextAreaItem: FC<Props> = ({
  title,
  maxLength,
  name,
  rows = 4,
  placeHolder,
  language,
  size = 'middle',
  setInputValue,
  ...rest
}: Props) => {
  const { t: t_input } = useTranslation('input')
  const { t: t_common } = useTranslation('common')

  return (
    <Form.Item
      {...rest}
      label={
        title ? (
          <>
            {title}{' '}
            <small>
              {maxLength} {t_input('characters')} {t_common('maximum')}
            </small>
          </>
        ) : (
          <>
            <img
              src={language === 'fr' ? frenchFlag : englishFlag}
              alt={''}
              className={styles.flagIcon}
            />
            {language === 'fr'
              ? t_common('french_version')
              : t_common('english_version')}
          </>
        )
      }
      name={name}
      rules={[
        {
          max: maxLength,
          message: `${t_input('textShouldNotExceed')} ${maxLength} ${t_input(
            'characters',
          )}`,
        },
        { required: true, message: t_common('requiredField') },
      ]}
    >
      <Input.TextArea
        rows={rows}
        showCount
        maxLength={maxLength}
        placeholder={placeHolder}
        size={size}
        onChange={(e): void => setInputValue && setInputValue(e.target.value)}
      />
    </Form.Item>
  )
}

export default TextAreaItem
