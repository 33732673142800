import { Col, Row } from 'antd'
import React, { FC, ReactNode } from 'react'
import MainButton from '../../../components/MainButton/MainButton'

import { ReactComponent as GoalIcon } from '../../../styles/svg/Goal.svg'
import { ReactComponent as SecureIcon } from '../../../styles/svg/Secure.svg'
import { ReactComponent as TimeIcon } from '../../../styles/svg/Time.svg'
import styles from './WelcomeStep.module.scss'
import { useTranslation } from 'react-i18next'
import ContactBloc from '../../../components/Layout/InfoBloc/ContactBloc'

interface Props {
  onNextStep: () => void
}

const Bloc = (icon: any, title: string, description: string): ReactNode => (
  <div className={styles.Bloc}>
    <div className={styles.BlocIcon}>{icon}</div>
    <div>
      <h4>{title}</h4>
      <p className={styles.description}>{description}</p>
    </div>
  </div>
)

const WelcomeStep: FC<Props> = ({ onNextStep }: Props) => {
  const { t: t_common } = useTranslation('common')
  const { t: t_startup } = useTranslation('startup')

  return (
    <>
      <h2>
        {t_common('welcomeOnStartupApp')} {t_common('byIndosuez')}
      </h2>
      <p className={styles.subTitle}>{t_common('reservedSpace')}</p>
      <p className={styles.blocTitle}>{t_common('toFigureOut')}</p>
      <Row>
        <Col
          xs={24}
          sm={24}
          md={24}
          lg={18}
          className={styles.welcomeContainer}
        >
          {Bloc(
            <GoalIcon />,
            t_startup('objective'),
            t_startup('welcome.objective'),
          )}
          {Bloc(
            <SecureIcon />,
            t_startup('secureData'),
            t_startup('welcome.secureData'),
          )}
          {Bloc(
            <TimeIcon />,
            t_startup('provide15Min'),
            t_startup('welcome.duration'),
          )}

          <p>
            <strong>{t_startup('init.procedureFinality')}</strong>
          </p>
          <MainButton onClick={onNextStep}>{t_common('begin')}</MainButton>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6}>
          <ContactBloc size={'large'} />
        </Col>
      </Row>
    </>
  )
}

export default WelcomeStep
