import React from 'react'
import MyProfilDto from '../domain/MyProfilDto'

const notImplemented = () => new Error('not implemented yet')

export interface UserContextI {
  user: MyProfilDto
  setUser: (u: MyProfilDto | null) => void
  logout: () => Promise<void>
}

const userContext = React.createContext<UserContextI>({
  user: { id: '', roles: [], email: '' },
  setUser: notImplemented,
  logout: () => Promise.reject(notImplemented()),
})

export default userContext
