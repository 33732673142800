import { Button, Space } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../MainButton/MainButton'

interface Props {
  onCancel?: () => void
  cancelLabel?: string
  saveLabel?: string
  showTips?: boolean
}

const FormFooter: FC<Props> = ({
  onCancel,
  cancelLabel = 'Retour',
  saveLabel = 'Continuer',
  showTips = true,
}: Props) => {
  const { t } = useTranslation('input')

  return (
    <Space direction="vertical" size={24}>
      <Space wrap={true}>
        {onCancel ? <Button onClick={onCancel}>{cancelLabel}</Button> : null}
        <MainButton htmlType={'submit'}>{saveLabel}</MainButton>
      </Space>
      {showTips ? (
        <strong>
          {t('yourProgressIsSavedWhenYouClickOn')} « {saveLabel} ».
        </strong>
      ) : null}
    </Space>
  )
}

export default FormFooter
