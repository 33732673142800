import { Layout } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import styles from './Footer.module.scss'

interface Props {
  showCGU?: boolean
}
const Footer: FC<Props> = ({ showCGU = true }: Props) => {
  const { t } = useTranslation('common')
  return (
    <Layout.Footer className={styles.footer}>
      <a href="https://france.ca-indosuez.com/">
        <img
          className={styles.logo}
          src="/images/ca-indo-wm.svg"
          alt="ca-indo-logo"
        />
      </a>
      {showCGU ? (
        <Link className={[styles.link, 'no-print'].join(' ')} to="/cgudetails">
          {t('CGU')}
        </Link>
      ) : (
        <Link
          className={[styles.link, 'no-print'].join(' ')}
          to="/legalnotices"
        >
          {t('legalNotices')}
        </Link>
      )}
    </Layout.Footer>
  )
}

export default Footer
