import { Button, Col, Modal, Row } from 'antd'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import BadgeStartupStatus from '../../../components/Badge/BadgeStartupStatus'
import MainButton from '../../../components/MainButton/MainButton'
import ApiContext from '../../../context/ApiContext'
import PianoAnalyticsContext from '../../../context/PianoAnalyticsContext'
import StartupStatusEnum from '../../../domain/startup-status.enum'
import { StartupModel } from '../../../domain/startup.model'

interface Props {
  startup: StartupModel
  onUpdate: () => void
}

const StartupHeaderInno: FC<Props> = ({
  startup,
  onUpdate = () => {},
}: Props) => {
  const isValidatedOrDisabled =
    startup.status === StartupStatusEnum.VALIDATED ||
    startup.status === StartupStatusEnum.DISABLED

  const { validateStartup } = useContext(ApiContext)
  const [showValidate, setShowValidate] = React.useState(false)
  const [validating, setValidating] = React.useState(false)
  const pianoAnalytics = useContext(PianoAnalyticsContext)
  const { t: t_startup } = useTranslation('startup')
  const { t: t_common } = useTranslation('common')

  const validate = () => {
    validateStartup(startup.id).then(() => {
      onUpdate()
      pianoAnalytics.validateStartup(startup)
      setValidating(false)
      setShowValidate(false)
    })
  }

  return (
    <>
      <Row justify="space-between">
        <Col flex="none">
          <h2>
            {startup.informations.name}{' '}
            <BadgeStartupStatus status={startup.status} />
          </h2>
        </Col>
        <Col flex="none">
          {isValidatedOrDisabled ? null : (
            <MainButton
              color={'framboise'}
              onClick={() => setShowValidate(true)}
            >
              {t_startup('validateTheCard')}
            </MainButton>
          )}
        </Col>
      </Row>
      <Row justify="space-between">
        <Col flex="none">
          <strong>Contact&nbsp;:&nbsp;</strong>
          <a href={`mailto:${startup.informations.email}`}>
            {startup.informations.email}
          </a>
        </Col>
      </Row>
      <Modal
        visible={showValidate}
        footer={[
          <Button key="back" onClick={() => setShowValidate(false)}>
            {t_common('cancel')}
          </Button>,
          <MainButton
            key="submit"
            type="primary"
            loading={validating}
            onClick={validate}
          >
            {t_common('confirm')}
          </MainButton>,
        ]}
      >
        <p>{t_startup('whenCardWillBeValided')}</p>
      </Modal>
    </>
  )
}

export default StartupHeaderInno
