export interface FlagCode {
  flag: string
  code: string
}

const flagCode: FlagCode[] = [
  { flag: '🇦🇫', code: '+93' },
  { flag: '🇦🇽', code: '+358' },
  { flag: '🇦🇱', code: '+355' },
  { flag: '🇩🇿', code: '+213' },
  { flag: '🇦🇸', code: '+1684' },
  { flag: '🇦🇩', code: '+376' },
  { flag: '🇦🇴', code: '+244' },
  { flag: '🇦🇮', code: '+1264' },
  { flag: '🇦🇶', code: '+672' },
  { flag: '🇦🇬', code: '+1268' },
  { flag: '🇦🇷', code: '+54' },
  { flag: '🇦🇲', code: '+374' },
  { flag: '🇦🇼', code: '+297' },
  { flag: '🇦🇺', code: '+61' },
  { flag: '🇦🇹', code: '+43' },
  { flag: '🇦🇿', code: '+994' },
  { flag: '🇧🇸', code: '+1242' },
  { flag: '🇧🇭', code: '+973' },
  { flag: '🇧🇩', code: '+880' },
  { flag: '🇧🇧', code: '+1246' },
  { flag: '🇧🇾', code: '+375' },
  { flag: '🇧🇪', code: '+32' },
  { flag: '🇧🇿', code: '+501' },
  { flag: '🇧🇯', code: '+229' },
  { flag: '🇧🇲', code: '+1441' },
  { flag: '🇧🇹', code: '+975' },
  { flag: '🇧🇴', code: '+591' },
  { flag: '🇧🇦', code: '+387' },
  { flag: '🇧🇼', code: '+267' },
  { flag: '🇧🇻', code: '+47' },
  { flag: '🇧🇷', code: '+55' },
  { flag: '🇮🇴', code: '+246' },
  { flag: '🇧🇳', code: '+673' },
  { flag: '🇧🇬', code: '+359' },
  { flag: '🇧🇫', code: '+226' },
  { flag: '🇧🇮', code: '+257' },
  { flag: '🇰🇭', code: '+855' },
  { flag: '🇨🇲', code: '+237' },
  { flag: '🇨🇦', code: '+1' },
  { flag: '🇨🇻', code: '+238' },
  { flag: '🇰🇾', code: '+345' },
  { flag: '🇨🇫', code: '+236' },
  { flag: '🇹🇩', code: '+235' },
  { flag: '🇨🇱', code: '+56' },
  { flag: '🇨🇳', code: '+86' },
  { flag: '🇨🇽', code: '+61' },
  { flag: '🇨🇨', code: '+61' },
  { flag: '🇨🇴', code: '+57' },
  { flag: '🇰🇲', code: '+269' },
  { flag: '🇨🇬', code: '+242' },
  { flag: '🇨🇩', code: '+243' },
  { flag: '🇨🇰', code: '+682' },
  { flag: '🇨🇷', code: '+506' },
  { flag: '🇨🇮', code: '+225' },
  { flag: '🇭🇷', code: '+385' },
  { flag: '🇨🇺', code: '+53' },
  { flag: '🇨🇾', code: '+357' },
  { flag: '🇨🇿', code: '+420' },
  { flag: '🇩🇰', code: '+45' },
  { flag: '🇩🇯', code: '+253' },
  { flag: '🇩🇲', code: '+1767' },
  { flag: '🇩🇴', code: '+1849' },
  { flag: '🇪🇨', code: '+593' },
  { flag: '🇪🇬', code: '+20' },
  { flag: '🇸🇻', code: '+503' },
  { flag: '🇬🇶', code: '+240' },
  { flag: '🇪🇷', code: '+291' },
  { flag: '🇪🇪', code: '+372' },
  { flag: '🇪🇹', code: '+251' },
  { flag: '🇫🇰', code: '+500' },
  { flag: '🇫🇴', code: '+298' },
  { flag: '🇫🇯', code: '+679' },
  { flag: '🇫🇮', code: '+358' },
  { flag: '🇫🇷', code: '+33' },
  { flag: '🇬🇫', code: '+594' },
  { flag: '🇵🇫', code: '+689' },
  { flag: '🇹🇫', code: '+262' },
  { flag: '🇬🇦', code: '+241' },
  { flag: '🇬🇲', code: '+220' },
  { flag: '🇬🇪', code: '+995' },
  { flag: '🇩🇪', code: '+49' },
  { flag: '🇬🇭', code: '+233' },
  { flag: '🇬🇮', code: '+350' },
  { flag: '🇬🇷', code: '+30' },
  { flag: '🇬🇱', code: '+299' },
  { flag: '🇬🇩', code: '+1473' },
  { flag: '🇬🇵', code: '+590' },
  { flag: '🇬🇺', code: '+1671' },
  { flag: '🇬🇹', code: '+502' },
  { flag: '🇬🇬', code: '+44' },
  { flag: '🇬🇳', code: '+224' },
  { flag: '🇬🇼', code: '+245' },
  { flag: '🇬🇾', code: '+592' },
  { flag: '🇭🇹', code: '+509' },
  { flag: '🇭🇲', code: '+672' },
  { flag: '🇻🇦', code: '+379' },
  { flag: '🇭🇳', code: '+504' },
  { flag: '🇭🇰', code: '+852' },
  { flag: '🇭🇺', code: '+36' },
  { flag: '🇮🇸', code: '+354' },
  { flag: '🇮🇳', code: '+91' },
  { flag: '🇮🇩', code: '+62' },
  { flag: '🇮🇷', code: '+98' },
  { flag: '🇮🇶', code: '+964' },
  { flag: '🇮🇪', code: '+353' },
  { flag: '🇮🇲', code: '+44' },
  { flag: '🇮🇱', code: '+972' },
  { flag: '🇮🇹', code: '+39' },
  { flag: '🇯🇲', code: '+1876' },
  { flag: '🇯🇵', code: '+81' },
  { flag: '🇯🇪', code: '+44' },
  { flag: '🇯🇴', code: '+962' },
  { flag: '🇰🇿', code: '+7' },
  { flag: '🇰🇪', code: '+254' },
  { flag: '🇰🇮', code: '+686' },
  { flag: '🇰🇵', code: '+850' },
  { flag: '🇰🇷', code: '+82' },
  { flag: '🇽🇰', code: '+383' },
  { flag: '🇰🇼', code: '+965' },
  { flag: '🇰🇬', code: '+996' },
  { flag: '🇱🇦', code: '+856' },
  { flag: '🇱🇻', code: '+371' },
  { flag: '🇱🇧', code: '+961' },
  { flag: '🇱🇸', code: '+266' },
  { flag: '🇱🇷', code: '+231' },
  { flag: '🇱🇾', code: '+218' },
  { flag: '🇱🇮', code: '+423' },
  { flag: '🇱🇹', code: '+370' },
  { flag: '🇱🇺', code: '+352' },
  { flag: '🇲🇴', code: '+853' },
  { flag: '🇲🇰', code: '+389' },
  { flag: '🇲🇬', code: '+261' },
  { flag: '🇲🇼', code: '+265' },
  { flag: '🇲🇾', code: '+60' },
  { flag: '🇲🇻', code: '+960' },
  { flag: '🇲🇱', code: '+223' },
  { flag: '🇲🇹', code: '+356' },
  { flag: '🇲🇭', code: '+692' },
  { flag: '🇲🇶', code: '+596' },
  { flag: '🇲🇷', code: '+222' },
  { flag: '🇲🇺', code: '+230' },
  { flag: '🇾🇹', code: '+262' },
  { flag: '🇲🇽', code: '+52' },
  { flag: '🇫🇲', code: '+691' },
  { flag: '🇲🇩', code: '+373' },
  { flag: '🇲🇨', code: '+377' },
  { flag: '🇲🇳', code: '+976' },
  { flag: '🇲🇪', code: '+382' },
  { flag: '🇲🇸', code: '+1664' },
  { flag: '🇲🇦', code: '+212' },
  { flag: '🇲🇿', code: '+258' },
  { flag: '🇲🇲', code: '+95' },
  { flag: '🇳🇦', code: '+264' },
  { flag: '🇳🇷', code: '+674' },
  { flag: '🇳🇵', code: '+977' },
  { flag: '🇳🇱', code: '+31' },
  { flag: '🇳🇨', code: '+687' },
  { flag: '🇳🇿', code: '+64' },
  { flag: '🇳🇮', code: '+505' },
  { flag: '🇳🇪', code: '+227' },
  { flag: '🇳🇬', code: '+234' },
  { flag: '🇳🇺', code: '+683' },
  { flag: '🇳🇫', code: '+672' },
  { flag: '🇲🇵', code: '+1670' },
  { flag: '🇳🇴', code: '+47' },
  { flag: '🇴🇲', code: '+968' },
  { flag: '🇵🇰', code: '+92' },
  { flag: '🇵🇼', code: '+680' },
  { flag: '🇵🇸', code: '+970' },
  { flag: '🇵🇦', code: '+507' },
  { flag: '🇵🇬', code: '+675' },
  { flag: '🇵🇾', code: '+595' },
  { flag: '🇵🇪', code: '+51' },
  { flag: '🇵🇭', code: '+63' },
  { flag: '🇵🇳', code: '+64' },
  { flag: '🇵🇱', code: '+48' },
  { flag: '🇵🇹', code: '+351' },
  { flag: '🇵🇷', code: '+1939' },
  { flag: '🇶🇦', code: '+974' },
  { flag: '🇷🇴', code: '+40' },
  { flag: '🇷🇺', code: '+7' },
  { flag: '🇷🇼', code: '+250' },
  { flag: '🇷🇪', code: '+262' },
  { flag: '🇧🇱', code: '+590' },
  { flag: '🇸🇭', code: '+290' },
  { flag: '🇰🇳', code: '+1869' },
  { flag: '🇱🇨', code: '+1758' },
  { flag: '🇲🇫', code: '+590' },
  { flag: '🇵🇲', code: '+508' },
  { flag: '🇻🇨', code: '+1784' },
  { flag: '🇼🇸', code: '+685' },
  { flag: '🇸🇲', code: '+378' },
  { flag: '🇸🇹', code: '+239' },
  { flag: '🇸🇦', code: '+966' },
  { flag: '🇸🇳', code: '+221' },
  { flag: '🇷🇸', code: '+381' },
  { flag: '🇸🇨', code: '+248' },
  { flag: '🇸🇱', code: '+232' },
  { flag: '🇸🇬', code: '+65' },
  { flag: '🇸🇰', code: '+421' },
  { flag: '🇸🇮', code: '+386' },
  { flag: '🇸🇧', code: '+677' },
  { flag: '🇸🇴', code: '+252' },
  { flag: '🇿🇦', code: '+27' },
  { flag: '🇸🇸', code: '+211' },
  { flag: '🇬🇸', code: '+500' },
  { flag: '🇪🇸', code: '+34' },
  { flag: '🇱🇰', code: '+94' },
  { flag: '🇸🇩', code: '+249' },
  { flag: '🇸🇷', code: '+597' },
  { flag: '🇸🇯', code: '+47' },
  { flag: '🇸🇿', code: '+268' },
  { flag: '🇸🇪', code: '+46' },
  { flag: '🇨🇭', code: '+41' },
  { flag: '🇸🇾', code: '+963' },
  { flag: '🇹🇼', code: '+886' },
  { flag: '🇹🇯', code: '+992' },
  { flag: '🇹🇿', code: '+255' },
  { flag: '🇹🇭', code: '+66' },
  { flag: '🇹🇱', code: '+670' },
  { flag: '🇹🇬', code: '+228' },
  { flag: '🇹🇰', code: '+690' },
  { flag: '🇹🇴', code: '+676' },
  { flag: '🇹🇹', code: '+1868' },
  { flag: '🇹🇳', code: '+216' },
  { flag: '🇹🇷', code: '+90' },
  { flag: '🇹🇲', code: '+993' },
  { flag: '🇹🇨', code: '+1649' },
  { flag: '🇹🇻', code: '+688' },
  { flag: '🇺🇬', code: '+256' },
  { flag: '🇺🇦', code: '+380' },
  { flag: '🇦🇪', code: '+971' },
  { flag: '🇬🇧', code: '+44' },
  { flag: '🇺🇸', code: '+1' },
  { flag: '🇺🇾', code: '+598' },
  { flag: '🇺🇿', code: '+998' },
  { flag: '🇻🇺', code: '+678' },
  { flag: '🇻🇪', code: '+58' },
  { flag: '🇻🇳', code: '+84' },
  { flag: '🇻🇬', code: '+1284' },
  { flag: '🇻🇮', code: '+1340' },
  { flag: '🇼🇫', code: '+681' },
  { flag: '🇾🇪', code: '+967' },
  { flag: '🇿🇲', code: '+260' },
  { flag: '🇿🇼', code: '+263' },
]

export default flagCode
