import { Modal } from 'antd'
import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MainButton from '../../components/MainButton/MainButton'
import { PasswordLogin } from './PasswordLogin'
import styles from './PasswordLogin.module.scss'

export const PasswordLoginModal: FC = (): JSX.Element => {
  const { t: t_login } = useTranslation('login')
  const [visible, setVisible] = useState<boolean>(false)

  return (
    <>
      <MainButton
        className={styles.marginRight}
        onClick={(): void => setVisible(true)}
      >
        {t_login('toLogin')}
      </MainButton>
      <Modal
        visible={visible}
        footer={null}
        closable
        onCancel={() => setVisible(false)}
      >
        <PasswordLogin mobile />
      </Modal>
    </>
  )
}
