import { Button, Col, Modal, Row } from 'antd'
import React, { FC, useContext, useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import MainButton from '../../../../components/MainButton/MainButton'
import StartupNeedsEnum from '../../../../domain/startup-needs.enum'
import { Raise, StartupModel } from '../../../../domain/startup.model'
import usePermission from '../../../../services/usePermission.hook'
import FormatHelper from '../../../../utils/FormatHelper'
import { Roles } from '../../../../utils/roles.enum'
import AskConnectionRequestButton from '../AskConnectionRequestButton'
import styles from './ShowRaiseStartup.module.scss'
import { StartupRaiseStatusHelper } from '../../../../domain/startup-raiseStatus.enum'
import moment from 'moment'
import ApiContext from '../../../../context/ApiContext'
import StartupStatusEnum from '../../../../domain/startup-status.enum'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'

interface Props {
  raise: Raise
  startup: StartupModel
  onUpdate?: () => void
}

const EditButton = (base: string, t: TFunction) => (
  <Link to={`${base}/edit`}>
    <MainButton>{t('modifyCard')}</MainButton>
  </Link>
)

const ShowRaiseStartup: FC<Props> = ({
  raise,
  startup,
  onUpdate = () => {},
}: Props) => {
  const isStartup = usePermission(Roles.STARTUP)
  const isClient = usePermission(Roles.CLIENT)
  const isInno = usePermission(Roles.INNO)
  const { t: t_startup } = useTranslation('startup')
  const { t: t_common } = useTranslation('common')
  const { t: t_domain } = useTranslation('domain')

  const match = useRouteMatch()

  const fundRising = raise.need?.includes(StartupNeedsEnum.FUND_RISING)
  const businessRelationship = raise.need?.includes(
    StartupNeedsEnum.BUSINESS_RELATIONSHIP,
  )
  const { disableStartup, deleteStartup } = useContext(ApiContext)
  const [showModal, setShowModal] = useState(false)
  const [contentModal, setContentModal] = useState({
    title: '',
    action: '',
    warning: '',
  })
  const [funcModal, setFuncModal] = useState(() => () => {})

  const displayButton = () => {
    if (isStartup || isInno) {
      return EditButton(match.url, t_startup)
    } else if (isClient) {
      return <AskConnectionRequestButton startupId={startup.id} />
    }
    return null
  }

  const confirmDisable = () => {
    disableStartup(startup.id).then(() => {
      onUpdate()
      setShowModal(false)
    })
  }

  const confirmDeletion = () => {
    deleteStartup(startup.id).then(() => {
      window.location.href = '/startups'
    })
  }

  return (
    <Row align="top" justify={'space-between'} gutter={100}>
      <Col xs={24} sm={24} xl={14} xxl={19}>
        {businessRelationship && !fundRising ? (
          <>
            <h3>{t_startup('itSearchBusinessRelationship')}</h3>
            <p>{t_startup('noFundRaising')}</p>{' '}
          </>
        ) : null}
        {fundRising ? (
          <>
            <h3>
              {t_startup('isInFundRaising')} (
              {StartupRaiseStatusHelper.format(raise.status, t_domain)})
            </h3>
            <Row align="top" gutter={50}>
              <Col>
                <h4 className={styles.color}>{t_startup('amount')}</h4>
                <p className={styles.amount}>
                  {FormatHelper.currencyFormatter(raise.targetMoney, {}, true)}
                </p>
              </Col>
              <Col>
                <h4 className={styles.color}>{t_startup('entryTicket')}</h4>
                <p className={styles.amount}>
                  {FormatHelper.currencyFormatter(raise.minInvest)}
                </p>
              </Col>
              <Col>
                <h4 className={styles.color}>{t_startup('closing')}</h4>
                <p className={styles.amount}>
                  {moment(raise.dateRaise).format('MM/YYYY')}
                </p>
              </Col>
              {businessRelationship && fundRising ? (
                <Col span={24}>
                  <p> {t_startup('itSearchBusinessRelationshipToo')} </p>
                </Col>
              ) : null}
            </Row>{' '}
          </>
        ) : null}
      </Col>
      <Col xs={24} sm={24} xl={10} xxl={5} className="no-print">
        {displayButton()}
        {isInno && startup.status !== StartupStatusEnum.DISABLED ? (
          <MainButton
            color={'grey'}
            className={styles.disable}
            onClick={() => {
              setContentModal({
                title: t_startup('disabledCard'),
                action: t_common('disable'),
                warning: t_startup('disableWarning'),
              })
              setFuncModal(() => confirmDisable)
              setShowModal(true)
            }}
          >
            {t_startup('disabledCard')}
          </MainButton>
        ) : null}
        {isInno && startup.status === StartupStatusEnum.DISABLED ? (
          <Button
            className={styles.disable}
            onClick={() => {
              setContentModal({
                title: t_startup('deleteCard'),
                action: t_common('delete'),
                warning: t_startup('deleteCardWarning'),
              })
              setFuncModal(() => confirmDeletion)
              setShowModal(true)
            }}
          >
            {t_startup('deleteStartup')}
          </Button>
        ) : null}
        <Modal
          maskStyle={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
          title={contentModal.title}
          centered
          visible={showModal}
          onCancel={() => setShowModal(false)}
          footer={[
            <Button key="disable" onClick={funcModal}>
              {contentModal.action}
            </Button>,
            <MainButton key="cancel" onClick={() => setShowModal(false)}>
              {t_common('cancel')}
            </MainButton>,
          ]}
        >
          <p> {contentModal.warning} </p>
          <p> {t_startup('confirmDeletion')} </p>
        </Modal>
      </Col>
    </Row>
  )
}

export default ShowRaiseStartup
