import styles from './SwitchGridTableView.module.scss'
import gridIconNotSelected from '../../styles/svg/gridIconNotSelected.svg'
import gridIconSelected from '../../styles/svg/gridIconSelected.svg'
import tableIconSelected from '../../styles/svg/tableIconSelected.svg'
import tableIconNotSelected from '../../styles/svg/tableIconNotSelected.svg'
import React, { FC } from 'react'
import usePermission from '../../services/usePermission.hook'
import { Roles } from '../../utils/roles.enum'
import { useTranslation } from 'react-i18next'

interface Props {
  tableView: boolean
  onChange: (tableView: boolean) => void
}

export const SwitchGridTableView: FC<Props> = ({
  tableView,
  onChange,
}: Props): JSX.Element => {
  const { t: t_startup } = useTranslation('startup')

  const isInno = usePermission(Roles.INNO)

  return isInno ? (
    <>
      <span className={styles.text}>{t_startup('display')}</span>
      <button className={styles.button} onClick={(): void => onChange(false)}>
        <img
          alt={'grid'}
          className={tableView ? styles.iconNotSelected : styles.iconSelected}
          src={tableView ? gridIconNotSelected : gridIconSelected}
        />
      </button>
      <button className={styles.button} onClick={(): void => onChange(true)}>
        <img
          alt={'table'}
          className={tableView ? styles.iconSelected : styles.iconNotSelected}
          src={tableView ? tableIconSelected : tableIconNotSelected}
        />
      </button>
    </>
  ) : (
    <></>
  )
}
