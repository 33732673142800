import Section from '../../../components/Layout/Section'
import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './DisplayClientActivity.module.scss'
import { Col, Divider, Row } from 'antd'
import { EventStatusEnum } from '../../../domain/event/EventStatus.enum'
import { SmallStartupCard } from './SmallStartupCard'
import { StartupModel } from '../../../domain/startup.model'
import ApiContext from '../../../context/ApiContext'
import { ConnectionRequestStatistics } from '../../../domain/admin/dashboard.model'
import { ConnectionRequestBadge } from '../../Events/ConnectionRequestBadge'

export const DisplayClientActivity: FC = (): JSX.Element => {
  const { t: t_client } = useTranslation('client')
  const [lastSeenStartups, setLastSeenStartups] = useState<StartupModel[]>([])
  const [eventsCountByType, setEventsCountByType] =
    useState<ConnectionRequestStatistics>()
  const { countEvents, getLastVisitedStartupsByClient, errorFormBehaviour } =
    useContext(ApiContext)

  useEffect((): void => {
    Promise.all([countEvents(), getLastVisitedStartupsByClient()])
      .then((results): void => {
        const [events, startups] = results
        setEventsCountByType(events)
        setLastSeenStartups(startups)
      })
      .catch(errorFormBehaviour)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Section theme={'dark'}>
      <h2>{t_client('activity.your_activity')}</h2>
      <div className={styles.connectionRequests}>
        <div className={styles.subTitle}>
          {t_client('activity.connection_requests').toUpperCase()}
        </div>
        <Row gutter={20}>
          <Col
            sm={24}
            md={12}
            lg={8}
            className={`${styles.marginBottom} ${styles.alignCenter}`}
          >
            <ConnectionRequestBadge
              nbEvents={eventsCountByType?.openEvents}
              textPlural={t_client('activity.requests.opened_plural')}
              textSingular={t_client('activity.requests.opened_singular')}
              eventStatus={EventStatusEnum.OPEN}
            />
          </Col>
          <Col
            sm={24}
            md={12}
            lg={8}
            className={`${styles.marginBottom} ${styles.alignCenter}`}
          >
            <ConnectionRequestBadge
              nbEvents={eventsCountByType?.inProgressEvents}
              textPlural={t_client('activity.requests.in_progress_plural')}
              textSingular={t_client('activity.requests.in_progress_singular')}
              eventStatus={EventStatusEnum.WIP}
            />
          </Col>
          <Col sm={24} md={12} lg={8} className={styles.alignCenter}>
            <ConnectionRequestBadge
              nbEvents={eventsCountByType?.closedEvents}
              textPlural={t_client('activity.requests.checked_plural')}
              textSingular={t_client('activity.requests.checked_singular')}
              eventStatus={EventStatusEnum.DONE}
            />
          </Col>
        </Row>
      </div>
      <Divider className={styles.divider} />
      <div className={styles.viewedStartups}>
        <div className={styles.subTitle}>
          {t_client('activity.last_startups_seen').toUpperCase()}
        </div>
        <Row gutter={10}>
          {lastSeenStartups && lastSeenStartups.length > 0 ? (
            lastSeenStartups.map((startup, index) => (
              <SmallStartupCard key={index} startup={startup} />
            ))
          ) : (
            <div>{t_client('activity.noStartupHistory')}</div>
          )}
        </Row>
      </div>
    </Section>
  )
}
