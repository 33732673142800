import { Col, Form, Input, Row, Typography } from 'antd'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AuthenticationContext from '../../context/AuthenticationContext'
import { InitAccountReq } from '../../services/Authentication'
import {
  complexityPasswordValidator,
  samePasswordValidator,
} from '../../services/validators/password.validators'
import FormHelper from '../../utils/FormHelper'
import InputWithTip from '../Form/InputWithTip/InputWithTip'
import MainButton from '../MainButton/MainButton'

interface Props {
  email: string
  token: string
}

const InitAccountForm: FC<Props> = ({ email, token }) => {
  const { t: t_login } = useTranslation('login')
  const { t: t_common } = useTranslation('common')
  const { initAccount, authenticateAndRedirect } = useContext(
    AuthenticationContext,
  )

  const saveProfile = (data: InitAccountReq) => {
    initAccount({
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password,
      token,
      email,
    }).then(() =>
      authenticateAndRedirect({ username: email, password: data.password }),
    )
  }

  return (
    <Form layout="vertical" requiredMark={false} onFinish={saveProfile}>
      <h3>{t_login('confirmYourDetailsToContinue')}</h3>
      <Row justify="space-between">
        <Col xs={24} md={11}>
          <Form.Item
            name="firstName"
            label={t_common('firstName')}
            rules={[
              {
                required: true,
                message: `${t_login('pleaseEnterYour')} ${t_common(
                  'firstName',
                ).toLowerCase()}`,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col xs={24} md={11}>
          <Form.Item
            name="lastName"
            label={t_common('lastName')}
            rules={[
              {
                required: true,
                message: `${t_login('pleaseEnterYour')} ${t_common(
                  'lastName',
                ).toLowerCase()}`,
              },
            ]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item name="email" label={t_common('email')}>
        <Typography.Text className="ant-form-text" type="secondary">
          {email}
        </Typography.Text>
      </Form.Item>

      <InputWithTip tip={t_login('atLest12Char1Number1MajAndNoSpace')}>
        <Form.Item
          name="password"
          label={t_common('password')}
          rules={[
            {
              required: true,
              message: `${t_login('pleaseEnterYour')} ${t_common(
                'password',
              ).toLowerCase()}`,
            },
            complexityPasswordValidator,
          ]}
        >
          <Input.Password
            size="large"
            iconRender={FormHelper.passwordIconRenderer}
          />
        </Form.Item>
      </InputWithTip>

      <InputWithTip>
        <Form.Item
          name="confirm"
          label={`${t_login('confirmationOf')} ${t_common(
            'password',
          ).toLowerCase()}`}
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: `${t_login('pleaseConfirmYour')} ${t_common(
                'password',
              ).toLowerCase()}`,
            },
            samePasswordValidator('password'),
          ]}
        >
          <Input.Password
            size="large"
            iconRender={FormHelper.passwordIconRenderer}
          />
        </Form.Item>
      </InputWithTip>

      <MainButton htmlType={'submit'}>{t_common('continue')}</MainButton>
    </Form>
  )
}

export default InitAccountForm
