import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApiContext from '../../context/ApiContext'
import styles from './News.module.scss'
import { Divider, Typography } from 'antd'
import { StartupNewsModel } from '../../domain/news/startupNews.model'

export const DisplayStartupNews: FC = (): JSX.Element => {
  const { t: t_startup_news } = useTranslation('news')
  const [startupNews, setStartupNews] = useState<StartupNewsModel[]>([])
  const { getStartupNews, errorFormBehaviour } = useContext(ApiContext)

  const { Paragraph } = Typography

  useEffect((): void => {
    getStartupNews()
      .then((res: StartupNewsModel[]): void => {
        setStartupNews(res)
      })
      .catch(errorFormBehaviour)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={styles.container}>
      <h2 className={styles.centeredContent}>
        {t_startup_news('startup_news')}
      </h2>
      {startupNews &&
        startupNews.length > 0 &&
        startupNews.map((info, index) => (
          <div key={index}>
            <Divider />
            <p className={styles.title}>{info.description}</p>
            <Paragraph ellipsis={{ rows: 1, expandable: false }} underline>
              <a
                href={info.link}
                rel="noopener noreferrer"
                target="_blank"
                className={styles.link}
              >
                {info.link}
              </a>
            </Paragraph>
          </div>
        ))}
    </div>
  )
}
