import { Card, Skeleton } from 'antd'
import React, { FC } from 'react'
import styles from './DemandeContactCard.module.scss'

const DemandeContactCardLoading: FC = () => {
  return (
    <Card
      hoverable
      title={
        <Skeleton
          active={true}
          paragraph={false}
          className={styles.loadingTitle}
        />
      }
      actions={[
        <Skeleton.Button active={true} shape={'round'} size={'small'} />,
      ]}
    >
      <Skeleton active={true} />
    </Card>
  )
}

export default DemandeContactCardLoading
