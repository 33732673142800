import { Form } from 'antd'
import React, { FC, useContext, useState } from 'react'
import FormFooter from '../../../components/Form/FormFooter'
import ApiContext from '../../../context/ApiContext'
import { StartupModel } from '../../../domain/startup.model'
import { useTranslation } from 'react-i18next'
import PicturesStartupForm from '../Update/Common/PicturesStartupForm'
import { FilePreview } from '../../../domain/filePreview.model'

interface Props {
  onSave: () => void
  onCancel: () => void
  startup: StartupModel
}

const InitPicturesStartup: FC<Props> = ({
  onSave,
  onCancel,
  startup,
}: Props) => {
  const { errorFormBehaviour, uploadStartupPicture, deleteStartupPicture, updateStartup } =
    useContext(ApiContext)
  const [picturesToAdd, setPicturesToAdd] = useState<FilePreview[]>([])

  const updateAllFiles = async (promises: (() => Promise<any>)[]) => {
    let index = 0
    const requestUpdateFile = (): any => {
      if (index >= promises.length) {
        return
      }
      return promises[index]().then(() => {
        index++
        return requestUpdateFile()
      })
    }
    await requestUpdateFile()
  }

  const save = () => {
    let promises: (() => Promise<any>)[] = []

    let toAdd: FilePreview[];
    if (startup.pictureIds !== null && picturesToAdd.length > 0) {
      toAdd = picturesToAdd.filter((picture) => !startup.pictureIds.includes(picture.id))
    } else {
      toAdd = picturesToAdd
    }

    toAdd.forEach((picture) => {
      promises.push(() =>
        uploadStartupPicture(startup.id, {
          id: picture.id,
          file: picture.file,
        }),
      )
    })

    let toDelete: string[] = [];
    if (startup.pictureIds !== null && picturesToAdd.length > 0) {
      toDelete = startup.pictureIds.filter(
          (id) => !picturesToAdd.map((picture) => picture.id).includes(id),
      )
    }
    toDelete.forEach((id: string) => {
      promises.push(() => deleteStartupPicture(startup.id, id))
    })

    if (promises.length > 0) {
      updateAllFiles(promises).then(() => {
        updateStartup(startup.id, startup).then(onSave).catch(errorFormBehaviour)
      }).catch(errorFormBehaviour)
    } else {
      updateStartup(startup.id, startup).then(onSave).catch(errorFormBehaviour)
    }
  }

  const { t } = useTranslation('startup')

  return startup == null ? (
    <></>
  ) : (
    <Form layout="vertical" onFinish={save} requiredMark={false}>
      <h2 className="mb-60">{t('pictures')}</h2>
      <PicturesStartupForm
        updateFilesToAdd={setPicturesToAdd}
        pictureIds={startup.pictureIds ?? []}
      />
      <FormFooter
        onCancel={onCancel}
        cancelLabel={t('back')}
        saveLabel={t('continue')}
      />
    </Form>
  )
}

export default InitPicturesStartup
