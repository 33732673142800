import React, { useState } from 'react'
import { isChrome, isFirefox, isSafari } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import BrowserSupport from '../../components/Authentication/BrowserSupport'
import { PasswordLogin } from '../../components/Authentication/PasswordLogin'
import LayoutLogin from './LayoutLogin'

export default function Login() {
  const { t } = useTranslation('login')
  const [compatible, setCompatible] = useState<boolean>(
    isChrome || isFirefox || isSafari,
  )

  return (
    <LayoutLogin title={t('connectTo')} showFooter={true}>
      {compatible ? (
        <PasswordLogin />
      ) : (
        <BrowserSupport onDismiss={() => setCompatible(true)} />
      )}
    </LayoutLogin>
  )
}
