import React, { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ApiContext from '../../../context/ApiContext'
import { Col, DatePicker, Divider, Grid, Row } from 'antd'
import moment from 'moment'
import styles from './ShowStartupActivity.module.scss'
import FormatHelper from '../../../utils/FormatHelper'

interface Props {
  startUpId: string
}

const today = Date.now()
const todayMinus90Days = today - 24 * 60 * 60 * 1000 * 90

export const ShowStartupActivity: FC<Props> = ({
  startUpId,
}: Props): JSX.Element => {
  const { t: t_startup } = useTranslation('startup')
  const { getStartupVisits, getStartupVisitors, errorFormBehaviour } =
    useContext(ApiContext)
  const { RangePicker } = DatePicker
  const breakpoint = Grid.useBreakpoint()

  const [visits, setVisits] = useState<number>()
  const [visitors, setVisitors] = useState<number>()
  const [startDate, setStartDate] = useState<string | null>(
    FormatHelper.formatTimestampInDateString(todayMinus90Days),
  )
  const [endDate, setEndDate] = useState<string | null>(
    FormatHelper.formatDateString(new Date()),
  )

  const dateFormat = 'DD/MM/YYYY'

  const getActivity = (): void => {
    Promise.all([
      getStartupVisits(startUpId, startDate, endDate),
      getStartupVisitors(startUpId, startDate, endDate),
    ])
      .then((results: number[]): void => {
        const [nbVisits, nbVisitors] = results.map((r: number) => r)
        setVisits(nbVisits)
        setVisitors(nbVisitors)
      })
      .catch(errorFormBehaviour)
  }

  useEffect((): void => {
    getActivity()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startUpId, startDate, endDate])

  const onChange = (value: any): void => {
    const startToDate = value !== null && value[0] ? value[0].toDate() : null
    const endToDate = value !== null && value[1] ? value[1].toDate() : null
    startToDate !== null && startToDate.setHours(12)
    endToDate !== null && endToDate.setHours(12)
    setStartDate(FormatHelper.formatDateString(startToDate))
    setEndDate(FormatHelper.formatDateString(endToDate))
  }

  return (
    <>
      <div>
        <h3>{t_startup('visitors.title')}</h3>
        <Row>
          <Col xs={24} sm={24} md={7} className={'search-bar-container'}>
            <label>{t_startup('visitors.period')}</label>
            <RangePicker
              className={styles.datePicker}
              picker={'date'}
              defaultValue={[
                moment(
                  FormatHelper.formatDate(todayMinus90Days, 'fr'),
                  dateFormat,
                ),
                moment(FormatHelper.formatDate(today, 'fr'), dateFormat),
              ]}
              onChange={(value) => onChange(value)}
              format={dateFormat}
            />
          </Col>
          {breakpoint.md && (
            <Col span={1} offset={1}>
              <Divider
                type={'vertical'}
                style={{
                  marginTop: 'unset',
                  marginBottom: 'unset',
                  height: '100%',
                }}
                className={styles.divider}
              />
            </Col>
          )}
          {visits ? (
            <>
              <Col xs={12} sm={12} md={7}>
                <span className={styles.categoryTitle}>
                  {t_startup('visitors.nbVisits')}
                </span>
                <div className={styles.number}>{visits}</div>
              </Col>
              <Col xs={12} sm={12} md={8}>
                <span className={styles.categoryTitle}>
                  {t_startup('visitors.nbVisitors')}
                </span>
                <div className={styles.number}>{visitors}</div>
              </Col>
            </>
          ) : (
            <Col>{t_startup('visitors.noHistory')}</Col>
          )}
        </Row>
      </div>
      <Divider className={styles.divider} />
    </>
  )
}
