import React, { FC } from 'react'
import { Switch } from 'antd'
import { SwitchProps } from 'antd/es/switch'
import styles from './InputSwitch.module.scss'

type Props = Omit<SwitchProps, 'checked'> & {
  label?: string
  value?: boolean
}

const InputSwitch: FC<Props> = ({ label, value, ...props }) => {
  return (
    <div className={styles.inputBloc}>
      <Switch {...props} checked={value} />{' '}
      <p className={styles.label}>{label}</p>
    </div>
  )
}

export default InputSwitch
