import { Button, Col, Divider, Row, Upload } from 'antd'
import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styles from './StartupForm.module.scss'
import { FilePreview } from '../../../../domain/filePreview.model'
import { genUUID } from '../../../../utils/UuidHelper'
import { getApiHost } from '../../../../conf'
import style from './PictureStartupForm.module.scss'
import uploadFileStyle from '../../../../components/Form/Upload/UploadFile.module.scss'
import { PlusOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { ReactComponent as TrashIcon } from '../../../../styles/svg/Trash.svg'
import { ReactComponent as SynchronizeIcon } from '../../../../styles/svg/synchronizeIcon.svg'

interface Props {
  pictureIds: string[]
  updateFilesToAdd: (data: FilePreview[]) => void
}

const PicturesStartupForm: FC<Props> = ({
  pictureIds,
  updateFilesToAdd,
}: Props) => {
  const { t: t_startup } = useTranslation('startup')
  const { t: t_input } = useTranslation('input')

  const initFiles: FilePreview[] = pictureIds.map((id) => {
    return {
      file: new File([''], `image${id}`),
      preview: `${getApiHost()}/asset-files/${id}`,
      id: id,

    }
  })

  const [files, setFiles] = useState<FilePreview[]>(initFiles)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const AUTHORIZED_EXTENSIONS = ['png', 'jpg', 'jpeg', 'svg']

  // needed to prevent auto upload
  const noRequest = ({ file, onSuccess }: UploadRequestOption) => {
    // @ts-ignore
    setTimeout(() => onSuccess({}, file), 0)
  }

  useEffect((): void => {
    updateFilesToAdd(files)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files])

  const onPictureUpload = ({ fileList, file }: UploadChangeParam) => {
    const filePreview = uploadImage({ fileList, file })
    if (filePreview != null) {
      setFiles([...files, filePreview])
    }
  }

  const onPictureRemove = (fileId: string): void => {
    let updatedFilesToAdd = files.map((file) => { if (file.id === fileId) file.isDelete = true; return file })
    setFiles([...updatedFilesToAdd])
  }

  const onUpdateImage = (
    { fileList, file }: UploadChangeParam,
    fileToDelete: string,
  ) => {
    const filePreview = uploadImage({ fileList, file })
    if (filePreview != null) {
      const updatedFilesToAdd = files.filter((file) => file.id !== fileToDelete)
      setFiles([...updatedFilesToAdd, filePreview])
    }
  }

  const uploadImage = ({
    fileList,
    file,
  }: UploadChangeParam): FilePreview | null => {
    setLoading(true)
    // Only keep first picture
    if (fileList.length > 1) {
      fileList.shift()
    }
    if (file.status === 'done') {
      let valid
      const fileExtension = file.name.split('.').pop()
      valid = AUTHORIZED_EXTENSIONS.includes(
        fileExtension?.toLocaleLowerCase() as string,
      )
      if (valid) {
        let image = file.originFileObj as unknown as File
        setErrorMessage('')
        let preview = window.URL.createObjectURL(new Blob([image]))
        let imageId = genUUID()
        setLoading(false)
        return { file: image, preview: preview, id: imageId }
      } else {
        setErrorMessage(t_startup('input.pictures.error'))
      }
    } else {
      setLoading(true)
    }
    return null
  }

  return (
    <>
      <Row>
        <p>{t_startup('input.pictures.warning')}</p>
      </Row>
      <br />
      <br />
      <Row gutter={20}>
        {files.filter(file => !file.isDelete).map((filePreview, index) => (
          <Col key={index} xs={24} lg={8}>
            <Row>
              <Col span={20} className={style.imageContainer}>
                <div className={uploadFileStyle.avatarDisplay}>
                  <img
                    className={uploadFileStyle.avatarImage}
                    key={filePreview.id}
                    src={filePreview.preview}
                    alt={filePreview.file.name}
                    title={filePreview.file.name}
                  />
                </div>
              </Col>
              <Col span={4} className={style.iconsContainer}>
                <Upload
                  key={index}
                  onChange={(data) => onUpdateImage(data, filePreview.id)}
                  customRequest={noRequest}
                  showUploadList={false}
                  listType="picture"
                >
                  <SynchronizeIcon className={style.clickableIcon} />
                </Upload>
                <TrashIcon
                  className={style.clickableIcon}
                  onClick={() => {
                    onPictureRemove(filePreview.id)
                  }}
                />
              </Col>
            </Row>
            <p style={{ color: '#953d51' }}>{errorMessage}</p>
          </Col>
        ))}
        {files.filter(c => !c.isDelete).length < 3 && (
          <Upload
            key={'add'}
            onChange={onPictureUpload}
            customRequest={noRequest}
            showUploadList={false}
            listType="picture"
          >
            <Button
              loading={loading}
              type="text"
              className={uploadFileStyle.buttonContainer}
            >
              <PlusOutlined className={uploadFileStyle.plusIcon} /> <br />
              <br />
              {t_input('choose_file')}
            </Button>
          </Upload>
        )}
      </Row>
      <Divider className={styles.formDivider} />
    </>
  )
}

export default PicturesStartupForm
