import { Tag } from 'antd'
import { TagProps } from 'antd/lib/tag'
import React, { FC } from 'react'
import styles from './PrimaryTag.module.scss'
export type PrimaryTagProps = TagProps & {
  color?: string
  children?: any
}

const PrimaryTag: FC<PrimaryTagProps> = ({
  color = '#746250',
  children,
  ...rest
}: PrimaryTagProps) => {
  return (
    <Tag className={styles.tag} {...rest} color={color}>
      {children}
    </Tag>
  )
}

export default PrimaryTag
