import React, { FC } from 'react'
import { StartUpActivityModel } from '../../domain/startupActivity.model'
import styles from './ClientActivity.module.scss'
import {
  EventStatusEnum,
  EventStatusHelper,
} from '../../domain/event/EventStatus.enum'
import inProgress from '../../styles/svg/inProgress.svg'
import checkIcon from '../../styles/svg/checkIcon.svg'
import notificationIcon from '../../styles/svg/notificationIcon.svg'
import { useTranslation } from 'react-i18next'
import CountryIcon from '../../components/CountryIcon'
import FormatHelper from '../../utils/FormatHelper'
import { Col, Row } from 'antd'
import { getApiHost } from '../../conf'

interface Props {
  viewedStartUps: StartUpActivityModel[]
}

export const ViewedStartupsMobile: FC<Props> = ({
  viewedStartUps,
}: Props): JSX.Element => {
  const { t: t_domain } = useTranslation('domain')
  const { t: t_client } = useTranslation('client')

  const showRequestContactStatus = (
    activity: StartUpActivityModel,
  ): JSX.Element => {
    const status = activity.event !== null ? activity.event.status : undefined
    return status !== undefined ? (
      <div
        style={{
          backgroundColor: EventStatusHelper.color(status),
          marginTop: '10px',
        }}
        className={styles.badgeEventStatus}
      >
        {EventStatusHelper.format(status, t_domain)}
        <img
          alt={''}
          className={styles.badgeIcon}
          src={
            status === EventStatusEnum.OPEN
              ? notificationIcon
              : status === EventStatusEnum.WIP
                ? inProgress
                : checkIcon
          }
        />
      </div>
    ) : (
      <></>
    )
  }

  return (
    <div>
      <div className={`${styles.categoryTitle} ${styles.viewedStartUpsTitle}`}>
        {t_client('activity.viewedStartUps')}
      </div>
      <br />
      {viewedStartUps.map((activity) => (
        <div
          key={activity.id}
          className={`${styles.separator} ${styles.startupActivityContainerMobile}`}
        >
          <Row>
            {activity.startup.informations.logoId && (
              <Col span={5} className={styles.logoContainer}>
                <img
                  className={styles.logoMobile}
                  alt={'Logo'}
                  src={`${getApiHost()}/asset-files/${
                    activity.startup.informations.logoId
                  }`}
                />
              </Col>
            )}
            <Col span={activity.startup.informations.logoId ? 19 : 24}>
              <span className={styles.startupName}>
                {activity.startup.informations.name}
              </span>
              <Row className={styles.spaceBetween}>
                <span className={styles.startupDate}>
                  {activity.date
                    ? FormatHelper.formatDate(activity.date, 'fr')
                    : ''}
                </span>
                {activity.startup.informations.country != null ? (
                  <CountryIcon
                    country={activity.startup.informations.country}
                    size={30}
                  />
                ) : (
                  <></>
                )}
              </Row>
            </Col>
          </Row>
          {showRequestContactStatus(activity)}
        </div>
      ))}
    </div>
  )
}
