import { PlusOutlined } from '@ant-design/icons'
import { Button, Tooltip, Upload } from 'antd'
import { UploadRequestOption } from 'rc-upload/lib/interface'
import { UploadChangeParam } from 'antd/lib/upload/interface'
import React, { FC, useState } from 'react'

import styles from './UploadFile.module.scss'
import { useTranslation } from 'react-i18next'
import { FilePreview } from '../../../domain/filePreview.model'

interface Props {
  filePreview?: FilePreview
  onFileUpload: (data: File | undefined) => void
  onUploadFail?: () => void
  authorizedExtensions?: string[]
}

const UploadFile: FC<Props> = ({
  filePreview,
  onFileUpload,
  authorizedExtensions,
  onUploadFail,
}: Props) => {
  const { t } = useTranslation('input')
  const { t: t_login } = useTranslation('login')

  const [loading, setLoading] = useState(false)

  const updateImage = ({ fileList, file }: UploadChangeParam) => {
    // Only keep first picture
    if (fileList.length > 1) {
      fileList.shift()
    }
    let valid = true
    if (authorizedExtensions) {
      const fileExtension = file.name.split('.').pop()
      valid = authorizedExtensions.includes(
        fileExtension?.toLocaleLowerCase() as string,
      )
    }
    if (valid) {
      if (file.status === 'uploading') {
        setLoading(true)
      } else if (file.status === 'done') {
        let image = file.originFileObj as unknown as File
        onFileUpload(image)
      }
    } else {
      onUploadFail && onUploadFail()
    }
  }

  // needed to prevent auto upload
  const noRequest = ({ file, onSuccess }: UploadRequestOption) => {
    // @ts-ignore
    setTimeout(() => onSuccess({}, file), 0)
  }

  return (
    <Upload
      onChange={updateImage}
      customRequest={noRequest}
      showUploadList={false}
      listType="picture"
    >
      {filePreview != null ? (
        <Tooltip placement="top" title={t_login('clickOnPictureToModifyIt')}>
          <div className={styles.avatarDisplay}>
            <img
              className={styles.avatarImage}
              key={filePreview.id}
              src={filePreview.preview}
              alt={filePreview.file.name}
              title={filePreview.file.name}
            />
          </div>
        </Tooltip>
      ) : (
        <Button
          loading={loading}
          type="text"
          className={styles.buttonContainer}
        >
          <PlusOutlined className={styles.plusIcon} /> <br />
          <br />
          {t('choose_file')}
        </Button>
      )}
    </Upload>
  )
}

export default UploadFile
