import React from 'react'
import CountryEnum from '../domain/country.enum'
import FranceIcon from '../styles/png/france.png'
import ItalyIcon from '../styles/png/italy.png'
import HKIcon from '../styles/png/hong-kong.png'
import SpainIcon from '../styles/png/spain.png'
import UKIcon from '../styles/png/united-kingdom.png'
import SuisseIcon from '../styles/png/suisse.png'
import SingapourIcon from '../styles/png/singapour.png'
import LuxembourgIcon from '../styles/png/luxembourg.png'

interface Props {
  size?: number
  className?: string
  country: CountryEnum
}

const CountryIcon = ({ size = 20, className, country }: Props): JSX.Element => {
  const getIcon = (country: CountryEnum) => {
    switch (country) {
      case CountryEnum.FRANCE:
        return FranceIcon
      case CountryEnum.ITALIE:
        return ItalyIcon
      case CountryEnum.ESPAGNE:
        return SpainIcon
      case CountryEnum.HONG_KONG:
        return HKIcon
      case CountryEnum.LUXEMBOURG:
        return LuxembourgIcon
      case CountryEnum.SUISSE:
        return SuisseIcon
      case CountryEnum.UK:
        return UKIcon
      case CountryEnum.SINGAPOUR:
        return SingapourIcon
    }
  }

  return (
    <img
      src={getIcon(country)}
      alt="country flag"
      height={size}
      width={size}
      className={className}
    />
  )
}

export default CountryIcon
