import React, { FC } from 'react'
import Section, { Theme } from './Section'
import styles from './SectionWithInfo.module.scss'

interface Props {
  theme?: Theme
  children?: any
  infoBloc?: any
}

const SectionWithInfo: FC<Props> = ({
  theme = 'light',
  children,
  infoBloc,
}: Props) => (
  <Section theme={theme}>
    <div className={styles.sectionContainer}>
      <div className={styles.mainContainer}>{children}</div>
      <div className={styles.infoContainer}>{infoBloc}</div>
    </div>
  </Section>
)

export default SectionWithInfo
