import React, { FC } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import PrimaryTagList from './PrimaryTagList'

interface Config<T> {
  values: T[]
  format?: (value: T, t: TFunction) => string
}

interface Props<T> {
  className?: string
  configs: Config<any>[]
}

const defaultFormat = <T,>(t: T) => t

const PrimaryTagCrossList: FC<Props<any>> = <T,>({
  className,
  configs,
}: Props<T>) => {
  const { t } = useTranslation('domain')
  const values = configs.flatMap((config) =>
    config.values.map((v) => {
      if (config.format) {
        return config.format(v, t)
      }
      return defaultFormat(v)
    }),
  )
  return <PrimaryTagList className={className} values={values} />
}

export default PrimaryTagCrossList
