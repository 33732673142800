enum StartupMarketsEnum {
  B2B = 'B2B',
  B2C = 'B2C',
  B2B2C = 'B2B2C',
}

export class StartupMarketsHelper {
  static allMarkets = (): StartupMarketsEnum[] =>
    Object.values(StartupMarketsEnum)
}

export default StartupMarketsEnum
