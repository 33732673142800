import { TFunction } from 'react-i18next'

export default class OptionWithCountModel<T> {
  label: string
  labelWithoutCount: string
  value: T
  count?: number
  disabled?: boolean

  constructor(value: T, label: string) {
    this.value = value
    this.label = label
    this.labelWithoutCount = label
  }

  addCount = (count?: number): OptionWithCountModel<T> => {
    this.count = count
    this.label = count
      ? `${this.labelWithoutCount} (${count})`
      : this.labelWithoutCount
    this.disabled = count === 0
    return this
  }

  toSelectOption = () => ({
    disabled: this.disabled,
    label: this.label,
    value: this.value,
    isSelectOption: false,
  })
}

const defaultFormat = (v: any) => v as string

type CompareFn<T> = (
  o1: OptionWithCountModel<T>,
  o2: OptionWithCountModel<T>,
) => number

const combineSort =
  <T>(...compareFns: CompareFn<T>[]): CompareFn<T> =>
  (o1: OptionWithCountModel<T>, o2: OptionWithCountModel<T>) => {
    let i = 0
    let result = 0
    while (i < compareFns.length && result === 0) {
      result = compareFns[i](o1, o2)
      i++
    }
    return result
  }

const labelSorter = <T>(
  o1: OptionWithCountModel<T>,
  o2: OptionWithCountModel<T>,
): number =>
  (o1.label + '').localeCompare(o2.label + '', undefined, {
    sensitivity: 'base',
  })

const disabledSorter = <T>(
  o1: OptionWithCountModel<T>,
  o2: OptionWithCountModel<T>,
): number => {
  if (o1.disabled !== o2.disabled) {
    return o1.disabled ? 1 : -1
  }
  return 0
}

const nullSorter = <T>(
  o1: OptionWithCountModel<T>,
  o2: OptionWithCountModel<T>,
): number => {
  if (o1 == null || o2 == null) {
    return -1
  }
  return 0
}

export class OptionWithCountHelper {
  public static sortBy = <T>(...compareFns: CompareFn<T>[]): CompareFn<T> =>
    combineSort(nullSorter, ...compareFns)

  public static sortByDisabledAndLabel = OptionWithCountHelper.sortBy(
    disabledSorter,
    labelSorter,
  )

  public static buildFrom = <T>(
    values: T[],
    t: TFunction,
    format: (v: T, t: TFunction) => string = defaultFormat,
  ): OptionWithCountModel<T>[] =>
    OptionWithCountHelper.buildFromMapper(values, (v) => v, t, format)

  public static buildFromMapper = <T, U>(
    values: T[],
    mapper: (v: T) => U = (v) => v as unknown as U,
    t: TFunction,
    format: (v: T, t: TFunction) => string = defaultFormat,
  ): OptionWithCountModel<U>[] =>
    values.map(
      (value: T): OptionWithCountModel<U> =>
        new OptionWithCountModel(mapper(value), format(value, t)),
    )

  public static toSelectOption = <T>(o: OptionWithCountModel<T>) =>
    o.toSelectOption()
}
