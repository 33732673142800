import axios from 'axios'
import { getApiHost } from '../conf'
import { notification } from 'antd'

const retryTimeInMs = 1000

axios.defaults.withCredentials = true

export function request(method, url, data, params, headers = {}, retry = 1) {
  return axios({
    method,
    url,
    data,
    params,
    withCredentials: true,
    baseURL: getApiHost(),
  })
    .then((response) => response.data)
    .catch((err) => {
      let fct = (ret) => request(method, url, data, params, headers, ret)
      return manageError(err, retry, fct)
    })
}

function manageError(err, retry, fct) {
  if (
    err.toJSON().message.toLowerCase().indexOf('network error') !== -1 &&
    retry === 1
  ) {
    // Retry
    console.log('try a retry in ' + retryTimeInMs + ' ms')
    return new Promise((resolve) => {
      setTimeout(() => resolve(fct(0)), retryTimeInMs)
    })
  }
  if (err.response.status === 401) {
    notification.warning({
      message: 'Connexion expirée',
      description: "Veuillez vous reconnecter pour accéder à l'application",
    })
  } else if (err.response.status === 403) {
    notification.warning({
      message: 'Action non autorisée',
      description: "Vous n'êtes pas autorisé à effectuer cette action",
    })
  } else if (err.response.status === 409) {
    notification.warning({
      message: 'Conflit',
      description: 'Cet email est déjà utilisé par un autre utilisateur',
    })
  } else {
    notification.error({
      message: 'Erreur réseau',
      description:
        "Impossible d'effectuer l'action, un problème réseau est survenu",
    })
  }
  return new Promise((resolve, reject) => reject(err))
}

export function authent(method, url, auth, data) {
  return axios({
    method,
    url,
    data,
    withCredentials: true,
    baseURL: getApiHost(),
    auth: auth
      ? {
          username: auth.username,
          password: auth.password,
        }
      : '',
  }).then((response) => response.data)
}
