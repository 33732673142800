import { Col, Row } from 'antd'
import React, { FC } from 'react'
import styles from './Section.module.scss'

export type Theme = 'light' | 'dark' | 'mixed'

interface Props {
  theme?: Theme
  children?: any
  className?: string
  hideMargins?: boolean
}

const Section: FC<Props> = ({
  theme = 'light',
  children,
  className = '',
  hideMargins = false,
}: Props) => (
  <Row
    justify="center"
    className={
      hideMargins
        ? [className, styles[theme]].join(' ')
        : [className, styles.content, styles[theme]].join(' ')
    }
  >
    <Col xs={24} sm={hideMargins ? 24 : 22} md={hideMargins ? 24 : 20}>
      {children}
    </Col>
  </Row>
)

export default Section
