import { CheckOutlined } from '@ant-design/icons'
import { Cascader, Collapse, Form, Grid } from 'antd'
import { FormInstance } from 'antd/lib/form/hooks/useForm'
import React, { FC, useContext } from 'react'
import CheckBoxGroup from '../../../components/Form/InputCheckBox/CheckBoxGroup'
import InputSelectMultiple from '../../../components/Form/InputSelect/InputSelectMultiple'
import InputRangeNumber from '../../../components/Range/InputRangeNumber'
import UserContext from '../../../context/UserContext'
import { SectorHelper } from '../../../domain/sector.enum'
import { AccessConfig, hasAccessFilter } from '../../../services/hasPermission'
import { Roles } from '../../../utils/roles.enum'
import { DataFilter, Filter } from './StartupsFilter.model'
import styles from './StartupsFilterForm.module.scss'
import InputSwitch from '../../../components/Form/InputSwitch'
import CountryEnum from '../../../domain/country.enum'
import { useTranslation } from 'react-i18next'

interface Props {
  form: FormInstance<Filter>
  filter: Filter
  dataFilter: DataFilter
  onValuesChange: (filter: Filter) => void
}

type ItemProps = AccessConfig & {
  name: string
  label: string
  active: boolean
  input: any
  hidden?: boolean
}

const StartupsFilterForm: FC<Props> = ({
  form,
  filter,
  dataFilter,
  onValuesChange,
}: Props) => {
  const breakpoint = Grid.useBreakpoint()
  const { user } = useContext(UserContext)
  const { t: t_startup } = useTranslation('startup')

  const filterItems: ItemProps[] = [
    {
      name: 'statuses',
      label: t_startup('status.label'),
      active: filter.statuses.length !== 0,
      input: <CheckBoxGroup options={dataFilter.statuses} />,
      access: Roles.INNO,
    },
    {
      name: 'sectors',
      label: t_startup('input.sector.label_filter'),
      active: filter.sectors.length !== 0,
      input: (
        <InputSelectMultiple
          options={dataFilter.sectors}
          format={SectorHelper.format}
        />
      ),
    },
    {
      name: 'countries',
      label: t_startup('input.country.pluriel'),
      active: filter.sectors.length !== 0,
      input: <InputSelectMultiple options={dataFilter.countries} />,
    },
    {
      name: 'regions',
      label: t_startup('input.region.label'),
      active: filter.regions.length !== 0,
      hidden: !filter.countries.includes(CountryEnum.FRANCE),
      input: <InputSelectMultiple options={dataFilter.regions} />,
    },

    {
      name: 'ecosystems',
      label: t_startup('input.ecosystem.pluriel'),
      active: filter.ecosystems.length !== 0,
      input: <Cascader multiple size="large" options={dataFilter.ecosystems} />,
    },
    {
      name: 'maturities',
      label: t_startup('input.developmentMaturity.label'),
      active: filter.maturities.length !== 0,
      input: <CheckBoxGroup options={dataFilter.maturities} />,
    },
    {
      name: 'fundRaiseNeeds',
      label: t_startup('businessOrFund'),
      active: filter.fundRaiseNeeds.length !== 0,
      input: <CheckBoxGroup options={dataFilter.fundRaiseNeeds} />,
    },
    {
      name: 'fundRaiseStatus',
      label: t_startup('input.towerType.label'),
      active: filter.fundRaiseStatus.length !== 0,
      input: <CheckBoxGroup options={dataFilter.fundRaiseStatus} />,
    },
    {
      name: 'fundRaiseValues',
      label: t_startup('fundRising'),
      active: filter.fundRaiseValues.length !== 0,
      input: <CheckBoxGroup options={dataFilter.fundRaiseValues} />,
    },
    {
      name: 'entryTicket',
      label: t_startup('entryTicket'),
      active: filter.entryTicket != null,
      input: <InputRangeNumber />,
    },
    {
      name: 'businessWoman',
      label: t_startup('input.businessWoman.label'),
      active: filter.businessWoman === true,
      input: <InputSwitch label={t_startup('showWomen')} />,
    },
    {
      name: 'environmentalImpact',
      label: t_startup('input.environmentalImpact.label'),
      active: filter.environmentalImpact === true,
      input: <InputSwitch label={t_startup('showImpact')} />,
    },
  ].filter(hasAccessFilter(user.roles))

  return (
    <Form
      layout="vertical"
      form={form}
      initialValues={filter}
      onValuesChange={() => {
        form.validateFields().then((store) => onValuesChange(store as Filter))
      }}
    >
      {breakpoint.xs ? FormMobile(filterItems) : FormDesktop(filterItems)}
    </Form>
  )
}

const FormDesktop = (filterItems: ItemProps[]) => (
  <>
    {filterItems.map((filterItem: ItemProps) => (
      <Form.Item
        label={filterItem.label}
        name={filterItem.name}
        key={filterItem.name}
        hidden={filterItem.hidden}
      >
        {filterItem.input}
      </Form.Item>
    ))}
  </>
)

const FormMobile = (filterItems: ItemProps[]) => {
  return (
    <Collapse
      accordion
      expandIconPosition="right"
      bordered={false}
      className={styles.collapse}
    >
      {filterItems.map((filterItem: ItemProps) => (
        <Collapse.Panel
          header={header(filterItem.label, filterItem.active)}
          key={filterItem.name}
          className={styles.collapsePanel}
        >
          <Form.Item name={filterItem.name} hidden={filterItem.hidden}>
            {filterItem.input}
          </Form.Item>
        </Collapse.Panel>
      ))}
    </Collapse>
  )
}

const header = (value: string, active?: boolean) =>
  active ? (
    <span className={styles.collapsePanelHeaderActive}>
      {value}&nbsp;
      <CheckOutlined />
    </span>
  ) : (
    <span className={styles.collapsePanelHeader}>{value}</span>
  )

export default StartupsFilterForm
