import { PlusOutlined } from '@ant-design/icons'
import { Button, Col, Divider, Form, Input, InputNumber, Row } from 'antd'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { StartupModel } from '../../../../domain/startup.model'
import { ReactComponent as TrashIcon } from '../../../../styles/svg/Trash.svg'
import FormatHelper from '../../../../utils/FormatHelper'
import styles from './StartupForm.module.scss'

interface Props {
  prefix?: keyof StartupModel
}

const TeamStartupForm: FC<Props> = ({ prefix }: Props) => {
  const { t: t_startup } = useTranslation('startup')
  const { t: t_common } = useTranslation('common')
  return (
    <>
      <div>
        {t_startup('founders')} / {t_startup('leaders')}
        <small> 5 {t_common('maximum')}</small>
      </div>
      <Form.List
        name={FormatHelper.formItemNameFormatter(prefix, 'fondateurs')}
      >
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => (
              <Row key={field.key} className={styles.dateRowContainer}>
                <Col xs={24} md={7}>
                  <Form.Item
                    {...field}
                    label={t_common('lastName')}
                    name={[field.name, 'lastname']}
                    fieldKey={[field.key, 'lastname']}
                    rules={[
                      {
                        required: true,
                        message: t_common('requiredField'),
                      },
                    ]}
                    className={styles.noMargin}
                  >
                    <Input size="large" placeholder={'Dupont'} />
                  </Form.Item>
                </Col>

                <Col xs={24} md={{ span: 7, offset: 1 }}>
                  <Form.Item
                    {...field}
                    label={t_common('firstName')}
                    name={[field.name, 'firstname']}
                    fieldKey={[field.key, 'firstname']}
                    rules={[
                      {
                        required: true,
                        message: t_common('requiredField'),
                      },
                    ]}
                    className={styles.noMargin}
                  >
                    <Input size="large" placeholder={'Nicolas'} />
                  </Form.Item>
                </Col>

                <Col xs={24} md={{ span: 7, offset: 1 }}>
                  <Form.Item
                    {...field}
                    label={t_common('role')}
                    name={[field.name, 'role']}
                    fieldKey={[field.key, 'role']}
                    rules={[
                      {
                        required: true,
                        message: t_common('requiredField'),
                      },
                    ]}
                    className={styles.noMargin}
                  >
                    <Input size="large" placeholder={'CEO'} />
                  </Form.Item>
                </Col>
                <Col span={1} className={styles.trashIconContainer}>
                  <TrashIcon
                    className={styles.clickableIcon}
                    onClick={() => remove(field.name)}
                  />
                </Col>
              </Row>
            ))}
            {fields.length < 5 ? (
              <Button onClick={(): void => add()} className={'add-btn'}>
                <Row>
                  <Col>
                    {t_startup('addSomebody')}
                    <br />
                    <small style={{ color: '#9a9a9a' }}>
                      ({t_common('until')} 5 {t_common('maximum')})
                    </small>
                  </Col>
                  <Col style={{ alignSelf: 'center' }}>
                    <PlusOutlined className={styles.plusIcon} />
                  </Col>
                </Row>
              </Button>
            ) : null}
          </>
        )}
      </Form.List>
      <br />
      <br />
      <br />
      <Form.Item
        label={
          <>
            {t_startup('input.collaboratorNumber.label')}
            <small>
              {t_startup('input.collaboratorNumber.additionalInformation')}
            </small>
          </>
        }
        name={FormatHelper.formItemNameFormatter(prefix, 'collaborateurs')}
        rules={[
          {
            required: true,
            message: t_startup('input.collaboratorNumber.message'),
          },
        ]}
      >
        <InputNumber
          placeholder="0"
          style={{ width: '80px' }}
          size="large"
          min={0}
        />
      </Form.Item>

      <Divider className={styles.formDivider} />
    </>
  )
}

export default TeamStartupForm
