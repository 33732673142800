import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Link, Switch, useLocation, useRouteMatch } from 'react-router-dom'
import Section from '../../components/Layout/Section'
import ProtectedRoute from '../../components/ProtectedRoute'
import { Roles } from '../../utils/roles.enum'
import { ReactComponent as BackIcon } from '../../styles/svg/back.svg'
import Client from './Client'
import ClientsTable from './ClientsTable'
import { useTranslation } from 'react-i18next'

export default function Clients() {
  const match = useRouteMatch()
  const location = useLocation()

  const { t } = useTranslation('common')

  const displayTable = location.pathname === match.path
  const tableStyle = displayTable ? {} : { display: 'none' }

  return (
    <>
      <div style={tableStyle}>
        <ClientsTable />
      </div>
      {displayTable ? null : (
        <Section>
          <Link to={match.url}>
            <BackIcon />
            &nbsp;&nbsp;{t('backToList')}
          </Link>
        </Section>
      )}
      <Switch>
        <ProtectedRoute
          path={`${match.url}/:idClient`}
          render={(props: RouteComponentProps<any>) => (
            <Client id={props.match.params.idClient} />
          )}
          roles={Roles.INNO}
        />
      </Switch>
    </>
  )
}
