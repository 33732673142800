import { Card, Col, Divider, Row } from 'antd'
import React, { FC, useContext } from 'react'
import { Link } from 'react-router-dom'
import BadgeStartupStatus from '../../components/Badge/BadgeStartupStatus'
import PrimaryTagCrossList from '../../components/Tag/PrimaryTagCrossList'
import StartupNeedsEnum, {
  StartupNeedHelper,
} from '../../domain/startup-needs.enum'
import { StartupRaiseStatusHelper } from '../../domain/startup-raiseStatus.enum'
import StartupStatusEnum from '../../domain/startup-status.enum'
import { StartupModel } from '../../domain/startup.model'
import usePermission from '../../services/usePermission.hook'
import { Roles } from '../../utils/roles.enum'
import styles from './StartupCard.module.scss'
import PrimaryTag from '../../components/Tag/PrimaryTag'
import PianoAnalyticsContext from '../../context/PianoAnalyticsContext'
import StartupContext from '../../context/StartupContext'
import CountryIcon from '../../components/CountryIcon'
import i18next from 'i18next'
import locationIcon from '../../styles/svg/location.svg'
import { SectorHelper } from '../../domain/sector.enum'
import { useTranslation } from 'react-i18next'
import { getApiHost } from '../../conf'

interface Props {
  startup: StartupModel
  className?: string
}

const StartupCard: FC<Props> = ({ startup, className }: Props) => {
  const pianoAnalytics = useContext(PianoAnalyticsContext)
  const accessInno = usePermission(Roles.INNO)
  const { getEcosystemName } = useContext(StartupContext)
  const { t: t_domain } = useTranslation('domain')
  const opacity = startup.status === StartupStatusEnum.DISABLED ? '0.5' : '1'

  const clickStartupCard = () => {
    pianoAnalytics.clickStartupCard(startup)
  }

  return (
    <Link
      to={`/startups/${startup.id}`}
      style={{ opacity: opacity, minWidth: '270px' }}
      onClick={clickStartupCard}
    >
      <Card hoverable className={className}>
        <Row gutter={20}>
          {showLogo(startup)}
          <Col xs={18} sm={24} md={18} lg={18} className={styles.startupName}>
            {startup.informations.name}
          </Col>
        </Row>
        <Divider />

        <Row className={styles.subEcosystem}>
          {startup.informations.subEcosystem && (
            <>
              <img
                alt={''}
                src={locationIcon}
                className={styles.locationIcon}
              />
              <p className={styles.village}>
                {startup.informations.subEcosystem}
              </p>
            </>
          )}
        </Row>

        <PrimaryTag color={'#516069'}>{getEcosystemName(startup)} </PrimaryTag>
        <PrimaryTag>
          {SectorHelper.format(startup.informations.sector, t_domain)}{' '}
        </PrimaryTag>
        <div className={styles.description}>
          {i18next.language === 'fr'
            ? startup.offer.shortDescriptionFr
            : startup.offer.shortDescriptionEn}
        </div>
        <Divider />
        <Row justify={'space-between'}>
          <Col span={20}>
            {accessInno && startup.status !== StartupStatusEnum.VALIDATED && (
              <BadgeStartupStatus status={startup.status} />
            )}
            <PrimaryTagCrossList
              configs={[
                {
                  values: startup.raise.status ? [startup.raise.status] : [],
                  format: StartupRaiseStatusHelper.format,
                },
                {
                  values:
                    startup.raise.need?.includes(
                      StartupNeedsEnum.BUSINESS_RELATIONSHIP,
                    ) &&
                    !startup.raise.need?.includes(StartupNeedsEnum.FUND_RISING)
                      ? [StartupNeedsEnum.BUSINESS_RELATIONSHIP]
                      : [],
                  format: StartupNeedHelper.format,
                },
              ]}
            />
          </Col>
          <Col span={4}>
            <CountryIcon country={startup.informations.country} />
          </Col>
        </Row>
      </Card>
    </Link>
  )
}

const showLogo = (startup: StartupModel) => {
  return (
    <Col xs={6} sm={24} md={6} lg={6} className={styles.logoContainer}>
      {startup.informations.logoId != null ? (
        <img
          className={styles.logo}
          alt={'Logo'}
          src={`${getApiHost()}/asset-files/${startup.informations.logoId}`}
        />
      ) : (
        <span className={styles.logoText}>{startup.informations.name}</span>
      )}
    </Col>
  )
}

export default StartupCard
