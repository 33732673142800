import React, { useContext, useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'
import ApiContext from '../../context/ApiContext'
import UserContext from '../../context/UserContext'
import MyProfilDto from '../../domain/MyProfilDto'
import { Roles } from '../../utils/roles.enum'

const getRedirection = (user: MyProfilDto): string => {
  if (user.roles.includes(Roles.STARTUP)) {
    return user.profilCompleted ? '/startups/my' : '/startups/init'
  } else if (user.roles.includes(Roles.CLIENT)) {
    return user.profilCompleted ? '/startups' : '/client/init'
  } else if (user.roles.includes(Roles.INNO)) {
    return '/dashboard'
  }
  return '/startups'
}

const Redirection = () => {
  const [redirection, setRedirection] = useState<string>()
  const { user } = useContext(UserContext)
  const { isConsented } = useContext(ApiContext)

  useEffect((): void => {
    isConsented().then((consented) => {
      setRedirection(consented ? getRedirection(user) : '/CG')
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return redirection ? <Redirect to={redirection} /> : null
}

export default Redirection
