import { Button, Col, Row } from 'antd'
import React, { FC, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import usePermission from '../../services/usePermission.hook'
import { Roles } from '../../utils/roles.enum'
import Section from './Section'

interface Props<T> {
  dataCards: T[]
  total?: number
  viewMore?: () => void
  what?: string
  children: (t: T, index: number) => ReactNode
  isInno?: boolean
}

const GridCard: FC<Props<any>> = <T,>({
  dataCards,
  total,
  viewMore = () => {},
  what,
  children,
  isInno = false,
}: Props<T>) => {
  const [scrollPosition, setScrollPosition] = useState<number>(0)
  const isClient = usePermission(Roles.CLIENT)

  const { t } = useTranslation('startup')

  useEffect((): void => {
    // when display count change, the page autoscroll
    // so we go back to previous scrollPosition
    window.scrollTo(0, scrollPosition)
  }, [dataCards, scrollPosition])

  const onViewMore = (): void => {
    // store current scroll position before display
    setScrollPosition(window.pageYOffset)
    viewMore()
  }

  return (
    <Section
      theme={isInno ? 'mixed' : 'light'}
      hideMargins={what === 'startups'}
    >
      <Row gutter={[16, { xs: 16, sm: 16, md: 24, lg: 32 }]} justify="start">
        {dataCards.map((s: T, index: number) => (
          <Col
            className={'startup_card'}
            key={`card_${index}`}
            xs={24}
            sm={12}
            md={12}
            lg={8}
            xl={isClient ? 12 : 8}
            xxl={isClient ? 8 : 6}
          >
            {children(s, index)}
          </Col>
        ))}
      </Row>

      {total != null ? (
        <Row justify="space-around" style={{ marginTop: '15px' }}>
          <Col className="text-align-center">
            <p>
              {' '}
              {t('youSeen')} {dataCards.length} {t('on')} {total}{' '}
              <strong>{what}</strong>
            </p>
            {total > dataCards.length ? (
              <Button type="primary" ghost={true} onClick={onViewMore}>
                {' '}
                {t('moreResults')}{' '}
              </Button>
            ) : null}
          </Col>
        </Row>
      ) : null}
    </Section>
  )
}

export default GridCard
