import { Button, Col, Grid, Row, Typography } from 'antd'
import i18next, { TFunction } from 'i18next'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Informations,
  KeyDate,
  Offer,
  Team,
} from '../../../../domain/startup.model'
import FormatHelper from '../../../../utils/FormatHelper'
import styles from './ShowOfferStartup.module.scss'

interface Props {
  offer: Offer
  team: Team
  informations: Informations
}

const ShowOfferStartup: FC<Props> = ({ offer, team, informations }: Props) => {
  const breakpoint = Grid.useBreakpoint()
  const { t } = useTranslation('startup')

  return offer != null ? (
    <Row align={'top'} justify="space-between" gutter={100}>
      <Col xs={24} sm={24} lg={16} xl={19}>
        {breakpoint.xs ? (
          <Typography.Paragraph
            ellipsis={{
              rows: 23,
              expandable: true,
              symbol: (
                <Button className={styles.button} type={'primary'} ghost={true}>
                  {t('showMore')}
                </Button>
              ),
            }}
          >
            {showOffer(informations, offer, t)}
          </Typography.Paragraph>
        ) : (
          showOffer(informations, offer, t)
        )}
      </Col>
      <Col xs={24} sm={24} lg={8} xl={5}>
        {showTeam(team, t)}
        {showMore(informations, t)}
      </Col>
    </Row>
  ) : (
    <></>
  )
}

const showRoadMap = (roadMap: KeyDate[], language: string): JSX.Element => {
  return roadMap && roadMap.length > 0 ? (
    <ul>
      {roadMap.map((keyDate, index) => (
        <li key={index}>
          <b>{FormatHelper.formatTimestampInMonthYear(keyDate.date)}</b> -{' '}
          {language === 'fr' ? keyDate.descriptionFr : keyDate.descriptionEn}
        </li>
      ))}
    </ul>
  ) : (
    <></>
  )
}

const showOffer = (informations: Informations, offer: Offer, t: TFunction) => {
  return (
    <>
      <h3 className={styles.title}>{t('input.keyDate.label')}</h3>
      <div className={styles.blocText}>
        {showRoadMap(informations.roadMap, i18next.language)}
      </div>

      <h3 className={styles.title}>{t('offerPresentation')}</h3>
      <p className={styles.blocText}>
        {FormatHelper.startupPresentation(informations.name, offer)}
      </p>

      <h4>{t('input.marketOpportunity.label')}</h4>
      <p className={styles.blocText}>
        {i18next.language === 'fr'
          ? offer.marketOpportunityFr
          : offer.marketOpportunityEn}
      </p>

      <h4>{t('input.innovativeSolution.label')}</h4>
      <p className={styles.blocText}>
        {i18next.language === 'fr'
          ? offer.innovativeSolutionFr
          : offer.innovativeSolutionEn}
      </p>

      <h4>{t('input.economicModel.label')}</h4>
      <p className={styles.blocText}>
        {i18next.language === 'fr'
          ? offer.economicModelFr
          : offer.economicModelEn}
      </p>
    </>
  )
}

const showTeam = (team: Team, t: TFunction) => {
  return team != null ? (
    <>
      <h3>{t('team')}</h3>
      <br />
      <h4>{t('founders')}</h4>
      {team.fondateurs.map((f, index) => (
        <p key={index} className={styles.blocText}>
          {f.firstname}&nbsp;{f.lastname}&nbsp;{f.role}
        </p>
      ))}
      <br />
      <p className={styles.blocText}>
        {team.collaborateurs}&nbsp;{t('collaborators')}
      </p>
    </>
  ) : null
}

const showMore = (informations: Informations, t: TFunction) => {
  return informations.website != null || informations.video != null ? (
    <>
      <h3 className={styles.titleMargin}>{t('knowMore')}</h3>
      <br />
      {informations.website ? (
        <Button type="link" href={informations.website}>
          {t('input.website.label')}
        </Button>
      ) : null}
      <br />
      {informations.video ? (
        <Button type="link" href={informations.video}>
          {t('input.video.label')}
        </Button>
      ) : null}
      <br />
      {informations.linkedin ? (
        <Button type="link" href={informations.linkedin}>
          {t('input.linkedin.label')}
        </Button>
      ) : null}
    </>
  ) : null
}

export default ShowOfferStartup
