import { Form, Input } from 'antd'
import React, { FC, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import AuthenticationContext from '../../context/AuthenticationContext'
import { ResetPasswordReq } from '../../services/Authentication'
import {
  complexityPasswordValidator,
  samePasswordValidator,
} from '../../services/validators/password.validators'
import FormHelper from '../../utils/FormHelper'
import InputWithTip from '../Form/InputWithTip/InputWithTip'
import MainButton from '../MainButton/MainButton'

interface Props {
  email: string
  token: string
}

const ResetPasswordForm: FC<Props> = ({ email, token }) => {
  const { t: t_login } = useTranslation('login')
  const { t: t_common } = useTranslation('common')
  const { resetPassword, authenticateAndRedirect } = useContext(
    AuthenticationContext,
  )

  const saveProfil = (data: ResetPasswordReq) => {
    resetPassword({ password: data.password, token, email }).then(() =>
      authenticateAndRedirect({ username: email, password: data.password }),
    )
  }

  return (
    <Form layout="vertical" requiredMark={false} onFinish={saveProfil}>
      <h3>
        {t_login('fillInYourNew')} {t_common('password').toLowerCase()}
      </h3>

      <InputWithTip tip={t_login('atLest12Char1Number1MajAndNoSpace')}>
        <Form.Item
          name="password"
          label={t_common('password')}
          rules={[
            {
              required: true,
              message: `${t_login('pleaseEnterYour')} ${t_common(
                'password',
              ).toLowerCase()}`,
            },
            complexityPasswordValidator,
          ]}
        >
          <Input.Password
            size="large"
            iconRender={FormHelper.passwordIconRenderer}
          />
        </Form.Item>
      </InputWithTip>

      <InputWithTip>
        <Form.Item
          name="confirm"
          label={`${t_login('confirmationOf')} ${t_common(
            'password',
          ).toLowerCase()}`}
          dependencies={['password']}
          rules={[
            {
              required: true,
              message: `${t_login('pleaseConfirmYour')} ${t_common(
                'password',
              ).toLowerCase()}`,
            },
            samePasswordValidator('password'),
          ]}
        >
          <Input.Password
            size="large"
            iconRender={FormHelper.passwordIconRenderer}
          />
        </Form.Item>
      </InputWithTip>

      <MainButton htmlType={'submit'}>{t_common('continue')}</MainButton>
    </Form>
  )
}

export default ResetPasswordForm
