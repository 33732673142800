import { SearchOutlined } from '@ant-design/icons'
import { Select } from 'antd'
import { SizeType } from 'antd/lib/config-provider/SizeContext'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ApiContext from '../context/ApiContext'
import { StartupModel } from '../domain/startup.model'
import useDebounce from '../utils/useDebounce'
import { useTranslation } from 'react-i18next'
import PianoAnalyticsContext from "../context/PianoAnalyticsContext";

interface Result {
  startup: StartupModel[]
}

interface Props {
  width?: string
  size?: SizeType
  className?: string
}

const QuickSearch = ({
  width = '200px',
  size,
  className,
}: Props): JSX.Element => {
  const { quickSearch } = useContext(ApiContext)
  const history = useHistory()
  const { t: t_startup } = useTranslation('startup')
  const [searchTerm, setSearchTerm] = useState<string>()
  const [searchResult, setSearchResult] = useState<Result>()
  const debounceSearchText = useDebounce(searchTerm)
  const pianoAnalytics = useContext(PianoAnalyticsContext)

  useEffect(() => {
    let searchQuery = (debounceSearchText || '').trim()
    if (searchQuery !== '') {
      pianoAnalytics.newKeyWord(searchQuery)
      quickSearch(searchQuery).then((res): void => {
        setSearchResult({
          startup: res.startups,
        })
      })
    } else {
      setSearchResult(undefined)
    }
  }, [quickSearch, debounceSearchText, pianoAnalytics])

  const onSelectOption = (select: any): void => {
    setSearchTerm(undefined)
    history.push(`/startups/${select}`)
  }

  return (
    <Select
      onClick={(e) => e.stopPropagation()}
      size={size}
      style={{ width: width }}
      showSearch={true}
      value={searchTerm}
      onSearch={setSearchTerm}
      suffixIcon={<SearchOutlined />}
      className={className}
      notFoundContent={t_startup('noResult')}
      filterOption={false}
      onSelect={onSelectOption}
      options={[
        {
          label:
            searchResult?.startup && searchResult.startup.length > 0
              ? t_startup('result_header')
              : t_startup('noResult'),
          options: (searchResult?.startup ?? []).map((s) => ({
            value: s.id,
            label: s.informations.name,
          })),
        },
      ]}
    />
  )
}

export default QuickSearch
