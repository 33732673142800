import { Button, Input, Modal, Table } from 'antd'
import { ColumnsType } from 'antd/lib/table/interface'
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react'
import Section from '../../components/Layout/Section'
import MailService, { Mail } from '../../components/Mail/mail.service'
import Mailer from '../../components/Mail/Mailer'
import ApiContext from '../../context/ApiContext'
import UserModel from '../../domain/admin/user.model'
import { PasswordToken } from '../../domain/password-token.model'
import FormHelper from '../../utils/FormHelper'
import TableHelper from '../../utils/TableHelper'
import { Roles, RolesHelper } from '../../utils/roles.enum'
import InviteUser from './InviteUser'
import MainButton from '../../components/MainButton/MainButton'
import UserContext from '../../context/UserContext'
import { useTranslation } from 'react-i18next'
import { EditUserByAdminModal } from './EditUserByAdminModal'

const Users: FC<void> = (): ReactElement => {
  const {
    listUsers,
    inviteUser,
    resetUserPassword,
    deleteUser,
    successFormBehaviour,
  } = useContext(ApiContext)
  const [resetMail, setResetMail] = useState<Mail>()
  const [users, setUsers] = useState<UserModel[]>([])
  const [filteredUsers, setFilteredUsers] = useState<UserModel[]>([])
  const [showModal, setShowModal] = useState(false)
  const [userToUpdate, setUserToUpdate] = useState<UserModel>()
  const { user } = useContext(UserContext)
  const [deleted, setDeleted] = useState<boolean>(false)
  const { t: t_common } = useTranslation('common')
  const { t: t_user } = useTranslation('users')

  const loadUsers = () => {
    listUsers().then((list) => {
      setUsers(list)
      setFilteredUsers(list)
    })
  }

  useEffect((): void => {
    loadUsers()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleted])

  const sendResetMail = (email: string): void => {
    resetUserPassword(email).then((token: PasswordToken) => {
      setResetMail(
        MailService.buildResetMail(email, token.token, token.expirationDate),
      )
    })
  }

  const sendInviteMail = (email: string, role: Roles): void => {
    inviteUser({ email, role }).then((token: PasswordToken) => {
      setResetMail(
        MailService.buildInviteEmail(email, token.token, token.expirationDate),
      )
    })
  }

  const confirmSuppression = async (email: string | undefined) => {
    setDeleted(false)
    if (email) {
      await deleteUser(email).then(() =>
        successFormBehaviour('utilisateur supprimé'),
      )
      setShowModal(false)
      setDeleted(true)
    }
  }

  const columnConfig: ColumnsType<UserModel> = [
    {
      title: t_common('firstName'),
      key: 'firstName',
      dataIndex: 'firstName',
      sorter: TableHelper.stringCompare('firstName'),
      filtered: true,
    },
    {
      title: t_common('lastName'),
      key: 'lastName',
      dataIndex: 'lastName',
      sorter: TableHelper.stringCompare('lastName'),
    },
    {
      title: 'Email',
      key: 'email',
      dataIndex: 'email',
      sorter: TableHelper.stringCompare('email'),
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      sorter: TableHelper.stringCompare('role'),
      filters: RolesHelper.allRoles().map((e: Roles) => ({
        text: RolesHelper.format(e, t_common).valueOf(),
        value: RolesHelper.format(e, t_common),
      })),
      onFilter: (value, record: UserModel) =>
        RolesHelper.format(record.role, t_common) === value,
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) =>
        record.id == null ? (
          record.role != null ? (
            <Button
              size="small"
              // @ts-ignore
              onClick={() => sendInviteMail(record.email, record.role)}
            >
              {t_user('re_send_invite')}
            </Button>
          ) : (
            t_user('waiting_invite')
          )
        ) : (
          <Button size="small" onClick={() => sendResetMail(record.email)}>
            {t_user('reset_password')}
          </Button>
        ),
    },
    {
      title: t_user('edit'),
      key: 'edit',
      render: (_, record) =>
        record.id != null ? (
          <EditUserByAdminModal id={record.id} refreshUsers={loadUsers} />
        ) : (
          <></>
        ),
    },
    {
      title: t_common('delete'),
      key: 'suppression',
      render: (text, record) => (
        <>
          {user.id !== record.id ? (
            <Button
              size="small"
              onClick={() => {
                setShowModal(true)
                setUserToUpdate(record)
              }}
            >
              {t_common('delete')}
            </Button>
          ) : null}
          <Modal
            maskStyle={{ backgroundColor: 'rgba(0,0,0,0.3)' }}
            title={t_common('delete')}
            centered
            visible={showModal}
            //confirmLoading={confirmLoading}
            onCancel={() => setShowModal(false)}
            footer={[
              <Button
                key="delete"
                onClick={() => confirmSuppression(userToUpdate?.email)}
              >
                {t_common('delete')}
              </Button>,
              <MainButton key="cancel" onClick={() => setShowModal(false)}>
                {t_common('cancel')}
              </MainButton>,
            ]}
          >
            <p>
              {t_common('confirmDelete')} {userToUpdate?.email}
            </p>
            <p>{t_common('areYouSure')}</p>
          </Modal>
        </>
      ),
    },
  ]

  const filterUsers = (search: string): void => {
    if (FormHelper.isBlank(search)) {
      setFilteredUsers(users)
    } else {
      setFilteredUsers(
        users.filter((user) =>
          `${user.firstName} ${user.lastName} ${user.email}`
            .toLowerCase()
            .includes(search.toLowerCase()),
        ),
      )
    }
  }

  return (
    <Section>
      <h2>{t_user('invite_user')}</h2>
      <InviteUser />
      <h2>{t_user('user_listing')}</h2>
      <div className={'search-bar-container'}>
        <label className={'search-label'}>{t_user('filter_users')}</label>
        <Input.Search
          placeholder={'ex: Nicolas Dupont'}
          allowClear
          onChange={(e) => filterUsers(e.target.value)}
          style={{ width: 300 }}
        />
      </div>
      <br />
      <br />
      <Table
        locale={{
          triggerDesc: t_common('triggerDesc'),
          triggerAsc: t_common('triggerAsc'),
          cancelSort: t_common('cancelSort'),
        }}
        columns={columnConfig}
        dataSource={filteredUsers}
        rowKey="email"
        scroll={{ x: true }}
      />
      <Mailer mail={resetMail} />
    </Section>
  )
}

export default Users
