enum RegionEnum {
  AUVERGNE_RHONE_ALPES = 'Auvergne-Rhône-Alpes',
  BOURGOGNE_FANCHE_COMTE = 'Bourgogne-Fanche-Comté',
  BRETAGNE = 'Bretagne',
  CENTRE_VAL_DE_LOIRE = 'Centre-Val de Loire',
  CORSE = 'Corse',
  GRAND_EST = 'Grand-Est',
  GUADELOUPE = 'Guadeloupe',
  GUYANE = 'Guyane',
  HAUTS_DE_FRANCE = 'Hauts-de-France',
  ILE_DE_FRANCE = 'Ile-de-France',
  MARTINIQUE = 'Martinique',
  MAYOTTE = 'Mayotte',
  NORMANDIE = 'Normandie',
  NOUVELLE_AQUITAINE = 'Nouvelle-Aquitaine',
  OCCITANIE = 'Occitanie',
  PAYS_DE_LA_LOIRE = 'Pays de la Loire',
  PROVENCE_ALPES_COTE_D_AZUR = "Provence-Alpes-Côte d'Azur",
  REUNION = 'Réunion',
}

export class RegionHelper {
  static allRegions = (): RegionEnum[] => Object.values(RegionEnum)
}

export default RegionEnum
