import { Form, Input, Select } from 'antd'
import { InputProps } from 'antd/lib/input'
import React, { FC, useState } from 'react'
import MobilePhone from '../../domain/mobilePhone'
import { StartupModel } from '../../domain/startup.model'
import codeFlags from '../../utils/codeFlags'
import FormatHelper from '../../utils/FormatHelper'

const { Option } = Select

type Props = Omit<InputProps, 'onChange' | 'value'> & {
  value?: MobilePhone
  onChange?: (v?: MobilePhone) => void
  prefix?: keyof StartupModel
  required?: boolean
}

const InputPhone: FC<Props> = ({
  value,
  prefix,
  size = 'large',
  onChange = () => {},
  required = true,
  ...props
}) => {
  const [number, setNumber] = useState(
    FormatHelper.phoneNumberFormatter(value ? value.number : ''),
  )
  const [indicatif, setIndicatif] = useState(value ? value.indicatif : '+33')

  const updateValue = (number: string, indicatif: string): void => {
    number = number.replaceAll(' ', '')
    setNumber(FormatHelper.phoneNumberFormatter(number))
    setIndicatif(indicatif)
    onChange({ number, indicatif })
  }

  return (
    <Input.Group compact>
      <Form.Item
        name={FormatHelper.formItemNameFormatter(
          prefix,
          'mobilePhone',
          'indicatif',
        )}
        initialValue={indicatif}
        noStyle
        rules={[{ required, message: 'Veuillez saisir un indicatif' }]}
      >
        <Select
          size={size}
          style={{ width: '30%' }}
          showSearch
          value={indicatif}
          placeholder={'+33'}
          optionFilterProp="child"
          filterOption={(input, option) =>
            (option!.value as string)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          }
          onChange={(e) => updateValue(number, e.toString())}
        >
          {codeFlags.map((pays, index) => (
            <Option value={pays.code} key={index}>
              {pays.flag} : {pays.code}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        initialValue={number}
        rules={[
          {
            required,
            message: 'Veuillez saisir un numéro de téléphone',
          },
          {
            pattern: /^[0-9]{9}$/,
            message: 'Numéro de téléphone de la forme 6 52 84 83 93',
          },
        ]}
        name={FormatHelper.formItemNameFormatter(
          prefix,
          'mobilePhone',
          'number',
        )}
        noStyle
      >
        {' ' /* Ne pas supprimer cet espace --> Sinon l'affichage bug */}
        <Input
          {...props}
          style={{ width: '69%', height: '62px' }}
          size={size}
          placeholder={'6 52 84 83 93'}
          maxLength={13}
          value={number}
          onChange={(e) => updateValue(e.target.value, indicatif)}
        />
      </Form.Item>
    </Input.Group>
  )
}

export default InputPhone
