import { authent, request } from '../utils/httpHelper'
import methods from '../utils/methods'

export interface AuthReq {
  username: string
  password: string
}
export interface InitAccountReq {
  token: string
  firstName: string
  lastName: string
  email: string
  password: string
}

export interface ResetPasswordReq {
  token: string
  email: string
  password: string
}

const getMe = () => request(methods.GET, `/me`)
const validateToken = (token: string, email: string) =>
  request(methods.GET, `/auth/validate-token`, undefined, { token, email })
const initAccount = (req: InitAccountReq) =>
  request(methods.POST, `/auth/init-account`, req)
const resetPassword = (req: ResetPasswordReq) =>
  request(methods.POST, `/auth/reset-password`, req)
const sendEmailResetPassword = (email: string): Promise<void> =>
  request(methods.POST, `/auth/send-reset-token-email`, { email })

const authenticate = (auth: AuthReq) => authent(methods.GET, `/login`, auth)

const logout = () => {
  Object.keys(localStorage).forEach((key) => localStorage.removeItem(key))
  return request(methods.GET, '/logout', {})
}

export default {
  authenticate,
  getMe,
  logout,
  validateToken,
  initAccount,
  resetPassword,
  sendEmailResetPassword,
}
